import { Routes, Route } from "react-router-dom";
import Login from "../AuthPages/Login";
import Signup from "../AuthPages/Signup";
import Signupconfirm from "../AuthPages/Signupconfirm";
import Confirmation from "../AuthPages/Confirmation";
import Forgotpassword from "../AuthPages/Forgotpassword";
import ResetPwd from "../AuthPages/ResetPassword";
import Test from "../AuthPages/Test";
import LoginWithOTP from "../AuthPages/LoginWithOTP";

import ShraddhaAdmin from "../AdminPages/ShraddhaAdmin";

const AuthRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/Signupconfirm" element={<Signupconfirm />} />
      <Route path="/confirmation" element={<Confirmation />} />
      <Route path="/forgot-password" element={<Forgotpassword />} />
      <Route path="/reset-password" element={<ResetPwd />} />
      <Route path="/ShraddhaAdmin" element={<ShraddhaAdmin />} />
      <Route path="/Test" element={<Test />} />
      <Route path="/LoginWithOTP" element={<LoginWithOTP />} />
    </Routes>
  );
};

export default AuthRoutes;
