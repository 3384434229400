import { useState, useEffect } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../theme";
import HomeIcon from "@mui/icons-material/Home";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import MenuOpen from "@mui/icons-material/MenuOpen";
import ApprovalIcon from "@mui/icons-material/Approval";
import SecondApprov from "@mui/icons-material/HowToReg";
import VerifiedIcon from "@mui/icons-material/Verified";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import AddHomeWorkRoundedIcon from "@mui/icons-material/AddHomeWorkRounded";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SourceIcon from "@mui/icons-material/Source";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import StoreIcon from "@mui/icons-material/Store";
import axios from "axios";
import EventNoteIcon from "@mui/icons-material/EventNote";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import { useNavigate } from "react-router-dom";


// const Item = ({ title, to, icon, selected, setSelected }) => {
//   const theme = useTheme();
//   const colors = tokens(theme.palette.mode);
const Item = ({ title, to, icon, selected, setSelected, style }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.primary[100],
        ...style,
        backgroundColor:
          selected === title ? colors.blueAccent[900] : "transparent",
        borderRadius: "10px",
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography
        style={{
          fontWeight: "bold",
          color: style?.color || colors.primary[100], // Use grey if provided
        }}
      >
        {title}
      </Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");
  const token = localStorage.getItem("token");
  const cmpid = localStorage.getItem("cmpid");

  // State variables for visibility
  const [showPrimaryApproval, setShowPrimaryApproval] = useState(true);
  const [showSecondaryApproval, setShowSecondaryApproval] = useState(true);
  const [showFinalApproval, setshowFinalApproval] = useState(true);
  const [showAdminApproval, setshowAdminApproval] = useState(false);
  const [showUserConfigApproval, setshowUserConfigApproval] = useState(false);
  const [showAllUsers, setshowAllUsers] = useState(false);

  useEffect(() => {
    const updateStateBasedOnScreenSize = () => {
      const isSmall = window.matchMedia("(max-width: 640px)").matches; // Tailwind SM breakpoint
      const isMedium = window.matchMedia(
        "(min-width: 641px) and (max-width: 1024px)"
      ).matches; // Tailwind MD breakpoint
      const isExtraLarge = window.matchMedia("(min-width: 1281px)").matches; // Tailwind XL breakpoint

      if (isExtraLarge) {
        setIsCollapsed(false);
      } else if (isMedium || isSmall) {
        setIsCollapsed(true);
      }
    };

    updateStateBasedOnScreenSize(); // Initialize state on mount

    window.addEventListener("resize", updateStateBasedOnScreenSize); // Update state on resize

    return () => {
      window.removeEventListener("resize", updateStateBasedOnScreenSize); // Cleanup
    };
  }, []);

  useEffect(() => {
    const role = localStorage.getItem("Role");

    if (role === "admin") {
      setshowUserConfigApproval(true);
      setshowAllUsers(true);
      setshowAdminApproval(true);
    }

    const fetchData = async () => {
      try {
        const response = await axios.post(
          process.env.REACT_APP_API_URL + "/profile/approvalPerm",
          {},
          {
            headers: { Authorization: token },
          }
        );

        const UserNameData = Object.values(response.data);

        const permissionLevels = UserNameData.map(
          (item) => item.permission_Level
        );

        const permissioncmpId = UserNameData.map((item) => item.cmp_id);

        if (
          permissionLevels.includes("Primary") &&
          permissioncmpId.includes(cmpid)
        ) {
          setShowPrimaryApproval(true);
        } else {
          setShowPrimaryApproval(false);
        }

        if (
          permissionLevels.includes("Secondary") &&
          permissioncmpId.includes(cmpid)
        ) {
          setShowSecondaryApproval(true);
        } else {
          setShowSecondaryApproval(false);
        }

        if (
          permissionLevels.includes("Final") &&
          permissioncmpId.includes(cmpid)
        ) {
          setshowFinalApproval(true);
        } else {
          setshowFinalApproval(false);
        }
      } catch (error) {
        console.log("Error fetching data", error);
      }
    };

    fetchData();
  }, []);

  //============================================================================================

  const [isDataOpen, setIsDataOpen] = useState(false); // State to manage collapse
  const [isMarketPlace, setMarketPlace] = useState(false);
  const [isVchOpen, setIsVchOpen] = useState(false);
  // const [isReportOpen, setIsReportOpen] = useState(false);
  const [isCreditOpen, setIsCreditOpen] = useState(false);
  const [IsField, setIsField] = useState(false);

  const handleDataToggle = () => {
    setIsDataOpen(!isDataOpen); // Toggle the open state
  };
  const handlvchToggle = () => {
    setIsVchOpen(!isVchOpen); // Toggle the open state
  };

  const handlMarketToggle = () => {
    setMarketPlace(!isMarketPlace); // Toggle the open state
  };

  // const handlReportToggle = () => {
  //   setIsReportOpen(!isReportOpen); // Toggle the open state
  // };

  const handleCreditToggle = () => {
    setIsCreditOpen(!isCreditOpen);
  };
  const handleFieldToggle = () => {
    setIsField(!IsField);
  };

  //============ navigate to pages ============

  const handleEvent = () => {
    navigate("/Member/ShraddhaEvents");
  };

  const handlSupport = () => {
    navigate("/Member/Support");
  };

  //======================================
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          "& .pro-sidebar-inner": {
            background: `${colors.primary[400]} !important`,
            boxShadow: "2px 0 2px -2px rgba(0, 0, 0, 0.5)",
            marginTop: {
              xs: "3px", // Margin for extra small screens
              sm: "34px", // Margin for small screens
              md: "34px", // Margin for medium screens
              lg: "34px", // Slightly larger margin for large screens
              xl: "34px", // Same margin for extra-large screens
            },
            position: isCollapsed ? "fixed" : "fixed",
            height: isCollapsed ? "76vh" : "76vh",
            overflowY: "auto",
            top: isCollapsed ? "40px" : "40px",
            left: 0,
            width: {
              xs: isCollapsed ? "90px" : "290px", // Full width on mobile when not collapsed
              sm: isCollapsed ? "90px" : "290px", // Adjust for small screens if needed
              md: isCollapsed ? "90px" : "290px", // Medium screens and larger
              lg: isCollapsed ? "90px" : "290px", // Large screens and up
            },
            zIndex: isCollapsed ? 999 : 999,
            transition: "all 0.3s ease",
            flexDirection: "column",
          },
          "& .pro-icon-wrapper": {
            backgroundColor: "transparent !important",
          },
          "& .pro-inner-item": {
            color: `${colors.grey[400]}!important`,
          },
          "& .pro-inner-item:hover": {
            color: "#CBAD5B !important", //"#868dfb !important",
          },
          "& .pro-menu-item": {
            color: "#6870fa !important",
            borderRadius: "10px",
            fontSize: "10px",
          },
          "& .pro-menu-item.active": {
            color: "#6870fa !important",
            backgroundColor: `${colors.blueAccent[900]}!important`,
            borderRadius: "10px",
          },
        }}
      >
        <ProSidebar collapsed={isCollapsed}>
          <Menu iconShape="square">
            <MenuItem
              onClick={() => setIsCollapsed(!isCollapsed)}
              icon={isCollapsed ? <MenuOutlinedIcon /> : <MenuOpen />}
              style={{
                color: colors.grey[100],
              }}
            ></MenuItem>

            {/* Menu Items */}

            <Box paddingLeft={isCollapsed ? undefined : "10%"}>
              <Item
                title="Dashboard"
                to="/Infinite/Dashboard"
                icon={<HomeIcon />}
                selected={selected}
                setSelected={setSelected}
              />

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={handleDataToggle}
              >
                <SourceIcon
                  style={{ marginLeft: "25px", color: colors.primary[100] }}
                />
                {/* Add your icon here */}
                <Typography
                  paddingLeft={isCollapsed ? "25%" : "0px"}
                  color={colors.primary[100]}
                  sx={{
                    m: "10px 0 5px 15px",
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                >
                  Configuration
                </Typography>

                {isDataOpen ? ( // Show up arrow when expanded
                  <ExpandLessIcon
                    style={{ marginLeft: "auto", color: colors.primary[100] }}
                  />
                ) : (
                  // Show down arrow when collapsed
                  <ExpandMoreIcon
                    style={{ marginLeft: "auto", color: colors.primary[100] }}
                  />
                )}
              </Box>
              {isDataOpen && ( // Conditionally render items
                <>
                  <Box
                    style={{
                      marginLeft: "20px",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <Item
                      title="Companies"
                      to="/Infinite/TallyCompanies"
                      icon={<AddHomeWorkRoundedIcon />}
                      selected={selected}
                      setSelected={setSelected}
                      style={{ color: "grey" }}
                    />

                    {showAllUsers && (
                      <Item
                        title="Users"
                        to="/Infinite/Users"
                        icon={<PeopleOutlinedIcon />}
                        selected={selected}
                        setSelected={setSelected}
                        style={{ color: "grey" }}
                      />
                    )}
                  </Box>
                </>
              )}

              {/* ======================= Masters menu items ======================== */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={handlMarketToggle}
              >
                <StoreIcon
                  style={{ marginLeft: "25px", color: colors.primary[100] }}
                />
                {/* Add your icon here */}
                <Typography
                  paddingLeft={isCollapsed ? "25%" : "0px"}
                  color={colors.primary[100]}
                  sx={{
                    m: "10px 0 5px 15px",
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                >
                  Master
                </Typography>
                {isMarketPlace ? ( // Show up arrow when expanded
                  <ExpandLessIcon
                    style={{ marginLeft: "auto", color: colors.primary[100] }}
                  />
                ) : (
                  // Show down arrow when collapsed
                  <ExpandMoreIcon
                    style={{ marginLeft: "auto", color: colors.primary[100] }}
                  />
                )}
              </Box>
              {isMarketPlace && (
                <>
                  <Box
                    sx={{
                      marginLeft: "20px",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <Item
                      title="Customers"
                      to="/Infinite/Ledger"
                      icon={<PeopleOutlinedIcon />}
                      selected={selected}
                      setSelected={setSelected}
                      style={{ color: "grey" }}
                    />
                  </Box>
                </>
              )}
              {/* =============== voucher Approval ================================ */}
              <Box>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={handlvchToggle}
                >
                  <VerifiedIcon
                    style={{ marginLeft: "25px", color: colors.primary[100] }}
                  />

                  <Typography
                    paddingLeft={isCollapsed ? "25%" : "0px"}
                    color={colors.primary[100]}
                    sx={{
                      m: "10px 0 5px 15px",
                      cursor: "pointer",
                      fontWeight: "bold",
                      fontSize: isCollapsed ? "15px" : "19px",
                    }}
                  >
                    Approval Module
                  </Typography>
                  {isVchOpen ? ( // Show up arrow when expanded
                    <ExpandLessIcon
                      style={{
                        marginLeft: "auto",
                        color: colors.primary[100],
                      }}
                    />
                  ) : (
                    // Show down arrow when collapsed
                    <ExpandMoreIcon
                      style={{
                        marginLeft: "auto",
                        color: colors.primary[100],
                      }}
                    />
                  )}
                </Box>

                {/* Conditionally render content if isVchOpen is true */}
                {isVchOpen && (
                  <Box
                    style={{
                      marginLeft: "20px",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    {showPrimaryApproval && (
                      <Item
                        title="Primary Approval"
                        to="/Infinite/PrimaryApprov"
                        icon={<ApprovalIcon />}
                        selected={selected}
                        setSelected={setSelected}
                        style={{ color: "grey" }}
                      />
                    )}

                    {showSecondaryApproval && (
                      <Item
                        title="Secondary Approval"
                        to="/Infinite/SecondaryApprov"
                        icon={<SecondApprov />}
                        selected={selected}
                        setSelected={setSelected}
                        style={{ color: "grey" }}
                      />
                    )}

                    {showFinalApproval && (
                      <Item
                        title="Final Approval"
                        to="/Infinite/FinalApprov"
                        icon={<TaskAltIcon />}
                        selected={selected}
                        setSelected={setSelected}
                        style={{ color: "grey" }}
                      />
                    )}

                    {showAdminApproval && (
                      <Item
                        title="Admin Report"
                        to="/Infinite/AdminList"
                        icon={<AssignmentIndIcon />}
                        selected={selected}
                        setSelected={setSelected}
                        style={{ color: "grey" }}
                      />
                    )}

                    {showUserConfigApproval && (
                      <Item
                        title="Users Config"
                        to="/Infinite/ApprovalModuleUsers"
                        icon={<AccountCircleIcon />}
                        selected={selected}
                        setSelected={setSelected}
                        style={{ color: "grey" }}
                      />
                    )}
                  </Box>
                )}
              </Box>

              {/* =================== Credit Management ========================= */}

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={handleCreditToggle}
              >
                <CreditCardIcon
                  style={{ marginLeft: "25px", color: colors.primary[100] }}
                />
                {/* Add your icon here */}
                <Typography
                  paddingLeft={isCollapsed ? "25%" : "0px"}
                  color={colors.primary[100]}
                  sx={{
                    m: "10px 0 5px 15px",
                    cursor: "pointer",
                    fontWeight: "bold",
                    fontSize: isCollapsed ? "15px" : "19px",
                  }}
                >
                  Credit Module
                </Typography>
                {isCreditOpen ? ( // Show up arrow when expanded
                  <ExpandLessIcon
                    style={{ marginLeft: "auto", color: colors.primary[100] }}
                  />
                ) : (
                  // Show down arrow when collapsed
                  <ExpandMoreIcon
                    style={{ marginLeft: "auto", color: colors.primary[100] }}
                  />
                )}
              </Box>
              {isCreditOpen && (
                <>
                  <Box
                    sx={{
                      marginLeft: "20px",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <Item
                      title="Credit Dashboard"
                      to="/Infinite/CreditDashboard"
                      icon={<HomeIcon />}
                      selected={selected}
                      setSelected={setSelected}
                      style={{ color: "grey" }}
                    />
                  </Box>
                  <Box
                    sx={{
                      marginLeft: "20px",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <Item
                      title="Payble Report"
                      to="/Infinite/PayableReport"
                      icon={<PeopleOutlinedIcon />}
                      selected={selected}
                      setSelected={setSelected}
                      style={{ color: "grey" }}
                    />
                  </Box>
                  <Box
                    sx={{
                      marginLeft: "20px",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <Item
                      title="Receivable Report"
                      to="/Infinite/ReceivableReport"
                      icon={<PeopleOutlinedIcon />}
                      selected={selected}
                      setSelected={setSelected}
                      style={{ color: "grey" }}
                    />
                  </Box>
                </>
              )}

              {/* =================== Field Sales  Management ========================= */}

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={handleFieldToggle}
              >
                <AnalyticsIcon
                  style={{ marginLeft: "25px", color: colors.primary[100] }}
                />
                {/* Add your icon here */}
                <Typography
                  paddingLeft={isCollapsed ? "25%" : "0px"}
                  color={colors.primary[100]}
                  sx={{
                    m: "10px 0 5px 15px",
                    cursor: "pointer",
                    fontWeight: "bold",
                    fontSize: isCollapsed ? "15px" : "19px",
                  }}
                >
                  Field Sales Module
                </Typography>
                {IsField ? ( // Show up arrow when expanded
                  <ExpandLessIcon
                    style={{ marginLeft: "auto", color: colors.primary[100] }}
                  />
                ) : (
                  // Show down arrow when collapsed
                  <ExpandMoreIcon
                    style={{ marginLeft: "auto", color: colors.primary[100] }}
                  />
                )}
              </Box>
              {IsField && (
                <>
                  <Box
                    sx={{
                      marginLeft: "20px",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <Item
                      title="Dashboard"
                      to="/Infinite/FieldDashboard"
                      icon={<HomeIcon />}
                      selected={selected}
                      setSelected={setSelected}
                      style={{ color: "grey" }}
                    />
                  </Box>
                  <Box
                    sx={{
                      marginLeft: "20px",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <Item
                      title="Customer Details"
                      to="/Infinite/CustomerDetails"
                      icon={<PeopleOutlinedIcon />}
                      selected={selected}
                      setSelected={setSelected}
                      style={{ color: "grey" }}
                    />
                  </Box>

                  {/* ============================================================================ */}

                  {/* <Box
                    sx={{
                      marginLeft: "20px",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <Item
                      title="Receivable Report"
                      to="/Ledger"
                      icon={<PeopleOutlinedIcon />}
                      selected={selected}
                      setSelected={setSelected}
                      style={{ color: "grey" }}
                    />
                  </Box> */}
                </>
              )}

              {/* ============================================================================ */}

              {/* <Box
                    sx={{
                      marginLeft: "20px",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <Item
                      title="Receivable Report"
                      to="/Ledger"
                      icon={<PeopleOutlinedIcon />}
                      selected={selected}
                      setSelected={setSelected}
                      style={{ color: "grey" }}
                    />
                  </Box> */}

              {/* ================= reports   ================================ */}
              {/* <Box>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={handlReportToggle}
                >
                  <BarChartIcon
                    style={{ marginLeft: "25px", color: colors.primary[100] }}
                  />
                  <Typography
                    variant="h4"
                    paddingLeft={isCollapsed ? "20%" : "0px"}
                    color={colors.primary[100]}
                    sx={{ m: "15px 0 5px 20px", cursor: "pointer" }}
                  >
                    Reports
                  </Typography>
                  {isReportOpen ? ( // Show up arrow when expanded
                    <ExpandLessIcon
                      style={{
                        marginLeft: "auto",
                        color: colors.primary[100],
                      }}
                    />
                  ) : (
                    // Show down arrow when collapsed
                    <ExpandMoreIcon
                      style={{
                        marginLeft: "auto",
                        color: colors.primary[100],
                      }}
                    />
                  )}
                </Box>

                {/* Conditionally render content if isReportOpen is true */}
              {/* {isReportOpen && (
                  <Box
                    style={{
                      marginLeft: "20px",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <Item
                      title="Vocher Entry"
                      to="/VoucherEntry"
                      icon={<TaskIcon />}
                      selected={selected}
                      setSelected={setSelected}
                      style={{ color: "grey" }}
                    />

                    <Item
                      title="Payable Report"
                      to="/PayableReport"
                      icon={<PaymentIcon />}
                      selected={selected}
                      setSelected={setSelected}
                      style={{ color: "grey" }}
                    />

                    <Item
                      title="Receivable Report"
                      to="/ReceivableReport"
                      icon={<AssuredWorkloadIcon />}
                      selected={selected}
                      setSelected={setSelected}
                      style={{ color: "grey" }}
                    />

                    <Item
                      title="Credtor's Score Card"
                      to="/CreditorScore"
                      icon={<AddCardIcon />}
                      selected={selected}
                      setSelected={setSelected}
                      style={{ color: "grey" }}
                    />

                    <Item
                      title="Debtor's Score Card"
                      to="/DebitorScore"
                      icon={<CreditScoreIcon />}
                      selected={selected}
                      setSelected={setSelected}
                      style={{ color: "grey" }}
                    />
                  </Box>
                )} 
              </Box>  */}
            </Box>
          </Menu>
        </ProSidebar>
      </Box>
      {/* =========================== box for add event support pages */}
      <Box
        sx={{
          background: `${colors.primary[400]} !important`,
          boxShadow: "2px 0 2px -2px rgba(0, 0, 0, 0.5)",
          position: "fixed",
          bottom: 0,
          left: 0,
          width: {
            xs: isCollapsed ? "90px" : "290px", // Full width on mobile when not collapsed
            sm: isCollapsed ? "90px" : "290px", // Adjust for small screens if needed
            md: isCollapsed ? "90px" : "290px", // Medium screens and larger
            lg: isCollapsed ? "90px" : "290px", // Large screens and up
          },
          display: isCollapsed ? "" : "flex",
          flexDirection: isCollapsed ? "column" : "row", // Keep row direction for icons and text horizontally
          alignItems: "center", // Center the items vertically
          padding: "10px", // Add padding for spacing
          zIndex: 1000, // Ensure it's above other content
          height: "13.5vh", // Set the height
        }}
      >
        {/* Event Icon and Text */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginRight: "20px",
            marginLeft: "20px",
            cursor: "pointer",
          }}
          onClick={handleEvent}
        >
          <EventNoteIcon style={{ color: "#B09246" }} />
          {!isCollapsed && (
            <Typography sx={{ marginLeft: "8px", color: "#B09246" }}>
              Events
            </Typography>
          )}
        </Box>

        {/* Support Icon and Text */}
        <Box
          sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={handlSupport}
        >
          <SupportAgentIcon
            style={{ color: "#B09246", marginLeft: isCollapsed ? "20px" : "0" }}
          />
          {!isCollapsed && (
            <Typography sx={{ marginLeft: "8px", color: "#B09246" }}>
              Support
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Sidebar;
