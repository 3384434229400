import { Routes, Route, Navigate } from "react-router-dom";
import Dashboard from "../InfinitePages/Dashboard/index";
import Users from "../InfinitePages/TallyData/Users";
import TallyCompanies from "../InfinitePages/TallyData/TallyCompanies";
import PrimaryApprov from "../InfinitePages/Module/Voucher-Approval/primary-Approval";
import SecondaryApprov from "../InfinitePages/Module/Voucher-Approval/secondary-Approval";
import FinalApprov from "../InfinitePages/Module/Voucher-Approval/final-Approval";
import AdminList from "../InfinitePages/Module/Voucher-Approval/admin-Report";
import ApprovalModuleUsers from "../InfinitePages/Module/Voucher-Approval/Approval-Module-Users";
import Ledger from "../InfinitePages/TallyData/Ledger";
//import VoucherEntry from "../InfinitePages/Reports/VoucherEntry";
//import VoucherCreate from "../InfinitePages/Reports/VoucherCreate";
import PayableReport from "../InfinitePages/Module/CreditManagement/PayableReport";
import ReceivableReport from "../InfinitePages/Module/CreditManagement/ReceivableReport";
import CreditDashboard from "../InfinitePages/Module/CreditManagement/CreditDashboard";
import FieldDashboard from "../InfinitePages/Module/Field Management/FieldDashboard";
import CustomerDetails from "../InfinitePages/Module/Field Management/CustomerDetails";


const isAuthenticated = localStorage.getItem("token") !== null;
const PrivateRoute = ({ element, isAuthenticated }) => {
  return isAuthenticated ? element : <Navigate to="/Auth/" />;
};

const InfiniteRoutes = () => {
  return (
    <Routes>
      <Route path="/Dashboard" element={<Dashboard />} />
      {/* <Route path="/Dashboard" element={<PrivateRoute element={<Dashboard />} isAuthenticated={isAuthenticated} />} /> */}
      <Route path="/Users" element={<PrivateRoute element={<Users />} isAuthenticated={isAuthenticated} />} />
      <Route path="/TallyCompanies" element={<PrivateRoute element={<TallyCompanies />} isAuthenticated={isAuthenticated} />} />
      <Route path="/PrimaryApprov" element={<PrivateRoute element={<PrimaryApprov />} isAuthenticated={isAuthenticated} />} />
      <Route path="/SecondaryApprov" element={<PrivateRoute element={<SecondaryApprov />} isAuthenticated={isAuthenticated} />} />
      <Route path="/FinalApprov" element={<PrivateRoute element={<FinalApprov />} isAuthenticated={isAuthenticated} />} />
      <Route path="/AdminList" element={<PrivateRoute element={<AdminList />} isAuthenticated={isAuthenticated} />} />
      <Route path="/ApprovalModuleUsers" element={<PrivateRoute element={<ApprovalModuleUsers />} isAuthenticated={isAuthenticated} />} />
      <Route path="/Ledger" element={<PrivateRoute element={<Ledger />} isAuthenticated={isAuthenticated} />} />
      {/* <Route path="/VoucherEntry" element={<PrivateRoute element={<VoucherEntry />} isAuthenticated={isAuthenticated} />} /> */}
      {/* <Route path="/VoucherCreate" element={<PrivateRoute element={<VoucherCreate />} isAuthenticated={isAuthenticated} />} /> */}
      <Route path="/PayableReport" element={<PrivateRoute element={<PayableReport />} isAuthenticated={isAuthenticated} />} />
      <Route path="/ReceivableReport" element={<PrivateRoute element={<ReceivableReport />} isAuthenticated={isAuthenticated} />} />
      <Route path="/CreditDashboard" element={<PrivateRoute element={<CreditDashboard />} isAuthenticated={isAuthenticated} />} />
      <Route path="/FieldDashboard" element={<PrivateRoute element={<FieldDashboard />} isAuthenticated={isAuthenticated} />} />
      <Route path="/CustomerDetails" element={<PrivateRoute element={<CustomerDetails />} isAuthenticated={isAuthenticated} />} />
    </Routes>
  );
};

export default InfiniteRoutes;
