import { Routes, Route } from "react-router-dom";
import GauestEvents from "../GauestPages/Events/GauestEvent";
import Support from "../GauestPages/Support/Support";

export default function GauestRoute() {
  return (
    <Routes>
      <Route path="/GauestEvents" element={<GauestEvents />} />
      <Route path="/Support" element={<Support />} />
    </Routes>
  );
}
