import React, { useState } from "react";
import { Box, Menu, MenuItem } from "@mui/material";
import SchoolIcon from "@mui/icons-material/School";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import SlowMotionVideoIcon from "@mui/icons-material/SlowMotionVideo";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import EventNoteIcon from "@mui/icons-material/EventNote";
import { useNavigate } from "react-router-dom";

export default function ShraddhaMobileMenuBar() {
  const navigate = useNavigate();
  
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeIcon, setActiveIcon] = useState(""); // Track the active icon

  const handleOpenDropUp = (event) => {
    setAnchorEl(event.currentTarget);
    setActiveIcon("");
  };

  const handleOpenEventDrop = () => {
    navigate("/Member/ShraddhaEvents");
    setActiveIcon("ShraddhaEvents");
  };
  const handleOpenTraVideoDrop = () => {
    navigate("/Member/EventsRecording");
    setActiveIcon("EventsRecording");
  };

  const handleOpenAllCoursesDrop = () => {
    navigate("/Member/ShraddhaInfinite");
    // navigate("/ShraddhaCourses");
    setAnchorEl(null);
    setActiveIcon("ShraddhaCourses");
  };

  const handleMyCourseDrop = () => {
    navigate("/Member/ShraddhaInfinite");
    //  navigate("/MyCourses");
    setAnchorEl(null);
    setActiveIcon("MyCourses");
  };

  const handleSInfiniteDrop = () => {
    navigate("/Member/ShraddhaInfinite");
    setActiveIcon("infinite");
  };

  const handleSSupportDrop = () => {
    navigate("/Member/Support");
    setActiveIcon("support");
  };

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        width: "100%",
        backgroundColor: "#333",
        color: "#fff",
        textAlign: "center",
        zIndex: 1000,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          height: "5vh",
        }}
      >
        {/* Knowledge Icon */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            backgroundColor:
              {
                ShraddhaCourses: "#fff",
                MyCourses: "#fff",
              }[activeIcon] || "transparent",
            borderRadius: "50%",
            padding: "10px",
          }}
          onClick={handleOpenDropUp}
        >
          <SchoolIcon
            sx={{
              color: activeIcon === "knowledge" ? "#B09246" : "#B09246",
            }}
          />
        </Box>

        {/* Drop-Up Menu */}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <MenuItem onClick={handleOpenAllCoursesDrop}>
            <SlowMotionVideoIcon
              sx={{ marginRight: "10px", cursor: "pointer", color: "#B09246" }}
            />
            All Courses
          </MenuItem>
          <MenuItem onClick={handleMyCourseDrop}>
            <OndemandVideoIcon
              sx={{ marginRight: "10px", cursor: "pointer", color: "#B09246" }}
            />
            My Course
          </MenuItem>
        </Menu>

        {/* training videos  */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            backgroundColor:
              activeIcon === "EventsRecording" ? "#fff" : "transparent",
            borderRadius: "50%",
            padding: "10px",
          }}
          onClick={handleOpenTraVideoDrop}
        >
          <OndemandVideoIcon
            sx={{
              color: activeIcon === "EventsRecording" ? "#B09246" : "#B09246",
            }}
          />
        </Box>

        {/* event  */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            backgroundColor:
              activeIcon === "ShraddhaEvents" ? "#fff" : "transparent",
            borderRadius: "50%",
            padding: "10px",
          }}
          onClick={handleOpenEventDrop}
        >
          <EventNoteIcon
            sx={{
              color: activeIcon === "ShraddhaEvents" ? "#B09246" : "#B09246",
            }}
          />
        </Box>

        {/* Support Icon */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            backgroundColor: activeIcon === "support" ? "#fff" : "transparent",
            borderRadius: "50%",
            padding: "10px",
          }}
          onClick={handleSSupportDrop}
        >
          <SupportAgentIcon
            sx={{
              color: activeIcon === "support" ? "#B09246" : "#B09246",
            }}
          />
        </Box>

        {/* Inclusive Icon */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            backgroundColor: activeIcon === "infinite" ? "#fff" : "transparent",
            borderRadius: "50%",
            padding: "10px",
          }}
          onClick={handleSInfiniteDrop}
        >
          <AllInclusiveIcon
            sx={{
              color: activeIcon === "infinite" ? "#B09246" : "#B09246",
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}
