import React, { useEffect, useState } from "react";
import axios from "axios";
import { PieChart, Pie, Cell, Tooltip, Legend } from "recharts";

const MyPieChart = () => {
  const token = localStorage.getItem("token");
  const cmpid = localStorage.getItem("cmpid");
  const [ApproveDashBord, setApproveDashBord] = useState([]);
  // console.log('approve Dashbord',ApproveDashBord);
  
  const data = [
    { id: 0, value: 50, label: "Primary Approval" },
    { id: 1, value: 25, label: "Secondary Approval" },
    { id: 2, value: 25, label: "Final Approval" },
  ];

  const fetchData = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/approvalModule/vchApprovedCount",
        { cmpid },
        {
          headers: { Authorization: token },
        }
      );

      const varApproveDashBord = response.data;
      setApproveDashBord(varApproveDashBord);
    } catch (error) {
      alert("Error fetching data", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // const ApproveData = Array.isArray(ApproveDashBord) ? ApproveDashBord.map((item) => ({
  //   primaryCount: parseFloat(item.primaryCount),
  //   secondaryCount: parseFloat(item.secondaryCount),
  //   finalCount: parseFloat(item.finalCount),
  // })) : [];;


  return (
    <PieChart width={250} height={250}>
      <Pie
        data={data}
        dataKey="value"
        nameKey="label"
        outerRadius={80}
        innerRadius={5}
        labelLine={true}
      >
        {data.map((entry, index) => (
          <Cell
            key={`cell-${index}`}
            fill={[
              "#377b2b", 
              "#7ac142", 
              "#62c2a6", 
            ][index % 3]}
          />
        ))}
      </Pie>
      <Tooltip />
      <Legend
        wrapperStyle={{
          fontSize: "14px", 
        }}
      />
    </PieChart>
  );
};

export default MyPieChart;
