import React, { useState } from "react";
import {

  Typography,
  Button,
  Box,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
export default function Confirmation() {
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");

  const handleVerify = async () => {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get("token");

      // console.log("token", token);
      const response = await axios.post(
        process.env.REACT_APP_API_URL + `/auth/confirmation`,
        { token }
      );

      // console.log("response", response);

      if (response) {
        setDialogMessage("Your email has been successfully verified.");
      }

      setOpenDialog(true);
    } catch (error) {
      alert("Verification failed. Please try again.");
      navigate("/Signupconfirm");
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    window.location.href = "/";
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        backgroundImage: "url('../../assets/sign up BG_equal.png')",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Card
        sx={{
          width: { xs: "90%", sm: "80%", md: "60%", lg: "40%" },
          textAlign: "center",
          boxShadow: 3,
          borderRadius: 2,
          padding: 3,
          backgroundColor: "white",
        }}
      >
        <img
          alt="logo"
          width="250px"
          height="120px"
          style={{ borderRadius: "15px" }}
          src="../../assets/Infinitelogo.png"
        />

        <Typography variant="h3" fontWeight="bold" gutterBottom>
          Verify your email address
        </Typography>
        <Typography variant="body1" gutterBottom>
          Please confirm that you want to use this as your account email
          address. Once it's done, you will be able to start using our services.
        </Typography>

        <Button
          variant="contained"
          color="success"
          onClick={handleVerify}
          sx={{ mt: 3, px: 5, py: 1.5, fontSize: "1rem" }}
        >
          Verify my email
        </Button>
      </Card>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle sx={{ color: "green", textAlign: "center" }}>
          Verification Status
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">{dialogMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            color="success"
            variant="contained"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
