import { Box, Typography, Grid, Card, CardContent } from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import GroupIcon from "@mui/icons-material/Group";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import DevicesIcon from "@mui/icons-material/Devices";
import LoginIcon from "@mui/icons-material/Login";

export default function AdminDashBoard() {
  const token = localStorage.getItem("shraddhatoken");
  const [TotalUserCnt, setTotalUserCnt] = useState([]);
  const [InFiMemCnt, setInFiMemCnt] = useState([]);
  const [ActSession, setActSession] = useState([]);
  const [Last30Days, setLast30Days] = useState([]);

  const TotalUsersData = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/reporting/userscount",
        {},
        {
          headers: { Authorization: token },
        }
      );
      const Totalusers = response.data.rows[0];
      setTotalUserCnt(Totalusers);
    } catch (error) {
      alert("Error fetching Total User Count data", error);
    }
  };

  const InfiniteMembData = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/reporting/infinitemember",
        {},
        {
          headers: { Authorization: token },
        }
      );
      const InfIMember = response.data.rows[0];
      setInFiMemCnt(InfIMember);
    } catch (error) {
      alert("Error fetching Total User Count data", error);
    }
  };

  const Activesession = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/reporting/activesession",
        {},
        {
          headers: { Authorization: token },
        }
      );
      const ActSession = response.data.rows[0];
      setActSession(ActSession);
    } catch (error) {
      alert("Error fetching Total User Count data", error);
    }
  };

  const LastThirtyDays = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/reporting/lasta30daysctive",
        {},
        {
          headers: { Authorization: token },
        }
      );
      const Last30days = response.data.rows[0];
      setLast30Days(Last30days);
    } catch (error) {
      alert("Error fetching Total User Count data", error);
    }
  };

  useEffect(() => {
    TotalUsersData();
    InfiniteMembData();
    Activesession();
    LastThirtyDays();
  }, []);

  return (
    <Box
      sx={{
        mt: 10,
        ml: 35,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
          p: 0,
          m: 0,
        }}
      >
        <Typography variant="h1">DashBoard</Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "40vh",
          gap: 5,
          p: 0,
          m: 0,
        }}
      >
        <Grid item xs={6}>
          <Card
            sx={{
              width: "400px",
              height: "30vh",
              borderRadius: "2vw",
              display: "Flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              backgroundColor: "#e3f2fd",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <GroupIcon style={{ width: "30%", height: "30%" }} />

              <Typography variant="h2">Total Users Count</Typography>
              <Typography
                variant="h3"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "30%",
                  height: "8vh",
                  backgroundColor: "#abd6ed",
                  borderRadius: "10%",
                  mt: 1,
                }}
              >
                {TotalUserCnt.userCount}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={6}>
          <Card
            sx={{
              width: "400px",
              height: "30vh",
              borderRadius: "2vw",
              display: "Flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              backgroundColor: "#e3f2fd",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <SubscriptionsIcon style={{ width: "30%", height: "30%" }} />
              <Typography variant="h2">Total Infinite Members</Typography>
              <Typography
                variant="h3"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "20%",
                  height: "8vh",
                  backgroundColor: "#abd6ed",
                  borderRadius: "10%",
                  mt: 1,
                }}
              >
                {InFiMemCnt.infinitemembercount}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "40vh",
          gap: 5,
        }}
      >
        <Grid item xs={6}>
          <Card
            sx={{
              width: "400px",
              height: "30vh",
              borderRadius: "2vw",
              display: "Flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              backgroundColor: "#e3f2fd",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <DevicesIcon style={{ width: "30%", height: "30%" }} />
              <Typography variant="h2">Active Sessions</Typography>
              <Typography
                variant="h3"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "30%",
                  height: "8vh",
                  backgroundColor: "#abd6ed",
                  borderRadius: "10%",
                  mt: 1,
                }}
              >
                {ActSession.actsessionscount}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={6}>
          <Card
            sx={{
              width: "400px",
              height: "30vh",
              borderRadius: "2vw",
              display: "Flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              backgroundColor: "#e3f2fd",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <LoginIcon style={{ width: "30%", height: "30%" }} />
              <Typography variant="h2">Last 30 days Active Users</Typography>
              <Typography
                variant="h3"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "20%",
                  height: "8vh",
                  backgroundColor: "#abd6ed",
                  borderRadius: "10%",
                  mt: 1,
                }}
              >
                {Last30Days.activeAdminCount}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Box>
    </Box>
  );
}
