// import React, { useState, useEffect } from "react";

// const ShraddhaInfinite = () => {
//   const [count, setCount] = useState(0);

//   useEffect(() => {
//     const savedCount = localStorage.getItem("visitCount");
//     const IncCount = savedCount ? Number(savedCount) : 0;
//     const IncCount2 = IncCount + 1;
    
//     setCount(IncCount2);
//     localStorage.setItem("visitCount",IncCount2);
//   }, []);

 
//   return (
//     <div style={styles.container}>
//       <p style={styles.text}>This page was viewed </p>
//       <h1 style={styles.count}>{count}</h1>
//       <p style={styles.text}>times</p>
//     </div>
//   );
// };

// const styles = {
//   container: {
//     backgroundColor: "#2d3342",
//     color: "#fff",
//     fontFamily: "'Muli', sans-serif",
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center",
//     justifyContent: "center",
//     height: "100vh",
//     margin: 0,
//   },
//   text: {
//     color: "rgba(247, 131, 131, 0.8)",
//     letterSpacing: "2px",
//     margin: 0,
//   },
//   count: {
//     fontSize: "70px",
//     margin: 0,
//   },
// };

// export default ShraddhaInfinite;


import React from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  CardActionArea,
} from "@mui/material";

export default function ShraddhaInfinite() {
  return (
    <Box
      sx={{
        ml: "30px",
        mt:"80px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "72vh", // Use minHeight for better responsive behavior
        // backgroundColor: "#fff",
      }}
    >
      <Card
        sx={{
          width: {
            xs: "90vw", // Wider card on smaller screens
            sm: "70vw", // Medium width on small screens
            md: "35vw", // Ideal width on medium and larger screens
          },
          height: {
            xs: "35vh", // Increased height for better proportion
            sm: "35vh",
            md: "36vh",
          },
          borderRadius: "12px", // Subtle rounded corners for the card itself
          boxShadow: {
            xs: "none",
            sm: "0 4px 10px rgba(0, 0, 0, 0.1)",
            md: "0 6px 15px rgba(0, 0, 0, 0.15)",
          },
          backgroundColor: "#000", // Black background for the card
          border: "2px solid #000", // Black border around the card
        }}
      >
        <CardActionArea>
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              textAlign: "center",
              padding: "16px",
            }}
          >
            {/* Logo above the title (not rounded) */}
            <img
              alt="Logo"
              src="/assets/Infinitelogo.png" // Replace this with your actual logo path
              style={{
                width: "300px",
                height: "160px",
                margin: "0vw",
                //marginBottom: "16px", // Margin below the logo
                objectFit: "contain", // Make sure the logo scales correctly
              }}
            />

            <Typography
              variant="body1"
              color="text.secondary"
              margin="0vw"
              sx={{
                fontSize: {
                  xs: "1.1rem", // Slightly larger font for small screens
                  sm: "1.2rem",
                  md: "1.8rem", // Larger subtitle for bigger screens
                },
                color: "#FFFFFF",
                fontStyle: "italic",
                fontWeight: "400", // Lighter subtitle weight
                letterSpacing: "0.5px",
              }}
            >
              Coming Soon...
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Box>
  );
}
