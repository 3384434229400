import { Routes, Route } from "react-router-dom";

import ShraddhaAdminUser from "../AdminPages/AdminUser";
import ShraddhaAdminEvent from "../AdminPages/AdminEvent";
import ShraddhaAdminTraning from "../AdminPages/TrainingVideos";
import ShraddhaInfiniteMember from "../AdminPages/InfiniteMember";
import AdminCourses from "../AdminPages/AdminCourses";
import VideoResourses from "../AdminPages/VideoResourses";
import AdminDashBoard from "../AdminPages/AdminDashBoard";

const AdminRoutes = () => {
  return (
    <Routes>
      <Route path="/VideoResourses" element={<VideoResourses />} />
      <Route path="/AdminDashBoard" element={<AdminDashBoard />} />
      <Route path="/ShraddhaAdminUser" element={<ShraddhaAdminUser />} />
      <Route path="/ShraddhaAdminEvent" element={<ShraddhaAdminEvent />} />
      <Route path="/AdminCourses" element={<AdminCourses />} />
      <Route path="/ShraddhaAdminTraning" element={<ShraddhaAdminTraning />} />
      <Route path="/ShraddhaInfiniteMember"element={<ShraddhaInfiniteMember />}/>
    
    </Routes>
  );
};

export default AdminRoutes;
