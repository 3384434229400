import { Routes, Route } from "react-router-dom";

import ShraddhaEvents from "../Shraddha_Members/Events/Events";
import LearningAndEvents from "../Shraddha_Members/support/Support";
import Support from "../Shraddha_Members/support/Support";
import ShraddhaMemeberTV from "../Shraddha_Members/courses/CourseVideo";
import CourseDetail from "../Shraddha_Members/courses/CourseDetilas";
import ShraddhaInfiniteCourses from "../Shraddha_Members/courses/Courses";
import MyCourses from "../Shraddha_Members/courses/MyCourses";
import TrainingVideos from "../Shraddha_Members/Events/TrainingVideos";
import ShraddhaInfinite from "../Shraddha_Members/Events/ShraddhaInfinite";
import WebsiteEvent from "../Shraddha_Members/Events/WebsiteEvent";


const MemberRoutes = () => {
  return (
    <Routes>
      <Route path="/ShraddhaEvents" element={<ShraddhaEvents />} />
      <Route path="/Support" element={<Support />} />
      <Route path="/ShraddhaMemeberTV" element={<ShraddhaMemeberTV />} />
      <Route path="/course-detail" element={<CourseDetail />} />
      <Route path="/ShraddhaCourses" element={<ShraddhaInfiniteCourses />} />
      <Route path="/MyCourses" element={<MyCourses />} />
      <Route path="/EventsRecording" element={<TrainingVideos />} />
      <Route path="/WebsiteEvent" element={<WebsiteEvent />} />
      <Route path="/ShraddhaInfinite" element={<ShraddhaInfinite />} />
    </Routes>
  );
};

export default MemberRoutes;
