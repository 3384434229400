import React, { useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Button,
  useTheme,
  Stack,
} from "@mui/material";
import { tokens } from "../../../theme";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import Header from "../../Dashboard/Header";
import PieChart from "../../Dashboard/ProgressCircle";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const FieldDashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [DashBord, setDashBord] = useState([
    {
      month: "Jan",
      total_sales_amount: "10000",
      total_purchase_amount: "8000",
    },
    {
      month: "Feb",
      total_sales_amount: "12000",
      total_purchase_amount: "9000",
    },
    {
      month: "Mar",
      total_sales_amount: "15000",
      total_purchase_amount: "11000",
    },
    {
      month: "Apr",
      total_sales_amount: "17000",
      total_purchase_amount: "13000",
    },
    {
      month: "May",
      total_sales_amount: "19000",
      total_purchase_amount: "14000",
    },
  ]);

  const formattedData = DashBord.map((item) => ({
    month: item.month,
    salesAmount: parseFloat(item.total_sales_amount),
    purchaseAmount: parseFloat(item.total_purchase_amount),
  }));

  const pendingMeetings = [
    { time: "10:00 AM", title: "Client Call with ABC Corp" },
    { time: "12:30 PM", title: "Project Review Meeting" },
    { time: "03:00 PM", title: "Team Sync-up" },
  ];

  return (
    <Box mt="80px" ml="30px" mr="20px">
      <Header title="DASHBOARD" subtitle="Welcome to Field Sales dashboard!" />

      <Box
        display="grid"
        gridTemplateColumns="repeat(12,1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        <Box
          gridColumn="span 12"
          gridRow="span 3"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                Revenue Generated
              </Typography>
              <Typography
                variant="h3"
                fontWeight="bold"
                color={colors.greenAccent[500]}
              >
                $59,342
              </Typography>
            </Box>
            <Box>
              <IconButton>
                <DownloadOutlinedIcon
                  sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                />
              </IconButton>
            </Box>
          </Box>
          <Box height="250px" mt="20px">
            <ResponsiveContainer width="100%" height={350}>
              <LineChart
                data={formattedData}
                margin={{ left: 20, right: 25, top: 20, bottom: 10 }}
              >
                <CartesianGrid strokeDasharray="2 2" />
                <XAxis dataKey="month" />
                <YAxis />
                <Tooltip />
                <Legend layout="horizontal" align="center" />
                <Line
                  dataKey="salesAmount"
                  stroke="#59a14f"
                  name="Sales"
                  strokeWidth={2}
                />
                <Line
                  dataKey="purchaseAmount"
                  stroke="#f28e2c"
                  name="Purchase"
                  strokeWidth={2}
                />
              </LineChart>
            </ResponsiveContainer>
          </Box>
        </Box>

        <Box
          gridColumn="span 5"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <Typography variant="h5" fontSize="20px" mt={1} ml={2}>
            Top Sales Product
          </Typography>
          <Box
            height="250px"
            width="250px"
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt="20px"
            p="0px"
            ml="15px"
            background={colors.primary[400]}
          >
            <PieChart width={130} height={130} />
          </Box>
        </Box>

        <Box
          gridColumn="span 7"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          padding="20px"
        >
          <Typography variant="h5" fontWeight="600" sx={{ p: "10px 0" }}>
            Today's Pending Meetings
          </Typography>

          {pendingMeetings.length > 0 ? (
            pendingMeetings.map((meeting, index) => (
              <Typography key={index} variant="body1" sx={{ mt: 1 }}>
                📅 {meeting.time} - {meeting.title}
              </Typography>
            ))
          ) : (
            <Typography variant="body2" sx={{ mt: 2 }}>
              No pending meetings for today.
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default FieldDashboard;
