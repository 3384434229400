import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import {
  Box,
  Typography,
  Button,
  // Select,
  // MenuItem,
  // FormControl,
  // InputLabel,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../../../theme";
import Header from "../../Dashboard/Header";
import axios from "axios";

export default function CreditorDashboard() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const token = localStorage.getItem("token");
  const cmpid = localStorage.getItem("cmpid");

  // Demo data for creditorData
  const [CrdDashboard, setCrdDashboard] = useState([]);
  const [PayTotal, setPayTotal] = useState([]);
  const [ReceiveTotal, setReceiveTotal] = useState([]);

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);

  // console.log(fromDate);
  //console.log(toDate);

  const handleChange = (event) => {
    const { name, value } = event.target;
    const formattedDate = value.replace(/-/g, "");
  
    if (name === "fromDate") {
      setFromDate(formattedDate);
    } else if (name === "toDate") {
      setToDate(formattedDate);
    }
  };
  
  useEffect(() => {
    if (fromDate && toDate) {
      fetchData();
    }
  }, [fromDate, toDate]);

    // Function to format "YYYYMMDD" back to "YYYY-MM-DD"
  const formatDateForInput = (dateString) => {
    if (!dateString) return ""; // Handle empty value
    return `${dateString.substring(0, 4)}-${dateString.substring(
      4,
      6
    )}-${dateString.substring(6, 8)}`;
  };

  //Function to fetch cmp list and User List ==========================================
  const fetchData = async () => {
    const DashData = {
      cmpid: cmpid,
      fromdate: fromDate,
      todate: toDate,
    };
console.log(DashData)
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/crDrManagement/dashBoard",
        DashData,

        {
          headers: { Authorization: token },
        }
      );

      const CrdDashboard = Object.values(response.data);
      setCrdDashboard(CrdDashboard);
    } catch (error) {
      console.log("Something went wrong ");
    }
  };
  //
  const PayTotalData = async () => {
    try {
      const cmpid = localStorage.getItem("cmpid");
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/crDrManagement/paybleTotal",
        { cmpid },
        {
          headers: { Authorization: token },
        }
      );

      const PayTotal = Object.values(response.data);

      setPayTotal(PayTotal[0]);
      // console.log(PayTotal[0])
    } catch (error) {
      console.log("Something went wrong ");
    }
  };

  const ReceiveTotalData = async () => {
    try {
      const cmpid = localStorage.getItem("cmpid");
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/crDrManagement/receivableTotal",
        { cmpid },
        {
          headers: { Authorization: token },
        }
      );

      const ReceiveTotal = Object.values(response.data);

      setReceiveTotal(ReceiveTotal[0]);
    } catch (error) {
      console.log("Something went wrong ");
    }
  };

  useEffect(() => {
    // fetchData();
    PayTotalData();
    ReceiveTotalData();
  }, []);

  const ReceivableList = CrdDashboard.map((deno2,index) => ({
    id: deno2.vch_id ?? `row-${index}`,
    month: deno2.month,
    year: deno2.year,
    total_sales_amount: `Rs. ${Math.abs(deno2.total_sales_amount).toFixed(2)}`,
    total_purchase_amount: `Rs. ${Math.abs(deno2.total_purchase_amount).toFixed(2)}`,
  }));

  const columns = [
    {
      field: "",
      headerName: "SR No",
      headerAlign: "center",
      align: "center",
      width: "100",
       renderCell: (params) => params.api.getRowIndex(params.id) + 1,
    },
    
    {
      field: "month",
      headerName: "Months",
      headerAlign: "center",
      align: "center",
      width: "200",
    },
    {
      field: "year",
      headerName: "Years",
      headerAlign: "center",
      align: "center",
      width: "200",
    },
    {
      field: "total_sales_amount",
      headerName: "Total Sales Amount",
      headerAlign: "center",
      align: "center",
      width: "200",
    },
    {
      field: "total_purchase_amount",
      headerName: "Total Purchase Amount",
      headerAlign: "center",
      align: "center",
      width: "200",
    },
  ];

  return (
    <Box mt="80px" ml="30px" mr="20px">
      <Header title="Dashboard" subtitle="Welcome to Dashboard!" />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        mb={0}
        flexDirection="row"
        gap="4vw"
        paddingBottom={2}
        paddingTop={0}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          padding={5}
          bgcolor="white"
          borderRadius={2}
          boxShadow={8}
          width="20vw"
          height="2vw"
        >
          <Typography variant="h3" color="primary" fontWeight={600}>
            Total Payable
          </Typography>
          <Typography variant="h3" color="error" fontWeight={700}>
            Rs. {PayTotal.total_amount}
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          padding={5.2}
          bgcolor="white"
          borderRadius={2}
          boxShadow={8}
          width="20vw"
          height="1vw"
        >
          <Typography variant="h3" color="primary" fontWeight={600}>
            Total Receivable
          </Typography>
          <Typography variant="h3" color="green" fontWeight={700}>
            Rs. {Math.abs(ReceiveTotal.total_amount)}
          </Typography>
        </Box>
      </Box>
      <Box m="2vw 0vw 1vw 1vw">
        <Typography variant="h2" color={colors.primary[150]}>
          Month-wise Analysis
        </Typography>

        {/* Date Filters */}
        <Box display="flex" alignItems="center" gap={2} mt={2}>
          <TextField
            type="date"
            label="From Date"
            InputLabelProps={{ shrink: true }}
            value={formatDateForInput(fromDate)}
            name="fromDate"
            onChange={handleChange}
          />

          <TextField
            type="date"
            label="To Date"
            InputLabelProps={{ shrink: true }}
            value={formatDateForInput(toDate)} // Convert back for display
            name="toDate"
            onChange={handleChange}
          />

          {/* <Button variant="contained" color="primary">
            Filter
          </Button> */}
        </Box>
      </Box>
      <Box
        height="74vh"
        sx={{
          ml: "1vw",
          mr: "1vw",
          overflowX: "auto",
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
            color: colors.primary[120],
            cursor: "default",
            // display:"flex",
            // justifyContent:"center",
          },
          "& .MuiDataGrid-columnHeader:focus": {
            outline: "none",
            boxShadow: "none",
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            outline: "none",
            boxShadow: "none",
          },
          "& .MuiDataGrid-columnHeader:focus-visible": {
            outline: "none",
            boxShadow: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
            color: colors.primary[150],
          },
        }}
      >
        <DataGrid rows={ReceivableList} columns={columns}
            checkboxSelection
            onRowSelectionModelChange={(newSelection) => setSelectedRows(newSelection.selectionModel)}
         />
      </Box>
    </Box>
  );
}
