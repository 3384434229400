import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { DataGrid } from "@mui/x-data-grid";

import axios from "axios";
import userEvent from "@testing-library/user-event";

export default function ShraddhaAdminEvent() {
  const [UsersData, setUsersData] = useState([]);
  const token = localStorage.getItem("shraddhatoken");
  const [formValues, setFormValues] = useState({
    id: "",
    name: "",
    event_Agenda: "",
    event_StartDate: "",
    event_EndDate: "",
    event_StartTime: "",
    event_EndTime: "",
    event_Location: "",
    event_RegLink: "",
    eventPublic: "",
  });
  const [ShowDialog, setShowDialog] = useState(false);

  const [isUpdate, setIsUpdate] = useState(false);

  const handleOpen = () => {
    setFormValues({
      id: "",
      name: "",
      event_Agenda: "",
      event_StartDate: "",
      event_EndDate: "",
      event_StartTime: "",
      event_EndTime: "",
      event_Location: "",
      event_RegLink: "",
      eventPublic: "",
    });
    setIsUpdate(false);
    setShowDialog(true);
  };

  const Closedialog = () => setShowDialog(false);

  const handleDateChange = (field, value) => {
    setFormValues((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleUpdate = async () => {
    const updateEvent = {
      eventId: formValues.id,
      eventName: formValues.name,
      eventSubName: "",
      eventAgenda: formValues.event_Agenda,
      StartDate: formValues.event_StartDate,
      EndDate: formValues.event_EndDate,
      StartTime: formValues.event_StartTime,
      EndTime: formValues.event_EndTime,
      Location: formValues.event_Location,
      RegLink: formValues.event_RegLink,
      isPublic: formValues.eventPublic,
    };

    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/shraddha/operation/eventUpdate",
        updateEvent,
        {
          headers: { Authorization: token },
        }
      );

      if (response) {
        alert(response.data.message);
        setShowDialog(false);
      }
    } catch (error) {
      alert("Error Update Event", error);
    }
  };

  const handleCreate = async () => {
    const newEvent = {
      eventId: formValues.id,
      eventName: formValues.name,
      eventSubName: "",
      eventAgenda: formValues.event_Agenda,
      StartDate: formValues.event_StartDate,
      EndDate: formValues.event_EndDate,
      StartTime: formValues.event_StartTime,
      EndTime: formValues.event_EndTime,
      Location: formValues.event_Location,
      RegLink: formValues.event_RegLink,
      isPublic: formValues.eventPublic,
    };
    console.log(newEvent);
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/shraddha/operation/eventCreate",
        newEvent,
        {
          headers: { Authorization: token },
        }
      );

      if (response) {
        alert(response.data.message);
        setShowDialog(false);
      }
    } catch (error) {
      alert("Error creating Event", error);
    }
  };

  const LedfetchData = (userRow) => {
    console.log(userRow);
    setFormValues({
      id: userRow.id,
      name: userRow.name,
      event_Agenda: userRow.event_Agenda,
      event_StartDate: userRow.event_StartDate,
      event_EndDate: userRow.event_EndDate,
      event_StartTime: userRow.event_StartTime,
      event_EndTime: userRow.event_EndTime,
      event_Location: userRow.event_Location,
      event_RegLink: userRow.event_RegLink,
      eventPublic: userRow.event_Public,
    });
    setIsUpdate(true);
    setShowDialog(true);
  };

  //function to delete select row
  const handleDelete = async (userRow) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/shraddha/operation/eventDelete",
        { eventId: userRow.id },
        {
          headers: { Authorization: token },
        }
      );

      if (response) {
        alert(response.data.message);
      }
    } catch (error) {
      alert("Error creating Delete", error);
    }
  };

  const fetchUsersData = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/shraddha/operation/eventList",
        {},
        {
          headers: { Authorization: token },
        }
      );
      const users = Object.values(response.data);
      setUsersData(users[0]);
      // console.log(users[0])
    } catch (error) {
      console.log("Error fetching group data", error);
    }
  };

  useEffect(() => {
    fetchUsersData();
  }, []);

  const userListRow = UsersData.map((event) => ({
    id: event.event_Id,
    name: event.event_Name,
    clicks: event.click_count,
    event_Agenda: event.event_Agenda,
    event_StartDate: event.event_StartDate
      ? new Date(event.event_StartDate).toISOString().split("T")[0]
      : "",
    event_EndDate: event.event_EndDate
      ? new Date(event.event_EndDate).toISOString().split("T")[0]
      : "",
    event_StartTime: event.event_StartTime,
    event_EndTime: event.event_EndTime,
    event_Location: event.event_Location,
    event_RegLink: event.event_RegLink,
    event_Public: event.event_Public,
  }));

  const columns = [
    {
      field: "Sr No",
      headerName: "Sr No",
      headerAlign: "center",
      align: "center",
      width: 80,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => params.api.getRowIndex(params.id) + 1,
    },
    {
      field: "clicks",
      headerName: "Reg Clicks",
      headerAlign: "center",
      align: "center",
      width: 150,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "name",
      headerName: "Name",
      headerAlign: "center",
      align: "center",
      width: 250,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "event_Agenda",
      headerName: "Agenda",
      headerAlign: "center",
      align: "center",
      width: 150,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "event_StartDate",
      headerName: "Start Date",
      headerAlign: "center",
      align: "center",
      width: 150,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "event_EndDate",
      headerName: "End Date",
      headerAlign: "center",
      align: "center",
      width: 150,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "event_StartTime",
      headerName: "Start Time",
      headerAlign: "center",
      align: "center",
      width: 150,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "event_EndTime",
      headerName: "End Time",
      headerAlign: "center",
      align: "center",
      width: 150,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "event_Location",
      headerName: "Location",
      headerAlign: "center",
      align: "center",
      width: 150,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "event_RegLink",
      headerName: "Reg Link",
      headerAlign: "center",
      align: "center",
      width: 150,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      width: 150,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <div>
            <Button
              variant="contained"
              style={{
                backgroundColor: "gray",
                color: "ffffff",
                marginRight: "10px",
                "&:hover": {
                  backgroundColor: "gray",
                  color: "ffffff",
                },
              }}
              size="small"
              type="button"
              onClick={() => LedfetchData(params.row)}
            >
              Update
            </Button>

            <Button
              variant="contained"
              style={{
                backgroundColor: "red",
                color: "ffffff",
                "&:hover": {
                  backgroundColor: "red",
                  color: "ffffff",
                },
              }}
              size="small"
              type="button"
              onClick={() => handleDelete(params.row)}
            >
              Delete
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        paddingTop: 8,
        paddingLeft: 3,
        paddingRight: 3,
        marginLeft: "240px",
        marginTop: 4,
        backgroundColor: "#f4f6f7",
        borderRadius: "10px",
      }}
    >
      {/* ================== Show Single Ledger Data ===================== */}
      <Dialog
        open={ShowDialog}
        sx={{
          "& .MuiDialog-paper": {
            backgroundColor: "#f0f0f0",
            padding: 2,
          },
        }}
      >
        <DialogTitle
          variant="h3"
          gutterBottom
          p={0}
          m={0}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            color: "#000",
          }}
        >
          {isUpdate ? "Update Event Details" : "Add New Event"}

          <CancelIcon
            sx={{
              cursor: "pointer",
              fontSize: "24px", // Adjust size if necessary
            }}
            onClick={Closedialog}
          />
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={2} mt={0}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Name"
                name="Name"
                size="small"
                fullWidth
                value={formValues.name}
                onChange={(e) => handleDateChange("name", e.target.value)}
                sx={{
                  "& .MuiInputBase-input": {
                    color: "black", // Input text color
                  },
                  "& .MuiInputLabel-root": {
                    color: "black", // Label text color
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "black", // Default border color
                    },
                    "&:hover fieldset": {
                      borderColor: "black", // Hover border color
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "black", // Focused border color
                    },
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth size="small">
                <InputLabel id="course-label" sx={{ color: "black" }}>
                  Publish Type
                </InputLabel>
                <Select
                  name="courseId" // State key for course ID
                  labelId="course-label"
                  value={formValues.eventPublic}
                  onChange={(e) =>
                    handleDateChange("eventPublic", e.target.value)
                  }
                  label="Publish Type"
                  fullWidth
                  sx={{
                    "& .MuiInputBase-input": {
                      color: "black", // Input text color
                    },
                    "& .MuiInputLabel-root": {
                      color: "black", // Label text color
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "black", // Default border color
                      },
                      "&:hover fieldset": {
                        borderColor: "black", // Hover border color
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "black", // Focused border color
                      },
                    },
                  }}
                >
                  <MenuItem key={1} value={1}>
                    Public
                  </MenuItem>
                  <MenuItem key={0} value={0}>
                    Private
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={12}>
              <TextField
                label="Agenda"
                name="agenda"
                size="small"
                fullWidth
                value={formValues.event_Agenda}
                onChange={(e) =>
                  handleDateChange("event_Agenda", e.target.value)
                }
                sx={{
                  "& .MuiInputBase-input": {
                    color: "black", // Input text color
                  },
                  "& .MuiInputLabel-root": {
                    color: "black", // Label text color
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "black", // Default border color
                    },
                    "&:hover fieldset": {
                      borderColor: "black", // Hover border color
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "black", // Focused border color
                    },
                  },
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} mt={0}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Start Date"
                name="StartDate"
                type="Date"
                size="small"
                fullWidth
                value={formValues.event_StartDate}
                onChange={(e) =>
                  handleDateChange("event_StartDate", e.target.value)
                }
                InputLabelProps={{ shrink: true }}
                sx={{
                  "& .MuiInputBase-input": {
                    color: "black", // Input text color
                  },
                  "& .MuiInputLabel-root": {
                    color: "black", // Label text color
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "black", // Default border color
                    },
                    "&:hover fieldset": {
                      borderColor: "black", // Hover border color
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "black", // Focused border color
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="End Date"
                name="EndDate"
                type="date"
                size="small"
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={formValues.event_EndDate}
                onChange={(e) =>
                  handleDateChange("event_EndDate", e.target.value)
                }
                sx={{
                  "& .MuiInputBase-input": {
                    color: "black", // Input text color
                  },
                  "& .MuiInputLabel-root": {
                    color: "black", // Label text color
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "black", // Default border color
                    },
                    "&:hover fieldset": {
                      borderColor: "black", // Hover border color
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "black", // Focused border color
                    },
                  },
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} mt={0}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Start Time"
                name="StartTime"
                type="time"
                size="small"
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={formValues.event_StartTime}
                onChange={(e) =>
                  handleDateChange("event_StartTime", e.target.value)
                }
                sx={{
                  "& .MuiInputBase-input": {
                    color: "black", // Input text color
                  },
                  "& .MuiInputLabel-root": {
                    color: "black", // Label text color
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "black", // Default border color
                    },
                    "&:hover fieldset": {
                      borderColor: "black", // Hover border color
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "black", // Focused border color
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="End Time"
                name="EndTime"
                type="time"
                size="small"
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={formValues.event_EndTime}
                onChange={(e) =>
                  handleDateChange("event_EndTime", e.target.value)
                }
                sx={{
                  "& .MuiInputBase-input": {
                    color: "black", // Input text color
                  },
                  "& .MuiInputLabel-root": {
                    color: "black", // Label text color
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "black", // Default border color
                    },
                    "&:hover fieldset": {
                      borderColor: "black", // Hover border color
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "black", // Focused border color
                    },
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <TextField
                label="Location"
                name="Location"
                type="text"
                size="small"
                fullWidth
                value={formValues.event_Location}
                onChange={(e) =>
                  handleDateChange("event_Location", e.target.value)
                }
                sx={{
                  "& .MuiInputBase-input": {
                    color: "black", // Input text color
                  },
                  "& .MuiInputLabel-root": {
                    color: "black", // Label text color
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "black", // Default border color
                    },
                    "&:hover fieldset": {
                      borderColor: "black", // Hover border color
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "black", // Focused border color
                    },
                  },
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} mt={0}>
            <Grid item xs={12}>
              <TextField
                label="Registration Link"
                name="EventLink"
                size="small"
                fullWidth
                value={formValues.event_RegLink}
                onChange={(e) =>
                  handleDateChange("event_RegLink", e.target.value)
                }
                sx={{
                  "& .MuiInputBase-input": {
                    color: "black", // Input text color
                  },
                  "& .MuiInputLabel-root": {
                    color: "black", // Label text color
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "black", // Default border color
                    },
                    "&:hover fieldset": {
                      borderColor: "black", // Hover border color
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "black", // Focused border color
                    },
                  },
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions
          sx={{ display: "flex", justifyContent: "right", padding: 1 }}
        >
          <Button
            size="small"
            variant="contained"
            onClick={Closedialog}
            sx={{ borderRadius: "10px", background: "red" }}
          >
            Cancel
          </Button>

          <Button
            color="success"
            size="small"
            variant="contained"
            onClick={isUpdate ? handleUpdate : handleCreate}
            sx={{
              borderRadius: "10px",
              backgroundColor: "green", // Set background color to green
              color: "white", // Set text color to white
              "&:hover": {
                backgroundColor: "darkgreen", // Optional: Darker green on hover
              },
            }}
          >
            {isUpdate ? "Update" : "Add"}
          </Button>
        </DialogActions>
      </Dialog>

      <Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h2" color="#000">
            All Events Details
          </Typography>
          <Button
            variant="contained"
            size="small"
            sx={{
              borderRadius: "10px",
              backgroundColor: "green", // Set background color to green
              color: "white", // Set text color to white
              "&:hover": {
                backgroundColor: "darkgreen", // Optional: Darker green on hover
              },
            }}
            type="button"
            onClick={handleOpen}
          >
            + Add Event
          </Button>
        </Box>
        <Box
          height="74vh"
          sx={{
            overflowX: "auto",
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
              color: "black", // Set cell text color to black
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#E9F3F5",
              borderBottom: "none",
              cursor: "default",
              color: "black", // Set header text color to black
            },
            "& .MuiDataGrid-columnHeader:focus": {
              outline: "none",
              boxShadow: "none",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              outline: "none",
              boxShadow: "none",
            },
            "& .MuiDataGrid-columnHeader:focus-visible": {
              outline: "none",
              boxShadow: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: "#fff", // Background color for the scroller
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: "#E9F3F5",
            },
            "& .MuiDataGrid-overlay": {
              backgroundColor: "#ffffff", // Set overlay background color when table is empty
              color: "gray", // Optional: set text color for empty state message
            },
          }}
        >
          <DataGrid
            rows={userListRow}
            columns={columns}
            disableColumnSort={true}
          />
        </Box>
      </Box>
    </Box>
  );
}
