import { Box, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../../theme";
import Header from "../../Dashboard/Header";
import React, { useEffect, useState } from "react";
import axios from "axios";

export default function AdminReport() {
  const [AdminVchs, setVouchers] = useState([]);
  const token = localStorage.getItem("token");
  const cmpid = localStorage.getItem("cmpid");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    //function to fetch all approval Voucher Details
    const fetchData = async () => {
      try {
        const response = await axios.post(
          process.env.REACT_APP_API_URL + "/approvalModule/vchAdminReport",
          { cmpid },
          {
            headers: { Authorization: token },
          }
        );
        const AdminArrayData = Object.values(response.data);
        setVouchers(AdminArrayData);
      } catch (error) {
        alert("Error fetching data", error);
      }
    };

    fetchData();
  });

  const convertDate = (dateString) => {
    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6);
    const day = dateString.substring(6, 8);
    return `${day} /${month} /${year}`;
  };

  const AdminStatusRow = Array.isArray(AdminVchs)
    ? AdminVchs.map((AdminVch) => ({
        id: AdminVch.vch_id,
        vchDate: convertDate(AdminVch.vch_date),
        partyName: AdminVch.PartyName,
        vchNumber: AdminVch.vch_number,
        vchAmount: -1 * AdminVch.vch_Amount,
        isPrimary: AdminVch.isPrimary === 1 ? "Approved" : "Pending",
        Primary_Approver: AdminVch.Primary_Approver,
        isSecondary: AdminVch.isSecondary === 1 ? "Approved" : "Pending",
        Secondary_Approver: AdminVch.Secondary_Approver,
        isOptional: AdminVch.isOptional === 0 ? "Approved" : "Pending",
        Final_Approver: AdminVch.Final_Approver,
      }))
    : [];

  //columns for tables
  const AdminColumns = [
    {
      field: "vchNumber",
      headerName: "Voucher Number",
      headerAlign: "center",
      align: "center",
      width: 140,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "vchDate",
      headerName: "Voucher Date",
      headerAlign: "center",
      align: "center",
      width: 130,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "partyName",
      headerName: "Party Name",
      headerAlign: "center",
      align: "center",
      width: 200,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "vchAmount",
      headerName: "Amount",
      headerAlign: "center",
      align: "center",
      width: 100,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "isPrimary",
      headerName: "Primary ",
      headerAlign: "center",
      align: "center",
      width: 130,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <span style={{ color: params.value === "Approved" ? "green" : "red" }}>
          {params.value}
        </span>
      ),
    },
    {
      field: "Primary_Approver",
      headerName: "Primary Approver",
      headerAlign: "center",
      align: "center",
      width: 140,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "isSecondary",
      headerName: "Secondary ",
      headerAlign: "center",
      align: "center",
      width: 130,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <span style={{ color: params.value === "Approved" ? "green" : "red" }}>
          {params.value}
        </span>
      ),
    },
    {
      field: "Secondary_Approver",
      headerName: "Secondary Approver",
      headerAlign: "center",
      align: "center",
      width: 170,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "isOptional",
      headerName: "Final ",
      headerAlign: "center",
      align: "center",
      width: 130,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <span style={{ color: params.value === "Approved" ? "green" : "red" }}>
          {params.value}
        </span>
      ),
    },
    {
      field: "Final_Approver",
      headerName: "Final Approver",
      headerAlign: "center",
      align: "center",
      width: 130,
      sortable: false,
      disableColumnMenu: true,
    },
  ];

  return (
    <Box mt="80px" ml="30px" mr="20px">
      <Header title="Approval Admin Report" subtitle="Voucher Approval List" />
      <Box
        height="74vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
            color: colors.primary[120],
            cursor: "default", // Change cursor to default to remove pointer effect
          },
          "& .MuiDataGrid-columnHeader:focus": {
            outline: "none", // Remove the outline when the header is focused
            boxShadow: "none", // Remove the box shadow when the header is focused
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            // This targets the header title specifically
            outline: "none", // Remove outline on click
            boxShadow: "none", // Remove box shadow on click
          },
          "& .MuiDataGrid-columnHeader:focus-visible": {
            // Remove the focus-visible effect when clicking
            outline: "none", // Remove the outline when focused
            boxShadow: "none", // Remove any shadow or border
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
            color: colors.primary[150],
          },
        }}
      >
        <DataGrid
          rows={AdminStatusRow}
          columns={AdminColumns.map((col) => ({ ...col, sortable: false }))}
          disableColumnSort={true}
        />
      </Box>
    </Box>
  );
}
