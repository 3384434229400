import {
  Box,
  useTheme,
  Card,
  CardContent,
  Typography,
  Grid,
  Checkbox 
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../../theme";
import Header from "../../Dashboard/Header";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import dayjs from "dayjs";

export default function ReceivableReport() {
  const navigate = useNavigate();
  const theme = useTheme();
  const token = localStorage.getItem("token");
  const cmpid = localStorage.getItem("cmpid");
  const colors = tokens(theme.palette.mode);
  const [Receivable, setReceivable] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  // Fetch data from API
  const fetchData = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/crDrManagement/receivableReport",
        { cmpid },
        {
          headers: { Authorization: token },
        }
      );

      const ReceivableReport = Object.values(response.data);
      console.log("Receivable Data", ReceivableReport);
      setReceivable(ReceivableReport);
    } catch (error) {
      alert("Error fetching data", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const ReceivableList = Receivable.map((Receivables) => ({
    id: Receivables.vch_id,
    vch_type: Receivables.vch_type,
    vch_number: Receivables.vch_number,
    vch_date: Receivables.vch_date,
    PartyName: Receivables.PartyName,
    vch_Amount: `Rs. ${Math.abs(Receivables.vch_Amount).toFixed(2)}`,
  }));

  // Calculate Summary Data
  const invoiceCount = Receivable.length;
  const totalDue = Receivable.reduce(
    (sum, item) => sum + Math.abs(parseFloat(item.vch_Amount || 0)),
    0
  );
  const highestDue = Receivable.length
    ? Math.max(
        ...Receivable.map((item) => Math.abs(parseFloat(item.vch_Amount || 0)))
      )
    : 0;

  const columns = [
    {
      field: "",
      headerName: "Sr No",
      headerAlign: "center",
      align: "center",
      width: 150,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => params.api.getRowIndex(params.id) + 1,
    },
    {
      field: "PartyName",
      headerName: "Customer Name",
      headerAlign: "center",
      align: "center",
      width: 350,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "vch_Amount",
      headerName: "Due Amount",
      headerAlign: "center",
      align: "center",
      width: 250,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "vch_date",
      headerName: "Due Date",
      headerAlign: "center",
      align: "center",
      width: 250,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => dayjs(params.value).format("DD/MM/YYYY"),
    },
  ];

  return (
    <Box mt="80px" ml="30px" mr="20px">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
       
      >
        <Header title="Reports" subtitle="Receivable Report" />
      </Box>

      {/* Summary Cards */}
      <Grid
        container
        spacing={2}
        ml={0}
        mr={0}
        mb={2}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexdirection="row"
      >
        <Grid item xs={12} sm={4}>
          <Card
            sx={{
              backgroundColor: colors.primary[400],
              boxShadow: "8",
              borderRadius: "1vw",
              width: "93%",
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography variant="h3" color={colors.primary[120]}>Total Due</Typography>
              <Typography variant="h3" color="green" fontWeight={700}>Rs. {highestDue.toFixed(2)}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card
            sx={{
              backgroundColor: colors.primary[400],
              boxShadow: "8",
              borderRadius: "1vw",
              width: "93%",
            }}
          >
            {" "}
            <CardContent
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography variant="h3" color={colors.primary[120]}>Total Receivables</Typography>
              <Typography variant="h3" color="green" fontWeight={700}>{invoiceCount}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card
            sx={{
              backgroundColor: colors.primary[400],
              boxShadow: "8",
              borderRadius: "1vw",
              width: "93%",
            }}
          >
            {" "}
            <CardContent
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography variant="h3" color={colors.primary[120]}>Highest Due</Typography>
              <Typography variant="h3" color="green" fontWeight={700}>Rs. {totalDue.toFixed(2)}</Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Data Grid */}
      <Box
        height="74vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
            color: colors.primary[120],
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
            color: colors.primary[150],
          },
        }}
      >
        <DataGrid rows={ReceivableList} columns={columns}
                    checkboxSelection
                    onRowSelectionModelChange={(newSelection) => setSelectedRows(newSelection.selectionModel)}
         />
      </Box>
    </Box>
  );
}
