import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import Stack from "@mui/material/Stack";


export default function SalesAndPurchasesChart() {
  const token = localStorage.getItem("token");
  const cmpid = localStorage.getItem("cmpid");
  const [DashBord, setDashBord] = useState([]);

  const formattedData = DashBord.map((item) => ({
    month: item.month,
    salesAmount: parseFloat(item.total_sales_amount), 
    purchaseAmount: parseFloat(item.total_purchase_amount),
  }));

  const fetchData = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/crDrManagement/dashBoard",
        { cmpid },
        {
          headers: { Authorization: token },
        }
      );

      const VarDashBord = Object.values(response.data);
      setDashBord(VarDashBord);
      // console.log(VarDashBord);
    } catch (error) {
      alert("Error fetching data", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Stack direction="column" spacing={2} alignItems="center" sx={{ width: "100%" }}>
      <ResponsiveContainer width="100%" height={400}>
        <LineChart
          data={formattedData}
          margin={{ left: 20, right: 25, top: 50, bottom: 10 }} // Margin applied here
        >
          <CartesianGrid strokeDasharray="2 2" />
          <XAxis dataKey="month" />
          <YAxis />
          <Tooltip />
          <Legend layout="horizontal" align="center"/>
          <Line
            dataKey="salesAmount"
            stroke="#59a14f"
            name="Sales"
            strokeWidth={2} // Makes the line bolder
          />
          <Line
            dataKey="purchaseAmount"
            stroke="#f28e2c"
            name="Purchase"
            strokeWidth={2} // Makes the line bolder
       
          />
        </LineChart>
      </ResponsiveContainer>
    </Stack>
  );
}
