import React, { useState, useEffect } from "react";
import {
  Box,
  Paper,
  Typography,
  TextField,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  Dialog,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import StylishLoadingDialog from "../InfinitePages/Dashboard/StatBox";
// import backgroundImage from '../../public/assets/sign up BG_equal.png';

// Custom styled TextField component to match the style
const SignupTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputLabel-root": { color: "#000" }, // Label text color black
  "& .MuiInputLabel-root.Mui-focused": { color: "#000" }, // Label text color black on focus
  "& .MuiOutlinedInput-root": {
    backgroundColor: "white", // Background color white
    "& fieldset": {
      borderColor: "#000", // Border color black
    },
    "&:hover fieldset": {
      borderColor: "#000", // Border color black on hover
    },
    "&.Mui-focused fieldset": {
      borderColor: "#000", // Border color black on focus
    },
  },
  "& .MuiOutlinedInput-input": { color: "#000" }, // Input text color black
  "& .MuiFormControlLabel-label": {
    color: "#000", // Label text color black
  },
  "& .MuiInputBase-root": {
    backgroundColor: "white",
  },
  "& .MuiInputBase-input": {
    color: "black",
  },
  "& .MuiInputBase-input:-webkit-autofill": {
    WebkitBoxShadow: "0 0 0px 1000px white inset",
    WebkitTextFillColor: "black",
  },
}));

const Login = () => {
  const [userEmail, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [activeSessions, setActiveSessions] = useState([]);
  const navigate = useNavigate();

  // const [captchtoken, setCaptchtoken] = useState("");

  //function to handle user Login
  const handleLogin = async () => {
    if (!userEmail || !password) {
      setMessage("Username and password are required.");
      return;
    }

    // if (!captchtoken) {
    //   setMessage("Please complete the Turnstile verification.");
    //   return;
    // }

    const device = getDeviceInfo();
    setMessage("");
    setLoading(true);

    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/login`,
        {
          userEmail: userEmail,
          password,
          device,
        }
      );
    //  console.log("Data ", data);

      if (data.Sub === "m") {
        localStorage.setItem("token", data.token);
        localStorage.setItem("role",data.role);
        navigate("/Member/ShraddhaEvents");
      } else if (data.Sub === "n") {
        localStorage.setItem("role",data.role);
        localStorage.setItem("guestToken", data.guestToken);
        navigate("/Gauest/GauestEvents");
      } else {
        alert("Access Denied ! Please Contact +91 020 41488999");
      }
    } catch (error) {
      if (error.response.status == 409) {
        setOpenDialog(true);
        setActiveSessions(error.response.data.activeSessions);
        console.log("error.response.data", error.response.data);
      } else if (error.response.status == 403) {
        localStorage.setItem("regiEmail", userEmail);
        navigate("/Auth/Signupconfirm");
      } else {
        setMessage(
          error.response?.data?.message || error.message || "An error occurred"
        );
      }
    } finally {
      setLoading(false);
    }
  };

  function getDeviceInfo() {
    const userAgent = navigator.userAgent;
    let deviceName = "Unknown Device";

    // Check for various devices based on user agent string
    if (/iPhone|iPad|iPod/i.test(userAgent)) {
      deviceName = "Apple iOS Device";
    } else if (/Android/i.test(userAgent)) {
      deviceName = "Android Device";
    } else if (/Windows NT/i.test(userAgent)) {
      deviceName = "Windows Device";
    } else if (/Macintosh/i.test(userAgent)) {
      deviceName = "MacOS Device";
    } else if (/Linux/i.test(userAgent)) {
      deviceName = "Linux Device";
    }

    return deviceName;
  }

  const handleLogoutSession = async (token) => {
    setLoading(true);
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/auth/logout`, {
        token,
      });
    } catch (error) {
      console.log("your session are expired");
    }

    setLoading(false);
    setOpenDialog(false);
  };

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  //==================================================

  const NavigatePage = () => {
    const token = localStorage.getItem("token");
    const guestToken = localStorage.getItem("guestToken");

    if (guestToken) {
      navigate("/Gauest/GuestEvents");
    } else if (token) {
      navigate("/Member/ShraddhaEvents");
    }
  };

  useEffect(() => {
    NavigatePage();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        backgroundImage: "url('../../assets/sign up BG_equal.png')",
        backgroundSize: "cover",
        backgroundPosition: "center",
        flexDirection: "column",
      }}
    >
      {/* ==================== Add marquee text====================================== */}
      <Box
        sx={{
          backgroundColor: "black", //"#FFFFC5",
          color: "white",
          width: "100%",
          display: "flex",
          alignItems: "center",
          paddingBottom: "5px",
          position: "fixed",
          top: 0,
          marginTop: "10px",
        }}
      >
        <marquee>
          <Button
            variant="contained"
            size="small"
            sx={{
              mt: "2px",
              mr: 2,
              backgroundColor: "#B09246",
              ":hover": { backgroundColor: "#B09246" },
              borderRadius: "10px",
            }}
            onClick={() =>
              window.open(
                "https://shraddhainfosystems.od2.vtiger.com/appointments/bookfreeconsultation"
              )
            }
          >
            Book Demo
          </Button>
          <Typography
            sx={{
              display: "inline-block",
              animation: " 5s linear infinite", // Adjust timing for slower speed
              whiteSpace: "nowrap",
            }}
          >
            FREE Shraddha Infinite Tally BackUp on Google Drive for 6 Months
          </Typography>
          <Button
            variant="contained"
            size="small"
            sx={{
              mt: "2px",
              ml: 2,
              backgroundColor: "#B09246",
              ":hover": { backgroundColor: "#B09246" },
              borderRadius: "10px",
            }}
            onClick={() =>
              window.open(
                "https://shraddhainfosystems.od2.vtiger.com/appointments/bookfreeconsultation"
              )
            }
          >
            Book Demo
          </Button>
        </marquee>
      </Box>

      {/* =========================== */}

      <Paper
        elevation={1}
        sx={{
          display: "flex",
          flexDirection: "column",
          width: {
            xs: "300px",
            sm: "300px",
            md: "400px",
            lg: "400px", // Increased height for larger screens
            xl: "400px", // Extra large screens
          }, //"",
          padding: {
            xs: "20px",
            sm: "20px",
            md: "20px",
            lg: "20px", // Increased height for larger screens
            xl: "20px", // Extra large screens
          },
          borderRadius: "10px",
          backgroundColor: "#ebfafc",
        }}
      >
        <Typography
          variant="h4"
          align="center"
          sx={{ color: "#000", marginBottom: "30px", m: "0", p: "0" }}
        >
          <img
            alt="logo"
            width="250px"
            height="120px"
            style={{ borderRadius: "15px" }}
            src="../../assets/Infinitelogo.png"
          />
        </Typography>

        <Divider style={{ margin: "16px 0" }} />

        <SignupTextField
          label="Email"
          variant="outlined"
          fullWidth
          size="small"
          margin="normal"
          required
          value={userEmail}
          onChange={(e) => setUsername(e.target.value)}
          onKeyPress={handleKeyPress}
          disabled={loading}
        />

        <SignupTextField
          label="Password"
          size="small"
          variant="outlined"
          type={showPassword ? "text" : "password"} // Toggle between text and password
          fullWidth
          margin="normal"
          required
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onKeyPress={handleKeyPress} // Listen for Enter key press
          disabled={loading}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleClickShowPassword} // Toggle password visibility
                  onMouseDown={handleMouseDownPassword} // Prevent default on mouse down
                  edge="end"
                  aria-label="toggle password visibility" // Improved accessibility
                  sx={{
                    color: "black", // Set the icon button color to black
                  }}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {message && <Typography color="error">{message}</Typography>}

        {/* =============== ReCAPTCHA code ================= */}
        {/* <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Turnstile
            sitekey="0x4AAAAAAA6ja7V0nCpcgzLs" // Replace with your Turnstile site key
            onVerify={(newToken) => setCaptchtoken(newToken)}
            onExpire={() => setCaptchtoken("")}
          />
        </Box> */}
        {/* =============================== */}

        <Button
          variant="contained"
          fullWidth
          sx={{
            marginTop: "20px",
            //   marginBottom: "20px",
            backgroundColor: "#B09246",
            ":hover": { backgroundColor: "#B09246" },
            borderRadius: "10px",
          }}
          onClick={handleLogin}
          disabled={loading}
        >
          {loading ? "Signing in..." : "Sign in"}
        </Button>

        <Divider style={{ margin: "16px 0" }} />

        <Box
          display="flex"
          justifyContent="space-between"
          sx={{ marginTop: "1px" }}
        >
          <Link to="/Auth/LoginWithOTP" style={{ textDecoration: "none" }}>
            <Typography
              variant="body1"
              sx={{ color: "Blue", fontSize: "15px" }}
            >
              Login With OTP
            </Typography>
          </Link>

          <Link to="/Auth/signup" style={{ textDecoration: "none" }}>
            <Typography
              variant="body2"
              sx={{ color: "blue", fontSize: "16px" }}
            >
              Register
            </Typography>
          </Link>
        </Box>

        {/* ========================= */}

        <Box sx={{ mt: 1 }}>
          <Link to="/Auth/forgot-password" style={{ textDecoration: "none" }}>
            <Typography
              variant="body2"
              sx={{ color: "#000", fontSize: "15px" }}
            >
              Forgot Password?
            </Typography>
          </Link>
        </Box>
      </Paper>

      <StylishLoadingDialog loading={loading} />

      {/* Confirmation Dialog */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Active Sessions</DialogTitle>
        <DialogContent>
          <Typography variant="body1" sx={{ marginBottom: "20px" }}>
            Session limit exceeded. Please log out from another device.
          </Typography>
          <List>
            {activeSessions.map((session) => (
              <ListItem key={session.session_Id}>
                <ListItemText
                  primary={`Device : ${session.session_device}`}
                  secondary={`Login at: ${session.created_at}`}
                />
                <ListItemSecondaryAction>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => handleLogoutSession(session.token)}
                  >
                    Logout
                  </Button>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default Login;
