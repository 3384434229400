import { Box, Switch, useTheme, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import Header from "../Dashboard/Header";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";
import axios from "axios";

export default function TallyCompanies() {
  const navigate = useNavigate();
  const theme = useTheme();
  const token = localStorage.getItem("token");
  const colors = tokens(theme.palette.mode);
  const [ModuleList, setModuleList] = useState([]);
  const [SelectCompany, setSelectCompany] = useState("");
  const [selectedId, setSelectedId] = useState(null);
  const [selectedName, setSelectedName] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [cmpNames, setCmpNames] = useState([]);
  const [moduleData, setModuleData] = useState([]);
  const [openModule, setOpenModule] = useState(false);

  const [CmpNames, setcmpName] = useState([]);
  useEffect(() => {
    fetchData();
    fetchModuleData();
  }, []);

  const handleSelect = (id, Cmp_Name) => {
    setSelectedId(selectedId === id ? null : id);
    setSelectedName(selectedName === Cmp_Name ? null : Cmp_Name);
    localStorage.setItem("cmpid", id);
    localStorage.setItem("cmpname", Cmp_Name);
    window.location.reload();
  };

  const fetchData = async () => {
    if (!token) {
      navigate("/Auth/");
      return;
    }

    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/company/list",
        {},
        {
          headers: { Authorization: token },
        }
      );
      const CmpListData = response.data.users;
      setcmpName(CmpListData);
    } catch (error) {
      alert("Error fetching data", error);
    }
  };

  // Fetch module data and convert it into an array for proper rendering
  const fetchModuleData = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/module/list`,
        {},
        {
          headers: { Authorization: token },
        }
      );

      // Convert object response to array
      const modules = Object.keys(response.data).map(
        (key) => response.data[key]
      );
      console.log(modules);

      setModuleList(modules);
      setModuleData(modules); // Ensure module data is set correctly for toggling
    } catch (error) {
      console.error("Error fetching module data", error);
    }
  };

  function formatTimestamp(timestamp) {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${day}-${month}-${year}  ${hours}:${minutes}`;
  }

  const CompanyListRow = CmpNames.map((users) => ({
    id: users.Cmp_Id,
    Cmp_Name: users.Cmp_Name,
    last_sync: formatTimestamp(users.updated_At),
  }));

  const columns = [
    {
      field: "Sr No",
      headerName: "Sr No",
      headerAlign: "center",
      align: "center",
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => params.api.getRowIndex(params.id) + 1,
    },

    {
      field: "Cmp_Name",
      headerName: "Company Name",
      headerAlign: "center",
      align: "center",
      width: 300,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "last_sync",
      headerName: "last Sync",
      headerAlign: "center",
      align: "center",
      width: 200,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "Action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      width: 300,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const { id, Cmp_Name } = params.row;
        const isSelected = localStorage.getItem("cmpid") === id;
        const selectedName = localStorage.getItem("cmpname") === Cmp_Name;

        return (
          <Box>
            <Button
              variant="contained"
              style={{
                backgroundColor: isSelected ? "green" : colors.blueAccent[700],
                color: isSelected ? "white" : colors.primary[150],
              }}
              size="small"
              type="button"
              onClick={() => handleSelect(id, Cmp_Name)}
            >
              {isSelected ? " Active " : "Select"}
            </Button>

            {/* ============================================= */}

            <Button
              variant="contained"
              style={{
                backgroundColor: colors.blueAccent[700],
                color: colors.primary[150],
                marginLeft: "20px",
              }}
              size="small"
              type="button"
              onClick={() => handleMdOpen(id)}
            >
              Add Module
            </Button>
          </Box>
        );
      },
    },
  ];

  //=========show module data code =====================

  // Open the module configuration dialog
  const handleMdOpen = (id) => {
    setSelectedCompany(id);
    setOpenModule(true);
  };

  // Close the module configuration dialog
  const handleMdClose = () => setOpenModule(false);

  // Handle toggle change for modules
  const handleToggleChange = (moduleId) => {
    setModuleData((prevData) =>
      prevData.map((module) =>
        module.module_Id === moduleId
          ? { ...module, isEnabled: module.isEnabled === 1 ? 0 : 1 }
          : module
      )
    );
  };

  // Handle close of module dialog and process enabled modules
  const handleCloseDialog = async () => {
    const enabledModules = moduleData
      .filter((module) => module.isEnabled === 1)
      .map((module) => ({
        cmpid: selectedCompany, // Directly assign selectedCompany
        moduleid: module.module_Id, // Ensure correct property
      }));

    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/module/enableModules",
        enabledModules,
        {
          headers: { Authorization: token },
        }
      );
      console.log("response", response);
    } catch (error) {
      alert("Error while enabling modules: " + error.message);
    }

    handleMdClose();
  };

  // Define rows for module data
  const moduleRows = moduleData.map((module, index) => ({
    id: module.module_Id,
    module_Name: module.module_Name, //module_Name
    is_Enabled: module.isEnabled,
    sr_no: index + 1,
  }));
  //===================== This column for module list =============================

  const moduleColumns = [
    {
      field: "Sr No",
      headerName: "Sr No",
      headerAlign: "center",
      align: "center",
      width: 80,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => params.api.getRowIndex(params.id) + 1,
    },
    {
      field: "module_Name",
      headerName: "Module List",
      headerAlign: "center",
      align: "center",
      width: 220,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "Module_Config",
      headerName: "Module Config",
      headerAlign: "center",
      align: "center",
      width: 200,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const { id, is_Enabled } = params.row;
        const isChecked = is_Enabled === 1;
        return (
          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <Switch
              checked={isChecked}
              onChange={() => handleToggleChange(id)}
              color="success"
              inputProps={{ "aria-label": `controlled-${params.value}` }}
            />
            <span>{isChecked ? "ON" : "OFF"}</span>
          </div>
        );
      },
    },
  ];

  //==========================================================

  return (
    <>
      {/* ================== Enable Module Dailog BOx ====================== */}
      
      <Dialog
        open={openModule}
        onClose={handleCloseDialog}
        PaperProps={{
          sx: {
            width: "700px",
            maxWidth: "80%",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <DialogTitle
            sx={{
              fontSize: "30px",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: "15px",
              marginLeft: "10px",
            }}
          >
            Module Configuration
          </DialogTitle>
          <CancelIcon
            sx={{
              cursor: "pointer",
              fontSize: "24px",
              marginRight: "20px",
              marginTop: "10px",
            }}
            onClick={handleCloseDialog}
          />
        </Box>
        <DialogContent>
          <Box
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              marginTop: "10px",
              height: "400px",
            }}
          >
            <Box
              height="74vh"
              sx={{
                "& .MuiDataGrid-root": { border: "none" },
                "& .MuiDataGrid-cell": { borderBottom: "none" },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                  color: colors.primary[150],
                  cursor: "default",
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: colors.blueAccent[700],
                  color: colors.primary[150],
                },
              }}
            >
              <DataGrid
                rows={moduleRows}
                columns={moduleColumns}
                disableColumnSort={true}
              />
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

      {/* =================================================================== */}
      <Box mt="80px" ml="30px" mr="20px">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header title="Tally Companies" subtitle="All Tally Companies List" />
        </Box>
        <Box
          height="74vh"
          sx={{
            overflowX: "auto",
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
              color: colors.primary[120],
              cursor: "default",
            },
            "& .MuiDataGrid-columnHeader:focus": {
              outline: "none",
              boxShadow: "none",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              outline: "none",
              boxShadow: "none",
            },
            "& .MuiDataGrid-columnHeader:focus-visible": {
              outline: "none",
              boxShadow: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[700],
              color: colors.primary[150],
            },
          }}
        >
          <DataGrid
            rows={CompanyListRow}
            columns={columns.map((col) => ({ ...col, sortable: false }))}
            pageSize={5}
            rowsPerPageOptions={[5]}
            autoHeight={false}
            disableColumnSort={true}
          />
        </Box>
      </Box>
    </>
  );
}
