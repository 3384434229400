import { Box, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../../theme";
import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "../../Dashboard/Header";
import Button from "@mui/material/Button";
import VerifiedIcon from "@mui/icons-material/Verified";

//Local Variables
const VoucherTable = () => {
  const [PriVchs, setVouchers] = useState([]);
  const token = localStorage.getItem("token");
  const Username = localStorage.getItem("newUseName");
  const cmpid = localStorage.getItem("cmpid");
  const [selectedIds, setSelectedIds] = useState([]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  //call after Component load 
  useEffect(() => {
    fetchData();
  }, [token, cmpid]);

  //fuction to fetch Primary Approval voucher list
  const fetchData = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL+"/approvalModule/vchPrimaryList", { cmpid },
        {
          headers: { Authorization: token },
        }
      );
      const arrayData = Object.values(response.data);
      setVouchers(arrayData);
    } catch (error) {
      alert("Error fetching data", error);
    }
  };

 
//approval function for single Voucher
  const handleApproval = (id) => {
    try {
      axios.post(process.env.REACT_APP_API_URL+`/approvalModule/vchPrimaryUpdate`, { id },
        {
          headers: { Authorization: token },
        }
      )
      alert("Aprroved Suucess")
      fetchData()
    }
    catch (error) {
      alert("Error updating voucher status:", error);
    }
  }; 

  //function for bulk approval
  const handleApprovalAll = () => {
    const selectedVoucherIds = rows
      .filter((row) => selectedIds.includes(row.id))
      .map((row) => row.id);
    const formattedArray = selectedVoucherIds.map(id => ({ id }));
    try {
      axios.post(
        process.env.REACT_APP_API_URL+"/approvalModule/allVchPrimaryUpdate", formattedArray,
        {
          headers: { Authorization: token },
        }
      );
      alert("Aprroved Suucess")
      fetchData()
    } catch (error) {
      alert("Error fetching data", error);
    }
  };

//rows for vouchers table 
  const rows = Array.isArray(PriVchs)
    ? PriVchs.map((PriVch) => ({
      id: PriVch.vch_id,
      vchDate: PriVch.vch_date,
      partyName: PriVch.PartyName,
      vchNumber: PriVch.vch_number,
      vchTypes: PriVch.vch_type,
      vchAmount: PriVch.vch_Amount, 
      vchRemark:PriVch.rejection_remark

    }))
    : [];
 
     
//colums for vouchers table 
  const columns = [
    { field: "Sr No", headerName: "Sr No", flex: 1, renderCell: (params) => params.api.getRowIndex(params.id) + 1 },
    { field: "vchNumber", headerName: "Voucher Number", flex: 1 },
    { field: "vchTypes", headerName: "Voucher Type", flex: 1 },
    { field: "vchDate", headerName: "Voucher Date", flex: 1 },
    { field: "partyName", headerName: "Party Name", flex: 1 },
    { field: "vchAmount", headerName: "Voucher Amount", flex: 1 },
    { field: "vchRemark", headerName: "Voucher Remark", flex: 1 },

    {
      field: "Action",
      headerName: "Action",
      flex: 1,
      renderCell: (PriVch) => (
        <Button
          variant="contained"
          color="success"
          startIcon={<VerifiedIcon />}
          type="button"
          onClick={() => handleApproval(PriVch.id)}
        > Approve </Button>
      ),
    },
  ];

  
  return ( 
    //button for selected Approval
    <Box mt="80px" ml="30px" mr="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Voucher Approval" subtitle="Primary Voucher Approval" />
        <Button variant="contained" color="success" size="small" onClick={handleApprovalAll}>
          Approve All
        </Button>
      </Box>

    {/* vouchers table  */}
      <Box height="74vh" sx={{
        "& .MuiDataGrid-root": { border: "none" },
       "& .MuiDataGrid-cell": { border: "none"},
        "& .MuiDataGrid-columnHeaders": { backgroundColor: colors.blueAccent[700], borderBottom: "none" },
        "& .MuiDataGrid-virtualScroller": { backgroundColor: colors.primary[400] },
        "& .MuiDataGrid-footerContainer": { borderTop: "none", backgroundColor: colors.blueAccent[700] },
        "& .MuiDataGrid-cell:focus": { outline: "none" },
      }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]} 
          checkboxSelection
          onSelectionModelChange={(newSelection) => setSelectedIds(newSelection)}
        />
      </Box>
    </Box>
  );
};
export default VoucherTable;


