import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  useTheme,
  Typography,
  TextField,
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Grid,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../../theme";
import CancelIcon from "@mui/icons-material/Cancel";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import Header from "../../Dashboard/Header";
import axios from "axios";


export default function CustomerDetails() {
    const theme = useTheme();
    const token = localStorage.getItem("token");
    const cmpid = localStorage.getItem("cmpid");
    const colors = tokens(theme.palette.mode);
  
    const [ledger, setLedger] = useState([]);
    const [open, setOpen] = useState(false);
    const [groupData, setGroupData] = useState([]); // State for group data
  
    const fetchGroupData = async () => {
      const cmpId = cmpid;
      try {
        const response = await axios.post(
          process.env.REACT_APP_API_URL + "/display/group",
          { cmpId },
          {
            headers: { Authorization: token },
          }
        );
        // Extract group data from the response and set it in state
        const groups = Object.values(response.data);
        setGroupData(groups); // Save groups in state
      } catch (error) {
        alert("Error fetching group data", error);
      }
    };
  
    const fetchData = async () => {
      const cmp_id = cmpid;
      try {
        const response = await axios.post(
          process.env.REACT_APP_API_URL + "/display/ledger",
          { cmp_id },
          {
            headers: { Authorization: token },
          }
        );
        const ledgerListData = Object.values(response.data.vchType_Master);
        setLedger(ledgerListData);
        // console.log('ledger data',ledgerListData)
      } catch (error) {
        alert("Error fetching data", error);
      }
    };
  
    useEffect(() => {
      fetchData();
      fetchGroupData();
    }, [token]);
  
    const userListRow = ledger.map((Led) => ({
      id: Led.led_Id,
      led_Name: Led.led_Name,
      led_Parent: Led.led_Parent,
      led_Address: Led.led_Address,
      led_State: Led.led_State,
      led_Country: Led.led_Country,
      led_GSTIN: Led.led_GSTIN,
      led_Pincode: Led.led_Pincode,
      led_Pan: Led.led_Pan,
    }));
  
    const columns = [
      {
        field: "Sr No",
        headerName: "Sr No",
        headerAlign: "center",
        align: "center",
        width: 80, // Specify width
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params) => params.api.getRowIndex(params.id) + 1,
      },
      {
        field: "led_Name",
        headerName: "Ledger Name",
        headerAlign: "center",
        align: "center",
        width: 150, // Specify width
        sortable: false,
        disableColumnMenu: true,
      },
      {
        field: "led_Parent",
        headerName: "Parent",
        headerAlign: "center",
        align: "center",
        width: 150, // Specify width
        sortable: false,
        disableColumnMenu: true,
      },
      {
        field: "led_Address",
        headerName: "Address",
        headerAlign: "center",
        align: "center",
        width: 200, // Specify width
        sortable: false,
        disableColumnMenu: true,
      },
      {
        field: "led_State",
        headerName: "State",
        headerAlign: "center",
        align: "center",
        width: 150, // Specify width
        sortable: false,
        disableColumnMenu: true,
      },
      {
        field: "led_Country",
        headerName: "Country",
        headerAlign: "center",
        align: "center",
        width: 150, // Specify width
        sortable: false,
        disableColumnMenu: true,
      },
      {
        field: "led_Pincode",
        headerName: "Pincode",
        headerAlign: "center",
        align: "center",
        width: 150, // Specify width
        sortable: false,
        disableColumnMenu: true,
      },
      {
        field: "led_GSTIN",
        headerName: "GSTIN",
        headerAlign: "center",
        align: "center",
        width: 150, // Specify width
        sortable: false,
        disableColumnMenu: true,
      },
      {
        field: "led_Pan",
        headerName: "Pan/IT No",
        headerAlign: "center",
        align: "center",
        width: 150, // Specify width
        sortable: false,
        disableColumnMenu: true,
      },
      {
        field: "action",
        headerName: "Action",
        headerAlign: "center",
        align: "center",
        width: 150, // Specify width
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
          return (
            <div>
              <Button
                variant="contained"
                style={{
                  backgroundColor: colors.blueAccent[700],
                  color: colors.primary[150], // Custom text color
                  "&:hover": {
                    backgroundColor: colors.blueAccent[700], // Maintain the same background on hover
                    color: colors.primary[150], // Maintain the same text color on hover
                  },
                }}
                size="small"
                type="button"
                onClick={() => LedfetchData(params.row)}
              >
                View
              </Button>
            </div>
          );
        },
      },
    ];
  
    // Dialog to Add Ledger
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
  
    const [formData, setFormData] = useState({
      name: "",
      alias: "",
      under: "",
      gstType: "",
      gstin: "",
      panNo: "",
      address: "",
      state: "",
      country: "",
      pincode: "",
      mobile: "",
      email: "",
    });
  
    // Handle form input change
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    };
  
    const validateForm = () => {
      const { name, under, gstin, panNo, pincode, mobile, email } = formData;
      if (!name || !under) {
        alert("Please fill Required fields.");
        return false;
      }
  
      const gstinRegex =
        /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/;
  
      if (gstin && !gstinRegex.test(gstin)) {
        alert("Invalid GSTIN format.");
        return false;
      }
  
      const mobileRegex = /^[0-9]{10}$/;
      if (mobile && !mobileRegex.test(mobile)) {
        alert("Invalid mobile number. It should be 10 digits.");
        return false;
      }
  
      const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      if (email && !emailRegex.test(email)) {
        alert("Invalid email format.");
        return false;
      }
  
      const pincodeRegex = /^[1-9][0-9]{5}$/;
      if (pincode && !pincodeRegex.test(pincode)) {
        alert("Invalid pincode.");
        return false;
      }
  
      const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
      if (panNo && !panRegex.test(panNo)) {
        alert("Invalid Pan Number .");
        return false;
      }
  
      return true;
    };
  
    // Handle form submission
    const handleFormSubmit = async (e) => {
      e.preventDefault();
  
      if (!validateForm()) return;
  
      const payload = [
        {
          ledger_Name: formData.name,
          ledger_Alies: formData.alias,
          ledger_Parent: formData.under,
          ledger_Address: formData.address,
          ledger_State: formData.state,
          ledger_Country: formData.country,
          ledger_Pincode: formData.pincode,
          ledger_Pan: formData.panNo,
          ledger_GSTReg: formData.gstType,
          ledger_GSTIN: formData.gstin,
          ledger_Cmp: cmpid,
        },
      ];
  
      // console.log("formData", payload);
  
      try {
        const response = await axios.post(
          process.env.REACT_APP_API_URL + "/create/ledger",
          payload,
          {
            headers: { Authorization: token },
          }
        );
  
        const groups = Object.values(response.data);
        alert("Ledger created..");
        setGroupData(groups);
      } catch (error) {
        alert("Error creation ledger ", error);
      }
  
      setFormData({
        name: "",
        alias: "",
        under: "",
        gstType: "",
        gstin: "",
        panNo: "",
        address: "",
        state: "",
        country: "",
        pincode: "",
        mobile: "",
        email: "",
      });
      handleClose();
    };
  
    // ============ show popup for single ledger data =============
    const [ShowDialog, setShowDialog] = useState(false);
    const [LedData, setLedData] = useState([]);
  
    const handleshowDialogClose = () => setShowDialog(false);
  
    const LedfetchData = async (LedRow) => {
      setLedData(LedRow);
      setShowDialog(true);
      // console.log('ledger data',LedRow);
    };
  
    return (
      <>
        <Dialog
          open={open}
          onClose={handleClose}
          PaperProps={{
            sx: {
              width: "40vw",
              maxWidth: "80%",
              height: "90vh",
              position: "fixed",
              top: 0,
              zIndex: 5000,
              borderRadius: "16px",
            },
          }}
        >
          <DialogTitle
            sx={{
              fontSize: "24px",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "0 16px",
            }}
          >
            <Box style={{ display: "flex", alignItems: "center" }}>
              <PersonAddAltIcon
                sx={{ marginRight: "8px", height: "50px", width: "30px" }}
              />
              Add New Ledger
            </Box>
            <CancelIcon
              sx={{ height: "50px", width: "30px", cursor: "pointer" }}
              onClick={handleClose}
            />
          </DialogTitle>
  
          <DialogContent>
            <Box
              component="form"
              onSubmit={handleFormSubmit}
              sx={{ display: "flex", flexDirection: "column", marginTop: "10px" }}
            >
              {/* Name and Alias */}
              <Box sx={{ display: "flex", gap: 2, width: "100%" }}>
                <TextField
                  size="small"
                  margin="dense"
                  label="Name"
                  type="text"
                  fullWidth
                  variant="outlined"
                  required
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  sx={{
                    fontSize: "0.875rem",
                    marginTop: "10px",
                    borderRadius: "8px",
                  }}
                />
                <TextField
                  size="small"
                  margin="dense"
                  label="Alias"
                  type="text"
                  fullWidth
                  variant="outlined"
                  name="alias"
                  value={formData.alias}
                  onChange={handleInputChange}
                  sx={{
                    fontSize: "0.875rem",
                    marginTop: "10px",
                    borderRadius: "8px",
                  }}
                />
              </Box>
  
              {/* Select Parent Group */}
              <Autocomplete
                size="small"
                fullWidth
                options={groupData.map((group) => group.group_Name)} // Array of options
                getOptionLabel={(option) => option || ""}
                value={formData.under || null}
                onChange={(event, newValue) => {
                  handleInputChange({
                    target: { name: "under", value: newValue },
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Under"
                    name="under"
                    required
                    sx={{
                      fontSize: "0.875rem",
                      marginTop: "10px",
                      borderRadius: "8px",
                      width: "235px",
                    }}
                  />
                )}
              />
  
              {/* Mailing Details Section */}
              <Box sx={{ marginTop: "20px" }}>
                <Typography variant="h6">Mailing Details</Typography>
                <Divider sx={{ marginY: 2 }} />
                <TextField
                  size="small"
                  margin="dense"
                  label="Address"
                  fullWidth
                  variant="outlined"
                  name="address"
                  value={formData.address}
                  onChange={handleInputChange}
                  sx={{
                    fontSize: "0.875rem",
                    marginBottom: "10px",
                    borderRadius: "8px",
                  }}
                />
                <Box sx={{ display: "flex", gap: 2, width: "100%" }}>
                  {/* Country Autocomplete */}
                  <Autocomplete
                    size="small"
                    fullWidth
                    options={Countries} // Array of country names
                    getOptionLabel={(option) => option || ""}
                    value={formData.country || null}
                    onChange={(event, newValue) => {
                      handleInputChange({
                        target: { name: "country", value: newValue },
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Country"
                        margin="dense"
                        variant="outlined"
                        required
                        name="country"
                        sx={{
                          flex: 1,
                          fontSize: "0.875rem",
                          borderRadius: "8px",
                        }}
                      />
                    )}
                  />
  
                  {/* State Autocomplete */}
                  <Autocomplete
                    size="small"
                    fullWidth
                    options={indianStates} // Array of state names
                    getOptionLabel={(option) => option || ""}
                    value={formData.state || null}
                    onChange={(event, newValue) => {
                      handleInputChange({
                        target: { name: "state", value: newValue },
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="State"
                        margin="dense"
                        variant="outlined"
                        name="state"
                        sx={{
                          flex: 1,
                          fontSize: "0.875rem",
                          borderRadius: "8px",
                        }}
                      />
                    )}
                  />
                </Box>
                <Box sx={{ display: "flex", gap: 2, width: "100%" }}>
                  <TextField
                    size="small"
                    margin="dense"
                    label="Pincode"
                    fullWidth
                    variant="outlined"
                    required
                    name="pincode"
                    value={formData.pincode}
                    onChange={handleInputChange}
                    sx={{ flex: 1, fontSize: "0.875rem", borderRadius: "8px" }}
                  />
                  <TextField
                    size="small"
                    margin="dense"
                    label="Mobile Number"
                    fullWidth
                    variant="outlined"
                    required
                    name="mobile"
                    value={formData.mobile}
                    onChange={handleInputChange}
                    sx={{ flex: 1, fontSize: "0.875rem", borderRadius: "8px" }}
                  />
                </Box>
                <TextField
                  size="small"
                  margin="dense"
                  label="Email"
                  fullWidth
                  variant="outlined"
                  required
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  sx={{
                    fontSize: "0.875rem",
                    marginTop: "10px",
                    borderRadius: "8px",
                    width: "235px",
                  }}
                />
              </Box>
            </Box>
  
            {/* Tax Details Section */}
            <Box sx={{ marginTop: "20px" }}>
              <Typography variant="h6">Tax Details</Typography>
              <Divider sx={{ marginY: 2 }} />
              <Autocomplete
                size="small"
                options={["Regular", "Composition", "Unregistered"]} // GST Registration Type options
                getOptionLabel={(option) => option || ""}
                value={formData.gstType || null}
                onChange={(event, newValue) => {
                  handleInputChange({
                    target: { name: "gstType", value: newValue },
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="GST Registration Type"
                    name="gstType"
                    fullWidth
                    required
                    sx={{
                      fontSize: "0.875rem",
                      marginTop: "10px",
                      borderRadius: "8px",
                      width: "235px",
                    }}
                  />
                )}
              />
  
              {/* GSTIN and PAN */}
              <Box sx={{ display: "flex", gap: 2, width: "100%" }}>
                <TextField
                  size="small"
                  margin="dense"
                  label="GSTIN/UIN"
                  fullWidth
                  variant="outlined"
                  name="gstin"
                  value={formData.gstin}
                  onChange={handleInputChange}
                  sx={{
                    fontSize: "0.875rem",
                    borderRadius: "8px",
                    marginTop: "10px",
                  }}
                />
                <TextField
                  size="small"
                  margin="dense"
                  label="PAN No"
                  fullWidth
                  variant="outlined"
                  name="panNo"
                  value={formData.panNo}
                  onChange={handleInputChange}
                  sx={{
                    fontSize: "0.875rem",
                    borderRadius: "8px",
                    marginTop: "10px",
                  }}
                />
              </Box>
            </Box>
          </DialogContent>
  
          <DialogActions>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center", // Center the content horizontally
                width: "80%", // 80% of the parent width
                mr: 3.5,
                mb: 2,
              }}
            >
              <Button
                type="submit"
                variant="contained"
                fullWidth
                onClick={handleFormSubmit}
                sx={{
                  backgroundColor: colors.blueAccent[700],
                  borderRadius: "10px",
                  fontSize: "0.875rem",
                  padding: "8px 16px",
                  width: "15vw",
  
                  color: colors.primary[150], // Custom text color
                  "&:hover": {
                    backgroundColor: colors.blueAccent[700], // Maintain the same background on hover
                    color: colors.primary[150], // Maintain the same text color on hover
                  },
                }}
              >
                Create
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
  
        {/* ================== show single ledger data =====================*/}
        <Box>
          <Dialog
            open={ShowDialog}
            sx={{
              "& .MuiDialog-paper": {
                backgroundColor: colors.primary[400], // Use your theme color
                padding: 2,
              },
            }}
          >
            <DialogTitle variant="h3" gutterBottom p={0} m={0}>
              Ledger Details
            </DialogTitle>
            <DialogContent>
              {/* Ledger Information Section */}
              <Grid container spacing={2} mt={0}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Name"
                    name="Name"
                    size="small"
                    fullWidth
                    value={LedData.led_Name}
                    InputProps={{
                      readOnly: true,
                      style: {
                        cursor: "default",
                        pointerEvents: "none",
                      },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Under"
                    name="Under"
                    size="small"
                    fullWidth
                    value={LedData.led_Parent}
                    InputProps={{
                      readOnly: true,
                      style: {
                        cursor: "default",
                        pointerEvents: "none",
                      },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>
  
              {/* Mailing Details Section */}
              <Typography variant="h4" p={1}>
                Mailing Details
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    label="Address"
                    name="Address"
                    size="small"
                    multiline
                    fullWidth
                    value={LedData.led_Address}
                    InputProps={{
                      readOnly: true,
                      style: {
                        cursor: "default",
                        pointerEvents: "none",
                      },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="State"
                    name="State"
                    size="small"
                    fullWidth
                    value={LedData.led_State}
                    InputProps={{
                      readOnly: true,
                      style: {
                        cursor: "default",
                        pointerEvents: "none",
                      },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Country"
                    name="Country"
                    size="small"
                    fullWidth
                    value={LedData.led_Country}
                    InputProps={{
                      readOnly: true,
                      style: {
                        cursor: "default",
                        pointerEvents: "none",
                      },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <TextField
                    label="Pincode"
                    name="Pincode"
                    size="small"
                    fullWidth
                    value={LedData.led_Pincode}
                    InputProps={{
                      readOnly: true,
                      style: {
                        cursor: "default",
                        pointerEvents: "none",
                      },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>
  
              {/* Tax Registration Details Section */}
              <Typography variant="h4" p={1}>
                Tax Registration Details
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="GSTIN/UIN"
                    name="GSTIN/UIN"
                    size="small"
                    fullWidth
                    value={LedData.led_GSTIN}
                    InputProps={{
                      readOnly: true,
                      style: {
                        cursor: "default",
                        pointerEvents: "none",
                      },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="PAN/IT No"
                    name="PAN/IT No"
                    size="small"
                    fullWidth
                    value={LedData.led_Pan}
                    InputProps={{
                      readOnly: true,
                      style: {
                        cursor: "default",
                        pointerEvents: "none",
                      },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>
            </DialogContent>
  
            {/* Centered Action Button */}
            <DialogActions
              sx={{
                display: "flex",
                justifyContent: "right",
                padding: 1,
              }}
            >
              <Button
                color="success"
                size="small"
                variant="contained"
                onClick={handleshowDialogClose}
                sx={{
                  borderRadius: "10px",
                }}
              >
                OK
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
  
        {/* ===================================================== */}
  
        {/* Table Display */}
        <Box mt="80px" ml="30px" mr="20px">
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Header title="Customer Details" subtitle="Tally Customer Details" />
            <Button
              variant="contained"
              size="small"
              sx={{
                backgroundColor: colors.blueAccent[700], // Custom background color
                color: colors.primary[150], // Custom text color
                "&:hover": {
                  backgroundColor: colors.blueAccent[700], // Maintain the same background on hover
                  color: colors.primary[150], // Maintain the same text color on hover
                },
              }}
              type="button"
              onClick={handleOpen}
            >
              Create customer
            </Button>
          </Box>
  
          <Box
            height="74vh"
            sx={{
              overflowX: "auto",
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .name-column--cell": {
                color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none",
                color: colors.primary[120],
                cursor: "default", // Change cursor to default to remove pointer effect
              },
              "& .MuiDataGrid-columnHeader:focus": {
                outline: "none", // Remove the outline when the header is focused
                boxShadow: "none", // Remove the box shadow when the header is focused
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                // This targets the header title specifically
                outline: "none", // Remove outline on click
                boxShadow: "none", // Remove box shadow on click
              },
              "& .MuiDataGrid-columnHeader:focus-visible": {
                // Remove the focus-visible effect when clicking
                outline: "none", // Remove the outline when focused
                boxShadow: "none", // Remove any shadow or border
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
                color: colors.primary[150],
              },
            }}
          >
            <DataGrid
              rows={userListRow}
              columns={columns}
              disableColumnSort={true}
            />
          </Box>
        </Box>
      </>
    );
  }
  
  const indianStates = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra and Nagar Haveli and Daman and Diu",
    "Delhi",
    "Jammu and Kashmir",
    "Ladakh",
    "Lakshadweep",
    "Puducherry",
  ];
  
  const Countries = [
    "Afghanistan",
    "Armenia",
    "Azerbaijan",
    "Bahrain",
    "Bangladesh",
    "Bhutan",
    "Brunei",
    "Cambodia",
    "China",
    "Cyprus",
    "Georgia",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Israel",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Lebanon",
    "Malaysia",
    "Maldives",
    "Mongolia",
    "Myanmar",
    "Nepal",
    "North Korea",
    "Oman",
    "Pakistan",
    "Palestine",
    "Philippines",
    "Qatar",
    "Russia",
    "Saudi Arabia",
    "Singapore",
    "South Korea",
    "Sri Lanka",
    "Syria",
    "Taiwan",
    "Tajikistan",
    "Thailand",
    "Timor-Leste",
    "Turkey",
    "Turkmenistan",
    "United Arab Emirates",
    "Uzbekistan",
    "Vietnam",
    "Yemen",
  ];
  