import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import CancelIcon from "@mui/icons-material/Cancel";
import axios from "axios";

export default function VideoResourses() {
  const [ResList, setResList] = useState([]);
  const [videoId, setVideoId] = useState("");
  const [videolist, setvideolist] = useState([]);

  const token = localStorage.getItem("shraddhatoken");
  const [lastValues, setlastValues] = useState({
    lastsourceURL: "",
    lastsourceName: "",
  });
  const [formValues, setFormValues] = useState({
    videoId: "",
    sourceURL: "",
    sourceName: "",
  });

  const [ShowDialog, setShowDialog] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);

  const handleOpen = () => {
    setFormValues({
      videoId: "",
      sourceURL: "",
      sourceName: "",
    });
    setIsUpdate(false);
    setShowDialog(true);
  };

  const Closedialog = () => setShowDialog(false);

  const handleDateChange = (field, value) => {
    setFormValues((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleUpdate = async () => {
    const updateVideoRes = {
      videoId: videoId,
      sourceURL: lastValues.lastsourceURL,
      sourceName: lastValues.lastsourceName,
      updatedUrl:formValues.sourceURL,
      updatedName:formValues.sourceName,
    };
// console.log('update',updateVideoRes)
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/shraddha/operation/videoResUpdate",
        updateVideoRes,
        {
          headers: { Authorization: token },
        }
      );

      if (response) {
        alert(response.data.message);
        setShowDialog(false);
      }
    } catch (error) {
      alert("Error Update Account", error);
    }
  };

  const handleCreate = async () => {
    const newVideoRes = {
      videoId: videoId,
      sourceURL: formValues.sourceURL,
      sourceName: formValues.sourceName,
    };

    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/shraddha/operation/videoResCreate",
        newVideoRes,
        {
          headers: { Authorization: token },
        }
      );

      if (response) {
        alert(response.data.message);
        setShowDialog(false);
      }
    } catch (error) {
      alert("Error creating video", error);
    }
  };

  const LedfetchData = (Row) => {
    setlastValues({
      lastsourceURL: Row.resURL,
      lastsourceName: Row.name,
    });
    setFormValues({
      videoId: Row.id,
      sourceURL: Row.resURL,
      sourceName: Row.name,
    });
    setIsUpdate(true);
    setShowDialog(true);
  };

  //function to delete select row
  const handleDelete = async (userRow) => {
    const DeleteVideoRes = {
      videoId: videoId,
      sourceURL: userRow.resURL,
    };
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/shraddha/operation/videoResDelete",
        DeleteVideoRes,
        {
          headers: { Authorization: token },
        }
      );

      if (response) {
        alert(response.data.message);
      }
    } catch (error) {
      alert("Error creating video", error);
    }
    alert("Deleted successfull");
    fetchvideoResList();
  };

  const fetchvideoResList = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/shraddha/operation/videoResList",
        { videoId:videoId },
        {
          headers: { Authorization: token },
        }
      );
      const users = Object.values(response.data.rows);
      setResList(users);
    } catch (error) {
      console.log("Error fetching group data", error);
    }
  };

  const fetchvideoData = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/shraddha/operation/svideoList",
        {},
        {
          headers: { Authorization: token },
        }
      );
      const users = Object.values(response.data.rows);
      setvideolist(users);
      console.log(users)
    } catch (error) {
      console.log("Error fetching group data", error);
    }
  };

  const handleVideoChange = (event) => {
    // const VideoId = event.target.value;
   
    setVideoId(event.target.value);
    fetchvideoResList();
  };

  useEffect(() => {
    fetchvideoResList();
    fetchvideoData();
  }, []);

  const ResListRow = ResList.map((ResLists, index) => ({
    id: ResLists.source_URL,
    name: ResLists.source_Name,
    resURL: ResLists.source_URL,
  }));

  const columns = [
    {
      field: "",
      headerName: "SR No",
      headerAlign: "center",
      align: "center",
      width: 80,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => params.api.getRowIndex(params.id) + 1,
    },
    {
      field: "name",
      headerName: "Name",
      headerAlign: "center",
      align: "center",
      width: 300,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "resURL",
      headerName: "Resource URL",
      headerAlign: "center",
      align: "center",
      width: 400,
      sortable: false,
      disableColumnMenu: true,
    },

    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      width: 200,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <div>
            <Button
              variant="contained"
              style={{
                backgroundColor: "gray",
                color: "ffffff",
                marginRight: "10px",
                "&:hover": {
                  backgroundColor: "gray",
                  color: "ffffff",
                },
              }}
              size="small"
              type="button"
              onClick={() => LedfetchData(params.row)}
            >
              Update
            </Button>

            <Button
              variant="contained"
              style={{
                backgroundColor: "red",
                color: "ffffff",
                "&:hover": {
                  backgroundColor: "red",
                  color: "ffffff",
                },
              }}
              size="small"
              type="button"
              onClick={() => handleDelete(params.row)}
            >
              Delete
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        paddingTop: 8,
        paddingLeft: 3,
        paddingRight: 3,
        marginLeft: "240px",
        marginTop: 4,
        backgroundColor: "#f4f6f7",
        borderRadius: "10px",
      }}
    >
      {/* ================== pop up form for create and update vidoe resources ===================== */}
      <Dialog
        open={ShowDialog}
        sx={{
          "& .MuiDialog-paper": {
            backgroundColor: "#f0f0f0",
            padding: 2,
          },
        }}
      >
        <DialogTitle
          variant="h3"
          gutterBottom
          p={0}
          m={0}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            color: "#000",
          }}
        >
          {isUpdate ? "Update Video Resources" : "Add New Video Resources"}
          <CancelIcon
            sx={{
              cursor: "pointer",
              fontSize: "24px", // Adjust size if necessary
            }}
            onClick={Closedialog}
          />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} mt={0}>
            <Grid item xs={12} sm={12}>
              <TextField
                label="Name"
                name="Name"
                size="small"
                fullWidth
                value={formValues.sourceName}
                onChange={(e) => handleDateChange("sourceName", e.target.value)}
                sx={{
                  "& .MuiInputBase-input": {
                    color: "black", // Input text color
                  },
                  "& .MuiInputLabel-root": {
                    color: "black", // Label text color
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "black", // Default border color
                    },
                    "&:hover fieldset": {
                      borderColor: "black", // Hover border color
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "black", // Focused border color
                    },
                  },
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} mt={0}>
            <Grid item xs={12} sm={12}>
              <TextField
                label="Video resource link"
                name="Video resource link"
                size="small"
                fullWidth
                value={formValues.sourceURL}
                onChange={(e) => handleDateChange("sourceURL", e.target.value)}
                sx={{
                  "& .MuiInputBase-input": {
                    color: "black", // Input text color
                  },
                  "& .MuiInputLabel-root": {
                    color: "black", // Label text color
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "black", // Default border color
                    },
                    "&:hover fieldset": {
                      borderColor: "black", // Hover border color
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "black", // Focused border color
                    },
                  },
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions
          sx={{ display: "flex", justifyContent: "right", padding: 1 }}
        >
          <Button
            size="small"
            variant="contained"
            onClick={Closedialog}
            sx={{ borderRadius: "10px", background: "red" }}
          >
            Cancel
          </Button>

          <Button
            color="success"
            size="small"
            variant="contained"
            onClick={isUpdate ? handleUpdate : handleCreate}
            sx={{
              borderRadius: "10px",
              backgroundColor: "green", // Set background color to green
              color: "white", // Set text color to white
              "&:hover": {
                backgroundColor: "darkgreen", // Optional: Darker green on hover
              },
            }}
          >
            {isUpdate ? "Update" : "Add"}
          </Button>
        </DialogActions>
      </Dialog>

      <Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h2" color="#000">
            All Videos Resources
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              width: "70%",
            }}
          >
            <FormControl
              fullWidth
              size="small"
              sx={{
                width: "40%", // Apply 40% width
                mr: 2,
              }}
            >
              <InputLabel id="course-label" sx={{ color: "black" }}>
                Select Video
              </InputLabel>
              <Select
                name="courseId" // State key for course ID
                labelId="course-label"
                value={videoId}
                onChange={handleVideoChange} // Update state on change
                label="Select Course"
                fullWidth
                sx={{
                  "& .MuiInputBase-input": {
                    color: "black", // Input text color
                  },
                  "& .MuiInputLabel-root": {
                    color: "black", // Label text color
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "black", // Default border color
                    },
                    "&:hover fieldset": {
                      borderColor: "black", // Hover border color
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "black", // Focused border color
                    },
                  },
                }}
              >
                {videolist.map((video) => (
                  <MenuItem key={video.video_Id} value={video.video_Id}>
                    {video.video_Name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Button
              variant="contained"
              size="small"
              sx={{
                borderRadius: "10px",
                backgroundColor: "green", // Set background color to green
                color: "white", // Set text color to white
                "&:hover": {
                  backgroundColor: "darkgreen", // Optional: Darker green on hover
                },
              }}
              type="button"
              onClick={handleOpen}
            >
              + Add Resource
            </Button>
          </Box>
        </Box>
        <Box
          width="100%"
          height="74vh"
          sx={{
            overflowX: "auto",
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
              color: "black", // Set cell text color to black
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#E9F3F5",
              borderBottom: "none",
              cursor: "default",
              color: "black", // Set header text color to black
            },
            "& .MuiDataGrid-columnHeader:focus": {
              outline: "none",
              boxShadow: "none",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              outline: "none",
              boxShadow: "none",
            },
            "& .MuiDataGrid-columnHeader:focus-visible": {
              outline: "none",
              boxShadow: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: "#fff", // Background color for the scroller
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: "#E9F3F5",
            },
            "& .MuiDataGrid-overlay": {
              backgroundColor: "#ffffff", // Set overlay background color when table is empty
              color: "gray", // Optional: set text color for empty state message
            },
          }}
        >
          <DataGrid
            rows={ResListRow}
            columns={columns}
            // disableColumnSort={true}
          />
        </Box>
      </Box>
    </Box>
  );
}
