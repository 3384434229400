import React, { useState, useEffect } from "react";
import {
  TextField,
  Box,
  Button,
  Typography,
  Grid,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

export default function LoginWithOTP() {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({ userEmail: "" });
  const [errors, setErrors] = useState({ userEmail: "" });
  const [openOtpModal, setOpenOtpModal] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [otpMessage, setOtpMessage] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [activeSessions, setActiveSessions] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
    setOtpError("");
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.userEmail) {
      newErrors.userEmail = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.userEmail)) {
      newErrors.userEmail = "Email is invalid";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsSubmitting(true);

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/auth/otpLogin`,
          { userEmail: formData.userEmail }
        );

        if (response.status === 201) {
          setOpenOtpModal(true);
          setOtpMessage(
            `An OTP has been sent to ${formData.userEmail}. Please check your email.`
          );
        } else {
          alert("Login failed");
        }
      } catch (error) {
        alert("error");
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const handleVerifyOtp = async () => {
    if (!otp) {
      setOtpError("OTP is required");
      return;
    }
    const device = getDeviceInfo();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/loginOTPVerify`,
        { otp, device }
      );

      // console.log(response, "data");

      if (response.data.Sub === "m") {
        localStorage.setItem("token", response.data.token);
        navigate("/Member/ShraddhaEvents");
      } else if (response.data.Sub === "n") {
        localStorage.setItem("guestToken", response.data.guestToken);
        navigate("/Gauest/GauestEvents");
      } else {
        alert("Access Denied ! Please Contact +91 020 41488999");
      }
    } catch (error) {
      if (error.response.status == 409) {
        setOpenDialog(true);
        console.log("error.response.data", error.response.data);
        setActiveSessions(error.response.data.activeSessions);
        // console.log("Session data", error.response.data.activeSessions);
      }
      if (error.response.status !== 409) {
        alert("Regenerate OTP");
      }
    }
  };

  // ===================================

  // const NavigatePage = () => {
  //   const token = localStorage.getItem("token");
  //   const guestToken = localStorage.getItem("guestToken");

  //   if (guestToken) {
  //     navigate("/Gauest/GuestEvents");
  //   } else if (token) {
  //     navigate("/Member/ShraddhaEvents");
  //   }
  // };

  // useEffect(() => {
  //   NavigatePage();
  // }, []);

  //=====================================================

  const handleLogoutSession = async (token) => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/auth/logout`, {
        token,
      });
    } catch (error) {
      console.log("your session are expired");
    }

    setOpenDialog(false);
  };

  //=============================
  function getDeviceInfo() {
    const userAgent = navigator.userAgent;
    let deviceName = "Unknown Device";

    // Check for various devices based on user agent string
    if (/iPhone|iPad|iPod/i.test(userAgent)) {
      deviceName = "Apple iOS Device";
    } else if (/Android/i.test(userAgent)) {
      deviceName = "Android Device";
    } else if (/Windows NT/i.test(userAgent)) {
      deviceName = "Windows Device";
    } else if (/Macintosh/i.test(userAgent)) {
      deviceName = "MacOS Device";
    } else if (/Linux/i.test(userAgent)) {
      deviceName = "Linux Device";
    }

    return deviceName;
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100vh",
          backgroundImage: "url('../../assets/sign up BG_equal.png')",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundAttachment: "fixed",
        }}
      >
        {/* ==================== Add marquee text====================================== */}
        <Box
          sx={{
            backgroundColor: "black", //"#FFFFC5",
            color: "white",
            width: "100%",
            display: "flex",
            alignItems: "center",
            paddingBottom: "5px",
            position: "fixed",
            top: 0,
            marginTop: "10px",
          }}
        >
          <marquee>
            <Button
              variant="contained"
              size="small"
              sx={{
                mt: "2px",
                mr: 2,
                backgroundColor: "#B09246",
                ":hover": { backgroundColor: "#B09246" },
                borderRadius: "10px",
              }}
              onClick={() =>
                window.open(
                  "https://shraddhainfosystems.od2.vtiger.com/appointments/bookfreeconsultation"
                )
              }
            >
              Book Demo
            </Button>
            <Typography
              sx={{
                display: "inline-block",
                animation: " 5s linear infinite", // Adjust timing for slower speed
                whiteSpace: "nowrap",
              }}
            >
              FREE Shraddha Infinite Tally BackUp on Google Drive for 6 Months
            </Typography>
            <Button
              variant="contained"
              size="small"
              sx={{
                mt: "2px",
                ml: 2,
                backgroundColor: "#B09246",
                ":hover": { backgroundColor: "#B09246" },
                borderRadius: "10px",
              }}
              onClick={() =>
                window.open(
                  "https://shraddhainfosystems.od2.vtiger.com/appointments/bookfreeconsultation"
                )
              }
            >
              Book Demo
            </Button>
          </marquee>
        </Box>

        <Paper
          elevation={1}
          sx={{
            display: "flex",
            flexDirection: "column",
            // height:{ md: "400px"},
            width: {
              xs: "300px",
              sm: "300px",
              md: "400px",
              lg: "400px", // Increased height for larger screens
              xl: "400px", // Extra large screens
            }, //"",
            padding: {
              xs: "20px",
              sm: "20px",
              md: "20px",
              lg: "20px", // Increased height for larger screens
              xl: "20px", // Extra large screens
            },
            borderRadius: "10px",
            backgroundColor: "#ebfafc",
           
          }}
        >
          <Typography
            variant="h4"
            align="center"
            sx={{ color: "#000", marginBottom: "30px", m: "0", p: "0" }}
          >
            <img
              alt="logo"
              width="250px"
              height="120px"
              style={{ borderRadius: "15px" }}
              src="../../assets/Infinitelogo.png"
            />
          </Typography>

          <Divider sx={{ margin: "10px" }} />

          <Box component="form" onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Email"
                  variant="outlined"
                  name="userEmail"
                  value={formData.userEmail}
                  onChange={handleChange}
                  error={Boolean(errors.userEmail)}
                  helperText={errors.userEmail}
                  sx={{
                    "& .MuiInputLabel-root": { color: "#000" }, // Label text color black
                    "& .MuiInputLabel-root.Mui-focused": { color: "#000" }, // Label text color black on focus
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: "white", // Background color white
                      "& fieldset": {
                        borderColor: "#000", // Border color black
                      },
                      "&:hover fieldset": {
                        borderColor: "#000", // Border color black on hover
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#000", // Border color black on focus
                      },
                    },
                    "& .MuiOutlinedInput-input": { color: "#000" }, // Input text color black
                    "& .MuiFormControlLabel-label": {
                      color: "#000", // Label text color black
                    },
                    "& .MuiInputBase-root": {
                      backgroundColor: "white",
                    },
                    "& .MuiInputBase-input": {
                      color: "black",
                    },
                    "& .MuiInputBase-input:-webkit-autofill": {
                      WebkitBoxShadow: "0 0 0px 1000px white inset",
                      WebkitTextFillColor: "black",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  sx={{ backgroundColor: "#B09246" }}
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Logging in..." : "Login"}
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              mt: 2,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography>Don't have an account yet ? </Typography>

            <Link to="/Auth/signup" style={{ textDecoration: "none" }}>
              <Typography
                variant="body2"
                sx={{ color: "blue", fontSize: "16px" }}
              >
                Register
              </Typography>
            </Link>
          </Box>
        </Paper>
        <Dialog
          open={openOtpModal}
          onClose={() => setOpenOtpModal(false)}
          maxWidth="sm"
          fullWidth
          PaperProps={{
            style: {
              borderRadius: "8px",
              padding: "20px",
              height: "50vh",
              width: "50vh",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            },
          }}
        >
          <Typography
            variant="body2"
            color="green"
            sx={{ marginTop: 2, textAlign: "center" }}
          >
            {otpMessage}
          </Typography>

          <DialogContent>
            <TextField
              fullWidth
              label="Enter OTP"
              variant="outlined"
              type="text"
              value={otp}
              onChange={handleOtpChange}
              error={Boolean(otpError)}
              helperText={otpError}
              inputProps={{ maxLength: 7 }}
            />
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center" }}>
            <Button
              onClick={handleVerifyOtp}
              fullWidth
              variant="contained"
              sx={{ backgroundColor: "#B09246", borderRadius: "8px" }}
            >
              Verify OTP
            </Button>
          </DialogActions>
        </Dialog>
      </Box>

      {/* ================ Active Sessions =============== */}

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Active Sessions</DialogTitle>
        <DialogContent>
          <Typography variant="body1" sx={{ marginBottom: "20px" }}>
            Session limit exceeded. Please log out from another device.
          </Typography>
          <List>
            {activeSessions.map((session) => (
              <ListItem key={session.session_Id}>
                <ListItemText
                  primary={`Device : ${session.session_device}`}
                  secondary={`Login at: ${session.created_at}`}
                />
                <ListItemSecondaryAction>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => handleLogoutSession(session.token)}
                  >
                    Logout
                  </Button>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </DialogContent>
      </Dialog>
    </>
  );
}
