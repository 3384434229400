import React, { useState, useEffect } from "react";
import Header from "../../InfinitePages/Dashboard/Header";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  useTheme,
} from "@mui/material";
import { tokens } from "../../theme";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import StylishLoadingDialog from "../../InfinitePages/Dashboard/StatBox";

export default function ShraddhaInfiniteCourses() {
  const theme = useTheme();
  const navigate = useNavigate();
  const colors = tokens(theme.palette.mode);
  const token = localStorage.getItem("token");

  const [courseList, setCourseList] = useState([]);
  const [loading, setLoading] = useState(false);

  // Fetch all courses data from API
  const fetchAllCourses = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/display/courseslist`,
        {},
        {
          headers: { Authorization: token },
        }
      );
      setCourseList(response.data.courseMap[0].Course || []);
    } catch (error) {
      console.error("Error fetching all courses:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllCourses();
  }, []);

  const handleLearnMoreClick = (course) => {
    navigate(`/Member/course-detail`, { state: { course } });
  };

  return (
    <Box
      sx={{
        padding: 4,
        mt: "50px",
        minHeight: "100vh",
      }}
      onContextMenu={(e) => e.preventDefault()}
    >
      <Header title="All Courses" subtitle="Explore all available courses" />

      <Grid container spacing={4}>
        {courseList.map((course) => (
          <Grid item xs={12} sm={6} md={4} key={course.course_Id}>
            <Card
              sx={{
                borderRadius: "15px",
                backgroundColor: colors.primary[400],
                boxShadow: 3,
                height: {
                  xs: "100vw", // Adjust height for extra-small screens
                  sm: "50vw", // Small screens
                  md: "35vw", // Medium screens
                  xl: "30vw", // Extra-large screens
                  lg:"30vw",
                },
                display: "flex",
                justifyContent: "space-between",
                // alignItems:"center",
                flexDirection: "column",
                transition: "transform 0.3s ease-in-out",
                "&:hover": {
                  transform: "scale(1.05)",
                  boxShadow: 6,
                },
              }}
            >
              <img
                src={course.courseImage}
                alt={course.courseName}
                style={{
                  height: "200px",
                  width: "100%",
                  objectFit: "cover",
                  borderTopLeftRadius: "15px",
                  borderTopRightRadius: "15px",
                }}
              />
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  {course.courseName}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  {course.courseDesc}
                </Typography>
              </CardContent>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <Button
                  size="small"
                  variant="contained"
                  sx={{
                    borderRadius: "10px",
                    backgroundColor: "#B09246",
                    ":hover": { backgroundColor: "#a0813f" },
                    width: "80%",
                    mb: 2,
                  }}
                  onClick={() => handleLearnMoreClick(course)}
                >
                  Learn More
                </Button>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>

      <StylishLoadingDialog loading={loading} />
    </Box>
  );
}
