import React, { useState } from "react";
import {
  Box,
  Paper,
  Typography,
  TextField,
  Button,
  Divider,
} from "@mui/material";
import { styled } from "@mui/material";
import {useNavigate } from "react-router-dom";
import axios from "axios";

// Custom styled TextField component to match the style
const SignupTextField = styled(TextField)({
  "& .MuiInputLabel-root": { color: "#000" },
  "& .MuiInputLabel-root.Mui-focused": { color: "#000" },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#000",
    },
    "&:hover fieldset": {
      borderColor: "#000",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#357AE8",
    },
  },
  "& .MuiOutlinedInput-input": { color: "#000" },
});

const ResetPwd = () => {
  const [cnfpass, setcnfpass] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleresetPwd = async () => {
    if (password !== cnfpass) {
      setMessage("Password Do Not macth");
      return;
    }
    setLoading(true);

    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/auth/reset-password",
        {
          token,
          password,
        }
      );
      // alert(response.data.message);
      if(response.status===200 || response.status===201) 
      {
        navigate("/Auth/");
      }
    } catch (error) {
      setMessage(`${error.response?.data?.message || error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundImage: "url('../../assets/sign up BG_equal.png')",
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "100vh",
      }}
    >
      <Paper
        elevation={1}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent:"center",
          alignItems:"center",
          width: "380px",
          
          borderRadius: "10px",
          padding: "30px 30px 30px 30px",
          backgroundColor:"white"
        }}
      >
        <Typography
          variant="h4"
          align="center"
          sx={{ color: "#000", marginBottom: "30px" }}
        >
          <img
            alt="logo"
            width="250px"
            height="120px"
            style={{ borderRadius: "15px" }}
            src="../../assets/Infinitelogo.png"
          />
        </Typography>

        <Typography
          variant="body2"
          sx={{
            fontSize: "18px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Change Password
        </Typography>

        <Divider style={{ margin: "16px 0" }} />
        <SignupTextField
          label="New Password"
          variant="outlined"
          type="password"
          fullWidth
          margin="normal"
          required
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          disabled={loading}
        />

        <SignupTextField
          label="Confirm Password"
          variant="outlined"
          type="password"
          fullWidth
          margin="normal"
          required
          value={cnfpass}
          onChange={(e) => setcnfpass(e.target.value)}
          disabled={loading}
        />

        {message && <Typography color="sucess">{message}</Typography>}
        <Button
          variant="contained"
          fullWidth
          sx={{
            backgroundColor: "#B09246",
            ":hover": { backgroundColor: "#B09246" },
            borderRadius: "10px",
            marginTop: "20px",
          }}
          onClick={handleresetPwd}
          disabled={loading}
        >
          Submit
        </Button>
      </Paper>
    </Box>
  );
};

export default ResetPwd;
