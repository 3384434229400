import React, { useState, useEffect } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  Divider,
  IconButton,
  useTheme,
  Accordion,
  AccordionDetails,
  Tab,
  Tabs,

} from "@mui/material";
import axios from "axios";
import { tokens } from "../../theme";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation } from "react-router-dom";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ padding: 3 }}>{children}</Box>}
    </div>
  );
}

export default function ShraddhaMemberTV() {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [videoList, setVideoList] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const location = useLocation();
  const course = location.state?.course;

  const [ResList, setResList] = useState([]);

  const handleBlackClick = () => {
    navigate(`/Member/MyCourses`);
  };

  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const SelectedVideo = (video) => {
    setSelectedVideo(video);
    fetchvideoResList();
  };

  const fetchvideoResList = async () => {
    try {
      const videoId = selectedVideo.id;
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/display/videoResource",
        { videoId },
        {
          headers: { Authorization: token },
        }
      );
      const users = Object.values(response.data.rows);
      setResList(users);
    } catch (error) {
      console.log("Error fetching group data", error);
    }
  };

  const fetchData = async () => {
    try {
      const courseId = course.courseId;

      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/display/videoList",
        { courseId },
        {
          headers: { Authorization: token },
        }
      );
      const videoLists = response.data.rows;
      setVideoList(videoLists);
    } catch (error) {
      console.log("Error fetching data", error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchvideoResList();
  }, []);

  return (
    <Box
      sx={{
        padding: 3,
        mt: "50px",
        minHeight: "100vh",
        display: "flex",
        flexDirection: {
          xs: "column",
          sm: "column",
          md: "column",
          xl: "row",
          lg: "row",
        },
        justifyContent: "space-between",
      }}
      onContextMenu={(e) => e.preventDefault()}
    >
      <Box
        sx={{
          flex: 0.65,
          padding: { xs: "20px", sm: "20px 40px" },
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {/* Main Content */}
        {selectedVideo ? (
          <>
            <Box
              sx={{
                width: "100%",
                maxWidth: "720px",
                marginBottom: "20px",
              }}
            >
              <video
                key={selectedVideo.video_Name}
                // src={selectedVideo.video_URL}
                src={`${selectedVideo.video_URL}?${token}`}
                controls
                controlsList="nodownload"
                style={{
                  width: "100%",
                  borderRadius: "10px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                }}
                onContextMenu={(e) => e.preventDefault()}
              />
            </Box>
            <Box sx={{ marginBottom: "10px" }}>
              <Typography
                variant="h5"
                sx={{
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                }}
              >
                {selectedVideo.video_Name}
              </Typography>
              <Box sx={{ width: "100%" }}>
                <Tabs
                  value={selectedTab}
                  onChange={handleTabChange}
                  variant="fullWidth"
                  aria-label="course details tabs"
                  sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                    color: colors.primary[150],
                    "& .MuiTab-root": {
                      fontWeight: "bold",
                      color: colors.primary[150],
                    },

                    "& .MuiTabs-indicator": {
                      backgroundColor: colors.greenAccent[300],
                    },
                  }}
                >
                  <Tab
                    label={
                      <Typography
                        variant="h4"
                        fontWeight="bold"
                        sx={{
                          color:
                            theme.palette.mode === "light" ? "black" : "white",
                        }}
                      >
                        Descriptions
                      </Typography>
                    }
                  />
                  <Tab
                    label={
                      <Typography
                        variant="h4"
                        fontWeight="bold"
                        sx={{
                          color:
                            theme.palette.mode === "light" ? "black" : "white",
                        }}
                      >
                        Resources
                      </Typography>
                    }
                  />
                </Tabs>

                <TabPanel value={selectedTab} index={0}>
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    paragraph
                    sx={{ lineHeight: 1.7 }}
                  >
                    {selectedVideo.video_Desc}
                  </Typography>
                </TabPanel>

                <TabPanel value={selectedTab} index={1}>
                  {/* Resources Mapping */}
                  {ResList.map((resource, idx) => (
                    <Accordion
                      key={idx}
                      elevation={0}
                      sx={{
                        m: 0,
                        p: 0,
                        backgroundColor: "transparent",
                        "&:before": {
                          display: "none", // Removes the bottom divider line
                        },
                      }}
                    >
                      <AccordionDetails>
                        <ul style={{ paddingLeft: "1.5rem", margin: 0 }}>
                          <li key={resource.video_Id}>
                            <Typography variant="body1">
                              <a
                                href={resource.href}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                  textDecoration: "none",
                                  color: colors.blueAccent[500],
                                  cursor: "pointer",
                                }}
                                onMouseOver={(e) =>
                                  (e.target.style.color = "blue")
                                }
                                onMouseOut={(e) =>
                                  (e.target.style.color =
                                    colors.blueAccent[500])
                                }
                              >
                                {resource.source_URL}
                              </a>
                            </Typography>
                          </li>
                        </ul>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </TabPanel>
              </Box>
            </Box>
          </>
        ) : (
          <Typography variant="h6">No video selected</Typography>
        )}
      </Box>

      {/* Sidebar */}
      <Box
        sx={{
          flex: 0.5,
          position: {
            xs: "relative ",
            sm: "relative",
            md: "fixed",
            xl: "fixed",
          },
          right: 0,
          height: "100vh",
          width: { xs: "100%", sm: "100%", md: "28vw", xl: "28vw", lg: "28vw" },
          padding: "20px",
          overflowY: "auto",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          backgroundColor: colors.primary[400],
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontSize: { xs: "1.2rem", sm: "1.5rem" },
              fontWeight: "bold",
            }}
          >
            {course?.courseName}
          </Typography>
          <IconButton
            size="small"
            color="primary"
            onClick={handleBlackClick}
            sx={{
              display: "block",
            }}
          >
            <CloseIcon sx={{ fontSize: "16px" }} />
          </IconButton>
        </Box>
        <Divider sx={{ margin: "10px 0" }} />

        <List>
          {videoList.map((video, index) => (
            <ListItem
              key={index}
              onClick={() => SelectedVideo(video)}
              sx={{
                backgroundColor:
                  selectedVideo?.id === video.id
                    ? colors.grey[800]
                    : "transparent",
                borderRadius: "8px",
                cursor: "pointer",
                margin: 0, // Remove any margin
              }}
            >
              <ListItemText
                primary={`${index + 1}. ${video.video_Name}`}
                secondary={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <OndemandVideoIcon
                      style={{ fontSize: "15px", marginRight: "5px" }}
                    />
                    1 hour 30 min
                  </div>
                }
                sx={{
                  color: selectedVideo?.id === video.id ? "#fff" : "inherit",
                  fontSize: "10px",
                  margin: 0,
                }}
              />
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
}
// document.querySelector('.MuiBox-root.css-19pqw0t').style.display = 'none';
