import { useState, useEffect } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../theme";
import EventNoteIcon from "@mui/icons-material/EventNote";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import MenuOpen from "@mui/icons-material/MenuOpen";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";


const Item = ({ title, to, icon, selected, setSelected, style }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.primary[100],
        ...style,
        backgroundColor:
          selected === title ? colors.blueAccent[900] : "transparent",
        borderRadius: "10px",
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography
        style={{
          fontWeight: "bold",
          color: style?.color || colors.primary[100], // Use grey if provided
        }}
      >
        {title}
      </Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Gauestsidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("ShraddhaEvents");

  useEffect(() => {
    const updateStateBasedOnScreenSize = () => {
      const isSmall = window.matchMedia("(max-width: 640px)").matches; // Tailwind SM breakpoint
      const isMedium = window.matchMedia(
        "(min-width: 641px) and (max-width: 1024px)"
      ).matches; // Tailwind MD breakpoint
      const isExtraLarge = window.matchMedia("(min-width: 1281px)").matches; // Tailwind XL breakpoint

      if (isExtraLarge) {
        setIsCollapsed(false);
      } else if (isMedium || isSmall) {
        setIsCollapsed(true);
      }
    };

    updateStateBasedOnScreenSize(); // Initialize state on mount

    window.addEventListener("resize", updateStateBasedOnScreenSize); // Update state on resize

    return () => {
      window.removeEventListener("resize", updateStateBasedOnScreenSize); // Cleanup
    };
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          "& .pro-sidebar-inner": {
            background: `${colors.primary[400]} !important`,
            boxShadow: "2px 0 2px -2px rgba(0, 0, 0, 0.5)",
            marginTop: {
              xs: "22px", 
              sm: "22px", 
              md: "27px", 
              lg: "34px", 
              xl: "34px", 
            },
            position: isCollapsed ? "fixed" : "fixed",
            height: "100vh", 
            overflowY: "auto",
            top: isCollapsed ? "34px" : "34px",
            left: 0,
            width: {
              xs: isCollapsed ? "90px" : "285px",
              sm: isCollapsed ?"90px" : "285px",
              md: isCollapsed ? "90px" : "285px", 
              lg: isCollapsed ? "90px" : "285px", 
            },
            zIndex: isCollapsed ? 999 : 999,
            transition: "all 0.3s ease",
          },
          "& .pro-icon-wrapper": {
            backgroundColor: "transparent !important",
          },
          "& .pro-inner-item": {
            color: `${colors.grey[400]}!important`,
          },
          "& .pro-inner-item:hover": {
            color: "#CBAD5B !important", //"#868dfb !important",
          },
          "& .pro-menu-item": {
            color: "#6870fa !important",
            borderRadius: "10px",
            fontSize: "10px",
          },
          "& .pro-menu-item.active": {
            color: "#6870fa !important",
            backgroundColor: `${colors.blueAccent[900]}!important`,
          },
        }}
      >
        <ProSidebar collapsed={isCollapsed}>
          <Menu iconShape="square">
            {/*LOGO AND MENU ICON */}
            <MenuItem
              onClick={() => setIsCollapsed(!isCollapsed)}
              icon={isCollapsed ? <MenuOutlinedIcon /> : <MenuOpen />}
              style={{
                color: colors.grey[100],
              }}
            ></MenuItem>

            {/* Menu Items */}

            <Box paddingLeft={isCollapsed ? undefined : "10%"}>
              <Item
                title=" Events"
                to="/Gauest/GauestEvents"
                icon={<EventNoteIcon style={{ color: "#B09246" }} />}
                selected={selected}
                setSelected={setSelected}
              />
              {/* <Item
                title="Support"
                to="/Gauest/Support"
                icon={<SupportAgentIcon style={{ color: "#B09246" }} />}
                selected={selected}
                setSelected={setSelected}
              /> */}
            </Box>
          </Menu>
        </ProSidebar>
      </Box>
    </Box>
  );
};

export default Gauestsidebar;
