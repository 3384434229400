import {
  Box,
  useTheme,
  Button,
  Card,
  CardContent,
  Typography,
  Grid,
  Checkbox,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../../theme";
import Header from "../../Dashboard/Header";

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import dayjs from "dayjs";

export default function PayableReport() {
  const navigate = useNavigate();
  const theme = useTheme();
  const token = localStorage.getItem("token");
  const cmpid = localStorage.getItem("cmpid");
  const colors = tokens(theme.palette.mode);
  const [Payables, setPayable] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const fetchData = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/crDrManagement/paybleReport",
        { cmpid },
        {
          headers: { Authorization: token },
        }
      );

      const PayableReport = Object.values(response.data);
      setPayable(PayableReport);
      console.log(PayableReport);
    } catch (error) {
      alert("Error fetching data", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const PayableList = Payables.map((Payable, index) => ({
    id: Payable.vch_number,
    srNo: index + 1,
    vch_type: Payable.vch_type,
    vch_number: Payable.vch_number,
    vch_date: dayjs(Payable.vch_date).format("DD/MM/YYYY"),
    PartyName: Payable.PartyName,
    vch_Amount: `Rs. ${Math.abs(Payable.vch_Amount).toFixed(2)}`,
  }));

  // Summary Data
  const totalAmountDue = Payables.reduce(
    (sum, payable) => sum + payable.vch_Amount,
    0
  );
  const totalCreditors = Payables.length;
  const upcomingDueDate =
    Payables.length > 0
      ? dayjs(Payables[0].vch_date).format("DD/MM/YYYY")
      : "N/A";

  const columns = [
    {
      field: "srNo",
      headerName: "Sr No",
      headerAlign: "center",
      align: "center",
      width: 100,
      sortable: false,
    },
    {
      field: "PartyName",
      headerName: "Creditors",
      headerAlign: "center",
      align: "center",
      width: 350,
      sortable: true,
    },
    {
      field: "vch_date",
      headerName: "Due Date",
      headerAlign: "center",
      align: "center",
      width: 250,
      sortable: true,
      renderCell: (params) => dayjs(params.value).format("DD/MM/YYYY"),
    },
    {
      field: "vch_Amount",
      headerName: "Amount Due",
      headerAlign: "center",
      align: "center",
      width: 250,
      sortable: true,
    },
  ];

  return (
    <Box mt="80px" ml="30px" mr="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center" >
        <Header title="Reports" subtitle="Payable Report" />
      </Box>

      {/* Summary Cards */}
      <Grid container spacing={2} ml={0} mr={0} mb={2} display="flex" justifyContent="center" alignItems="center">
        <Grid item xs={12} sm={4}>
          <Card
            sx={{
              backgroundColor: colors.primary[400],
              boxShadow: "8",
              borderRadius: "1vw",
              width: "93%",
            }}
          >
            <CardContent sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
              <Typography variant="h3" color={colors.primary[120]}>
                Total Amount Due
              </Typography>
              <Typography variant="h3" color="error" fontWeight={700}>
                Rs. {totalAmountDue.toFixed(2)}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card
            sx={{
              backgroundColor: colors.primary[400],
              boxShadow: "8",
              borderRadius: "1vw",
              width: "93%",
            }}
          >
            <CardContent sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
              <Typography variant="h3" color={colors.primary[120]}>
                Total Creditors
              </Typography>
              <Typography variant="h3" color="error" fontWeight={700}>{totalCreditors}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card
            sx={{
              backgroundColor: colors.primary[400],
              boxShadow: "8",
              borderRadius: "1vw",
              width: "93%",
            }}
          >
            <CardContent sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
              <Typography variant="h3" color={colors.primary[120]}>
                Upcoming Due Date
              </Typography>
              <Typography variant="h3" color="error" fontWeight={700}>{upcomingDueDate}</Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Data Grid */}
      <Box
        height="74vh"
        sx={{
          "& .MuiDataGrid-root": { border: "none" },
          "& .MuiDataGrid-cell": { borderBottom: "none" },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
            color: colors.primary[120],
            cursor: "default",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
            color: colors.primary[150],
          },
        }}
      >
<DataGrid
    rows={PayableList}
    columns={columns}
    checkboxSelection
    onRowSelectionModelChange={(newSelection) => setSelectedRows(newSelection.selectionModel)}
    // selectionModel={selectedRows}
/>
      </Box>
    </Box>
  );
}
