import { useEffect } from "react";
import { useMediaQuery } from "@mui/material";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import { Routes, Route, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

//  All Top Bar And Side Bar
import Topbar from "./Topbar_Sidebar/Topbar";
import MemTopbar from "./Topbar_Sidebar/MemberTopBar";
import Sidebar from "./Topbar_Sidebar/Sidebar";
import GauestSidebar from "./Topbar_Sidebar/GauestSidebar";
import MemberSidebar from "./Topbar_Sidebar/MemberSideBar";
import AdminSidebar from "./Topbar_Sidebar/AdminSidebar";
import AdminTopbar from "./Topbar_Sidebar/AdminTopbar";
import GauestTopbar from "./Topbar_Sidebar/GauestTopbar";
import ShraddhaMobileMenuBar from "./Topbar_Sidebar/MobileMenuBar";

// All Parent Routes
import AuthRoutes from "./Routes/AuthRoutes";
import AdminRoutes from "./Routes/AdminRoutes";
import MemberRoutes from "./Routes/MemberRoutes";
import InfiniteRoutes from "./Routes/InfiniteRoutes";
import Gauest from "./Routes/GauestRoute";

function App() {
  const [theme, colorMode] = useMode();
  const location = useLocation();
  const navigate = useNavigate();

  const isMobileView = useMediaQuery("(max-width: 900px)");

  // const isAuthpage = location.pathname.startsWith("/Auth");

  const isAdminPage = location.pathname.startsWith("/Admin");

  const isGauest = location.pathname.startsWith("/Gauest");

  const memberPaths = location.pathname.startsWith("/Member");

  const isInfintePage = location.pathname.startsWith("/Infinite");

  // =================================

  useEffect(() => {
    if (location.pathname === "/") {
      navigate("/Auth/");
    }
  }, [location.pathname, navigate]);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <main className="content">
            {/* Topbar For Infinite  */}
            {isInfintePage && <Topbar />}

            {/* Tobar for Menbers  */}
            {memberPaths && <MemTopbar />}

            {/* Topbar for Admin */}
            {isAdminPage && <AdminTopbar />}

            {/* Topbar for mobile  */}
            {isMobileView && memberPaths && <ShraddhaMobileMenuBar />}

            {isGauest && <GauestTopbar />}

            <div className="layout">
              {/* Sidebar fo Infinite */}
              {(isInfintePage && <Sidebar />) || isMobileView}

              {/* sidebar for member */}
              {memberPaths && !isMobileView && <MemberSidebar />}

              {/* Sidebar for Admin */}
              {isAdminPage && <AdminSidebar />}

              {/* Sidebar for Gauest */}
              {(isGauest && <GauestSidebar />) || isMobileView}

              {/* Routes for the application */}
              <div className="route-container">
                <Routes>
                  <Route path="/Admin/*" element={<AdminRoutes />} />
                  <Route path="/Member/*" element={<MemberRoutes />} />
                  <Route path="/Auth/*" element={<AuthRoutes />} />
                  <Route path="/Gauest/*" element={<Gauest />} />
                  <Route path="/Infinite/*" element={<InfiniteRoutes />} />
                </Routes>
              </div>
            </div>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;

//  document.addEventListener('contextmenu', (e) => e.preventDefault());
