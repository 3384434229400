import {
  Box,
  Button,
  Card,
  CardContent,
  useTheme,
  Icon,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import TotalIcon from "@mui/icons-material/Pending";
import { AccountBalanceWallet } from "@mui/icons-material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import Header from "../../InfinitePages/Dashboard/Header";
import { tokens } from "../../theme";
import { DataGrid } from "@mui/x-data-grid";
import StylishLoadingDialog from "../../InfinitePages/Dashboard/StatBox";
import axios from "axios";

export default function Support() {
  //use states
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const token = localStorage.getItem("token");
  const [formValues, setFormValues] = useState({
    id: "",
    caseTitle: "",
    case_Description: "",
    Remark: "",
    caseType: "",
  });
  const [openDialog, setOpenDialog] = useState(false);
  const DialogClose = () => setOpenDialog(false);
  const [loading, setloading] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [caseList, setCaseList] = useState([]);
  const [casetatus, setcasetatus] = useState([]);
  const ContId = localStorage.getItem("VTContacId");
  const role = localStorage.getItem("role");
  const Org_id = localStorage.getItem("VIOrgId")

  //function to set from values on textfeild change
  const handleDateChange = (field, value) => {
    setFormValues((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  //function to open dialog on case Creation
  const handleDialogOpen = () => {
    setIsUpdate(false);
    setFormValues({
      id: "",
      caseTitle: "",
      case_Description: "",
      resolution_type: "",
      caseType: "",
    });
    setOpenDialog(true);
  };

  //function to handle create case
  const handleCreate = async () => {
    setloading(true);
    if (balCase > 0) {
      const newCase = {
        contact_Id: ContId,
        caseTitle: formValues.caseTitle,
        case_Description: formValues.case_Description,
        caseType: formValues.caseType,
        Org_id:Org_id,
      };
      //  console.log("new case", newCase);
      try {
        await axios.post(
          process.env.REACT_APP_API_URL + "/support/Cases/create",
          newCase,
          {
            headers: { Authorization: token },
          }
        );
        alert("Case generated successfully");
      } catch (error) {
        alert("Failed To generate case");
      }
      // window.location.reload();
    } else {
      alert(
        "You have reached your case limit. Please contact us at 91-020-414-88999."
      );
    }
    setOpenDialog(false);
    setloading(false);
  };

  //open Dialog while Update Case and fill values in that
  const handleUpdate = (row) => {
    // console.log(row)
    setIsUpdate(true);
    setFormValues({
      id: row.id,
      caseTitle: row.title,
      case_Description: row.description,
      caseType: row.caseType,
    });
    setOpenDialog(true);
  };

  //function to return indian Timestamp
  function getCurrentTimestamp() {
    const now = new Date();

    const options = {
      timeZone: "Asia/Kolkata",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    };

    const istTime = now.toLocaleString("en-GB", options);

    const [date, time] = istTime.split(", ");
    const [day, month, year] = date.split("/");
    return `${year}-${month}-${day} ${time}`;
  }

  //Handle to Update Case Data

  const updateData = async (row) => {
    setloading(true);
    const updateDec = formValues.Remark
      ? "\n  -  " + getCurrentTimestamp() + "\n  " + formValues.Remark
      : " ";
    const UpdatedCase = {
      case_Id: formValues.id,
      contact_Id: ContId,
      caseTitle: formValues.caseTitle,
      case_Description: formValues.case_Description + updateDec,
      caseType: formValues.caseType,
    };

    try {
      await axios.post(
        process.env.REACT_APP_API_URL + "/support/Cases/update",
        UpdatedCase,
        {
          headers: { Authorization: token },
        }
      );
      alert("Case Update Successfully");
    } catch (error) {
      alert("Error Update Account", error);
    }
    window.location.reload();
    setOpenDialog(false);
    setloading(false);
  };

  //function get all Cases Data
  const fetchData = async () => {
    setloading(true);
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/support/Cases/AllcasesList",
        { contact_Id: ContId },
        {
          headers: { Authorization: token },
        }
      );
      setCaseList(response.data.result);
    } catch (error) {
      console.log("Error fetching data");
    }
    setloading(false);
  };

  //function get all case status
  const caseData = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/support/Cases/getCasesStatus",
        { contact_Id: ContId },
        {
          headers: { Authorization: token },
        }
      );
      setcasetatus(response.data);
    } catch (error) {
      console.log("Error fetching data");
    }
  };

  //call on page load
  useEffect(() => {
    fetchData();
    caseData();
  }, []);

  //Rows of case table
  const userListRow = caseList.map((AllCaseList) => ({
    id: AllCaseList.id,
    title: AllCaseList.title,
    description: AllCaseList.description,
    case_no: AllCaseList.case_no,
    resolution_type: AllCaseList.resolution_type,
    casestatus: AllCaseList.casestatus,
    caseType: AllCaseList.cf_cases_casetype,
  }));

  //columns pf Case Table
  const columns = [
    {
      field: "case_no",
      headerName: "Case No",
      headerAlign: "center",
      align: "center",
      width: 80,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "title",
      headerName: "Case Title",
      headerAlign: "center",
      align: "center",
      width: 300,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "description",
      headerName: "Case Description",
      headerAlign: "center",
      align: "center",
      width: 500,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Box style={{ wordWrap: "break-word", whiteSpace: "normal" }}>
          {params.value}
        </Box>
      ),
    },
    {
      field: "resolution_type",
      headerName: "Resolution Type",
      headerAlign: "center",
      align: "center",
      width: 170,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "caseType",
      headerName: "Case Type",
      headerAlign: "center",
      align: "center",
      width: 170,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "casestatus",
      headerName: "Case Status",
      headerAlign: "center",
      align: "center",
      width: 170,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      width: 150,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Button
          variant="contained"
          style={{
            backgroundColor: colors.blueAccent[700],
            color: colors.primary[150],
            "&:hover": {
              backgroundColor: colors.blueAccent[700],
              color: colors.primary[150],
            },
          }}
          size="small"
          type="button"
          onClick={() => handleUpdate(params.row)}
          disabled={params.row.casestatus === "Closed"}
        >
          {params.row.casestatus === "Closed" ? "Closed" : "Update"}
        </Button>
      ),
    },
  ];

  const rowHeight = "auto";
  const balCase = 30 - casetatus.totalCases;

  const CaseType = [
    { id: 1, name: "APAR" },
    { id: 2, name: "PIAGGIO" },
    { id: 3, name: "INFINITE" },
    { id: 4, name: "VTIGER" },
    { id: 5, name: "TALLY" },
    { id: 6, name: "AMC" },
    { id: 7, name: "KOEL" },
    { id: 8, name: "Mahindra" },
  ];

  //   Tally Serial Number States and Function

  const [openTallyDialog, setOpenTallyDialog] = useState(false);
  const [tallySerialNo, setTallySerial] = useState("");

  const handleOpenTallyDialog = () => {
    console.log(role)
    if (role.includes("null")) {
      setOpenTallyDialog(true);
    } else {
      setOpenDialog(true);
    }
  };

  const handleCloseDialogs = () => {
    setOpenTallyDialog(false);
    setOpenDialog(false);
  };

  const handleTallyChange = (event) => {
    setTallySerial(event.target.value);
  };

  const handleTallySubmit = async () => {
    setOpenTallyDialog(false);
    setOpenDialog(true);

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/support/Cases/tallySerialNo`,
        { tallySerialNo },
        {
          headers: { Authorization: token },
        }
      );
      alert("Tally Serial No Added successfully");
    } catch (error) {
      alert("Tally Serial No Failed To Submit");
    }
  };

  //==========================================================

  return (
    <Box>
      {/* Tally Serial Number Dialog */}
      <Dialog open={openTallyDialog} onClose={handleCloseDialogs}>
        <DialogTitle variant="h2">Tally Serial Number</DialogTitle>
        <DialogContent>
          <TextField
            label="Enter Tally Serial Number"
            fullWidth
            type="number"
            variant="outlined"
            sx={{ mt: 2 }}
            value={tallySerialNo}
            onChange={handleTallyChange}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialogs}
            color="error"
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            onClick={handleTallySubmit}
            color="success"
            variant="contained"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      {/*  this is code for add cases */}
      <Dialog
        open={openDialog}
        PaperProps={{
          sx: {
            width: "450px",
            maxWidth: "80%",
            height: "450px",
            backgroundColor: colors.primary[400],
          },
        }}
      >
        <DialogTitle variant="h3">
          {isUpdate ? "Update Case" : "Generate Case"}
        </DialogTitle>
        <DialogContent>
          <Box display="flex" flexDirection="column" gap={2} mt="10px">
            <TextField
              label="Case Title"
              variant="outlined"
              value={formValues.caseTitle}
              onChange={(e) => handleDateChange("caseTitle", e.target.value)}
              fullWidth
              required
              InputProps={{
                readOnly: isUpdate,
              }}
            />

            <FormControl fullWidth size="small">
              <InputLabel id="case-type-label">Case Type</InputLabel>
              <Select
                id="case-type-label"
                name="caseType"
                label="Case Type"
                value={formValues.caseType}
                onChange={(e) => handleDateChange("caseType", e.target.value)}
                fullWidth
                required
              >
                {CaseType.map((ctype) => (
                  <MenuItem key={ctype.id} value={ctype.name}>
                    {ctype.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Case Summary TextField */}
            <TextField
              label="Case Summary"
              variant="outlined"
              value={formValues.case_Description}
              onChange={(e) =>
                handleDateChange("case_Description", e.target.value)
              }
              fullWidth
              required
              multiline
              rows={4}
              InputProps={{
                readOnly: isUpdate,
              }}
            />

            {/* Resolution TextField */}
            {isUpdate && (
              <TextField
                label="Remark"
                variant="outlined"
                onChange={(e) => handleDateChange("Remark", e.target.value)}
                fullWidth
                required
              />
            )}
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "center", // Center the buttons horizontally
            gap: 3, // Optional: Adds space between buttons
            mb: 3,
          }}
        >
          <Button
            onClick={DialogClose}
            variant="contained"
            color="error"
            size="small"
            style={{ minWidth: "80px", height: "35px", padding: "8px 16px" }}
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            color="success"
            onClick={isUpdate ? updateData : handleCreate}
            style={{ minWidth: "80px", height: "35px", padding: "8px 16px" }}
          >
            {isUpdate ? "Update" : "Save"}
          </Button>
        </DialogActions>
      </Dialog>

      {/* ================ this code for card and tables  */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          ml: "25px",
          mt: "80px",
        }}
      >
        {/* ================== card box ===================== */}
        <Box
          p={0}
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          width="100%"
        >
          <Box
            display="flex"
            flexDirection="row" /* Stack items vertically */
            justifyContent="space-between"
            alignItems="center"
            sx={{ width: "100%" }}
          >
            <Header title="Support Dashboard" subtitle="Tally Support Cases" />

            <SupportAgentIcon
              sx={{
                width: "50px",
                height: "50px",
                mr: "20px" /* Add spacing between header and icon */,
                //  color: "#B09246",
              }}
            />
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row", md: "row" },
            justifyContent: "flex-start",
            alignItems: "center",
            width: "100%",
            p: { xs: 1, sm: 2, md: 3, lg: 4 },
            gap: 2,
          }}
        >
          <Box sx={{ flex: "1 1 calc(25% - 16px)", maxWidth: "300px" }}>
            <Card
              sx={{
                width: {
                  xs: "300px",
                  sm: "300px",
                  md: "100%",
                  lg: "100%",
                  xl: "100%",
                },
                boxShadow: 2,
                borderRadius: 3,
                textAlign: "center",
                backgroundColor: colors.primary[400],
                position: "relative", // Ensures elements can be positioned absolutely within the card
                height: "100px",
              }}
            >
              <CardContent>
                {/* Icon in the top-left */}
                <Box
                  sx={{
                    mt: "10px",
                    position: "absolute",
                    top: 16,
                    left: 16,
                  }}
                >
                  <Icon
                    sx={{
                      fontSize: 40,
                      color: colors.primary[200],
                      height: "45px",
                      width: "60px",
                      backgroundColor: colors.blueAccent[900],
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "15px",
                    }}
                  >
                    <FactCheckIcon />
                  </Icon>
                </Box>

                {/* Number amount in the top-right */}
                <Box
                  sx={{
                    position: "absolute",
                    top: 16,
                    right: 16,
                    fontSize: "1.5rem",
                    fontWeight: "bold",
                    color: colors.primary[100],
                    marginRight: "20px",
                  }}
                >
                  {casetatus.activeCase}
                </Box>

                <Box
                  sx={{
                    position: "absolute",
                    bottom: 16,
                    right: 16,
                    fontSize: "0.9rem",
                    color: colors.primary[200],
                  }}
                >
                  <Typography>Active Case</Typography>
                </Box>
              </CardContent>
            </Card>
          </Box>

          {/* ====================== second card ===================== */}

          <Box sx={{ flex: "1 1 calc(25% - 16px)", maxWidth: "300px" }}>
            <Card
              sx={{
                width: {
                  xs: "300px",
                  sm: "300px",
                  md: "100%",
                  lg: "100%",
                  xl: "100%",
                },
                boxShadow: 1,
                borderRadius: 3,
                textAlign: "center",
                backgroundColor: colors.primary[400],
                position: "relative",
                height: "100px",
              }}
            >
              <CardContent>
                {/* Icon in the top-left */}
                <Box
                  sx={{
                    mt: "10px",
                    position: "absolute",
                    top: 16,
                    left: 16,
                  }}
                >
                  <Icon
                    sx={{
                      fontSize: 40,
                      color: colors.primary[200],
                      height: "45px",
                      width: "60px",
                      backgroundColor: colors.blueAccent[900],
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "15px",
                    }}
                  >
                    <CheckCircleOutlineIcon />
                  </Icon>
                </Box>

                {/* Number amount in the top-right */}
                <Box
                  sx={{
                    position: "absolute",
                    top: 16,
                    right: 16,
                    fontSize: "1.5rem",
                    fontWeight: "bold",
                    color: colors.primary[100],
                    marginRight: "20px",
                    animation: "increment 2s ease-out forwards",
                  }}
                >
                  {casetatus.ClosedCase}
                </Box>

                <Box
                  sx={{
                    position: "absolute",
                    bottom: 16,
                    right: 16,
                    fontSize: "0.9rem",
                    color: colors.primary[200],
                  }}
                >
                  <Typography> Solve Case</Typography>
                </Box>
              </CardContent>
            </Card>
          </Box>

          {/* ================= third card  ====================== */}

          <Box sx={{ flex: "1 1 calc(25% - 16px)", maxWidth: "300px" }}>
            <Card
              sx={{
                width: {
                  xs: "300px",
                  sm: "300px",
                  md: "100%",
                  lg: "100%",
                  xl: "100%",
                },
                boxShadow: 1,
                borderRadius: 3,
                textAlign: "center",
                backgroundColor: colors.primary[400],
                position: "relative",
                height: "100px",
              }}
            >
              <CardContent>
                {/* Icon in the top-left */}
                <Box
                  sx={{
                    mt: "10px",
                    position: "absolute",
                    top: 16,
                    left: 16,
                  }}
                >
                  <Icon
                    sx={{
                      fontSize: 40,
                      color: colors.primary[200],
                      height: "45px",
                      width: "60px",
                      backgroundColor: colors.blueAccent[900],
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "15px",
                    }}
                  >
                    <AccountBalanceWallet style={{ marginRight: "8px" }} />
                  </Icon>
                </Box>

                {/* Number amount in the top-right */}
                <Box
                  sx={{
                    position: "absolute",
                    top: 16,
                    right: 16,
                    fontSize: "1.5rem",
                    fontWeight: "bold",
                    color: colors.primary[100],
                    marginRight: "20px",
                  }}
                >
                  {balCase}
                </Box>

                <Box
                  sx={{
                    position: "absolute",
                    bottom: 16,
                    right: 16,
                    fontSize: "0.9rem",
                    color: colors.primary[200],
                  }}
                >
                  <Typography> Balance case</Typography>
                </Box>
              </CardContent>
            </Card>
          </Box>

          {/*========================== four card =============================== */}
          <Box sx={{ flex: "1 1 calc(25% - 16px)", maxWidth: "300px" }}>
            <Card
              sx={{
                width: {
                  xs: "300px",
                  sm: "300px",
                  md: "100%",
                  lg: "100%",
                  xl: "100%",
                },
                boxShadow: 1,
                borderRadius: 3,
                textAlign: "center",
                backgroundColor: colors.primary[400],
                position: "relative",
                height: "100px",
              }}
            >
              <CardContent>
                {/* Icon in the top-left */}
                <Box
                  sx={{
                    mt: "10px",
                    position: "absolute",
                    top: 16,
                    left: 16,
                  }}
                >
                  <Icon
                    sx={{
                      fontSize: 40,
                      color: colors.primary[200],
                      height: "45px",
                      width: "60px",
                      backgroundColor: colors.blueAccent[900],
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "15px",
                    }}
                  >
                    <TotalIcon />
                  </Icon>
                </Box>

                {/* Number amount in the top-right */}
                <Box
                  sx={{
                    position: "absolute",
                    top: 16,
                    right: 16,
                    fontSize: "1.5rem",
                    fontWeight: "bold",
                    color: colors.primary[100],
                    marginRight: "20px",
                  }}
                >
                  30
                  {/* {casetatus.totalCases} */}
                </Box>

                <Box
                  sx={{
                    position: "absolute",
                    bottom: 16,
                    right: 16,
                    fontSize: "0.9rem",
                    color: colors.primary[200],
                  }}
                >
                  <Typography>Total Case</Typography>
                </Box>
              </CardContent>
            </Card>
          </Box>
        </Box>
        {/*  ============================ tables box ===================== */}
        <Box
          sx={{
            width: "100%",
            mt: "20px",
          }}
        >
          <Box
            p={0}
            m={0}
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            sx={{ mb: 2 }}
          >
            <Button
              variant="contained"
              sx={{
                mr: 1,
                borderRadius: "10px",
                backgroundColor: colors.blueAccent[700],
                color: colors.primary[150],
                "&:hover": {
                  backgroundColor: colors.blueAccent[700],
                  color: colors.primary[150],
                },
              }}
              onClick={handleOpenTallyDialog}
            >
              Generate Case
            </Button>
          </Box>

          <StylishLoadingDialog loading={loading} />

          <Box
            sx={{
              height: "500px",
              width: "100%",
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
                mt: "10px",
              },
              "& .name-column--cell": {
                color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none",
                color: colors.primary[120],
                cursor: "default",
              },
              "& .MuiDataGrid-columnHeader:focus": {
                outline: "none",
                boxShadow: "none",
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                outline: "none",
                boxShadow: "none",
              },
              "& .MuiDataGrid-columnHeader:focus-visible": {
                outline: "none",
                boxShadow: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
                color: colors.primary[150],
              },
            }}
          >
            <DataGrid
              rows={userListRow}
              columns={columns}
              pageSize={35}
              getRowHeight={() => rowHeight}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
