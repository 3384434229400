import { useState, useEffect } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../theme";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import SchoolIcon from "@mui/icons-material/School";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import MenuOpen from "@mui/icons-material/MenuOpen";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import EventNoteIcon from "@mui/icons-material/EventNote";
import SlowMotionVideoIcon from "@mui/icons-material/SlowMotionVideo";

const Item = ({ title, to, icon, selected, setSelected, style }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.primary[100],
        ...style,
        backgroundColor:
          selected === title ? colors.blueAccent[900] : "transparent",
        borderRadius: "10px",
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography
        style={{
          fontWeight: "bold",
          color: style?.color || colors.primary[100], // Use grey if provided
        }}
      >
        {title}
      </Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const MemberSidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("ShraddhaEvents");

  const [isCoursesOpen, setisCoursesOpen] = useState();

  const handleCoursesToggle = () => {
    setisCoursesOpen(!isCoursesOpen); // Toggle the open state
  };

  useEffect(() => {
    const updateStateBasedOnScreenSize = () => {
      const isSmall = window.matchMedia("(max-width: 640px)").matches; // Tailwind SM breakpoint
      const isMedium = window.matchMedia(
        "(min-width: 641px) and (max-width: 1024px)"
      ).matches; // Tailwind MD breakpoint
      const isExtraLarge = window.matchMedia("(min-width: 1281px)").matches; // Tailwind XL breakpoint

      if (isExtraLarge) {
        setIsCollapsed(false);
      } else if (isMedium || isSmall) {
        setIsCollapsed(true);
      }
    };

    updateStateBasedOnScreenSize(); // Initialize state on mount

    window.addEventListener("resize", updateStateBasedOnScreenSize); // Update state on resize

    return () => {
      window.removeEventListener("resize", updateStateBasedOnScreenSize); // Cleanup
    };
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          "& .pro-sidebar-inner": {
            background: `${colors.primary[400]} !important`,
            boxShadow: "2px 0 2px -2px rgba(0, 0, 0, 0.5)",
            marginTop: {
              xs: "25px", // Margin for extra small screens
              sm: "25px", // Margin for small screens
              md: "27px", // Margin for medium screens
              lg: "34px", // Slightly larger margin for large screens
              xl: "34px", // Same margin for extra-large screens
            },
            position: isCollapsed ? "fixed" : "fixed",
            height: "100vh", //isCollapsed ? "calc(100vh - 104px)" : "calc(100vh - 104px)",
            overflowY: "auto",
            top: isCollapsed ? "34px" : "34px",
            left: 0,
            width: {
              xs: isCollapsed ? "0" : "0", // Full width on mobile when not collapsed
              sm: isCollapsed ? "0" : "0", // Adjust for small screens if needed
              md: isCollapsed ? "90px" : "286px", // Medium screens and larger
              lg: isCollapsed ? "90px" : "286px", // Large screens and up
            },
            zIndex: isCollapsed ? 999 : 999,
            transition: "all 0.3s ease",
          },
          "& .pro-icon-wrapper": {
            backgroundColor: "transparent !important",
          },
          "& .pro-inner-item": {
            color: `${colors.grey[400]}!important`,
          },
          "& .pro-inner-item:hover": {
            color: "#CBAD5B !important", //"#868dfb !important",
          },
          "& .pro-menu-item": {
            color: "#6870fa !important",
            borderRadius: "10px",
            fontSize: "10px",
          },
          "& .pro-menu-item.active": {
            color: "#6870fa !important",
            backgroundColor: `${colors.blueAccent[900]}!important`,
          },
        }}
      >
        <ProSidebar collapsed={isCollapsed}>
          <Menu iconShape="square">
            {/*LOGO AND MENU ICON */}
            <MenuItem
              onClick={() => setIsCollapsed(!isCollapsed)}
              icon={isCollapsed ? <MenuOutlinedIcon /> : <MenuOpen />}
              style={{
                color: colors.grey[100],
              }}
            ></MenuItem>

            {/* Menu Items */}

            <Box paddingLeft={isCollapsed ? undefined : "10%"}>
              <Item
                title="Events"
                to="/Member/ShraddhaEvents"
                icon={<EventNoteIcon style={{ color: "#B09246" }} />}
                selected={selected}
                setSelected={setSelected}
              />
              {/* <Item
                title="Support"
                to="/Member/Support"
                icon={<SupportAgentIcon style={{ color: "#B09246" }} />}
                selected={selected}
                setSelected={setSelected}
              /> */}
              <Item
                title="Events Recording"
                to="/Member/EventsRecording"
                icon={<OndemandVideoIcon style={{ color: "#B09246" }} />}
                selected={selected}
                setSelected={setSelected}
              />
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={handleCoursesToggle}
              >
                <SchoolIcon style={{ marginLeft: "25px", color: "#B09246" }} />

                <Typography
                  paddingLeft={isCollapsed ? "25%" : "0px"}
                  color={colors.primary[100]}
                  sx={{
                    m: "10px 0 5px 15px",
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                >
                  Courses
                </Typography>

                {isCoursesOpen ? (
                  <ExpandLessIcon
                    style={{ marginLeft: "auto", color: colors.primary[100] }}
                  />
                ) : (
                  <ExpandMoreIcon
                    style={{ marginLeft: "auto", color: colors.primary[100] }}
                  />
                )}
              </Box>
              {isCoursesOpen && (
                <>
                  <Box
                    style={{
                      marginLeft: "20px",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <Item
                      title="All Courses"
                      // to="/Member/ShraddhaCourses"
                      to="/Member/ShraddhaInfinite"
                      icon={
                        <SlowMotionVideoIcon style={{ color: "#B09246" }} />
                      }
                      selected={selected}
                      setSelected={setSelected}
                      style={{ color: "grey" }}
                    />

                    <Item
                      title="My Courses"
                      to="/Member/ShraddhaInfinite"
                      //  to="/Member/MyCourses"
                      icon={<OndemandVideoIcon style={{ color: "#B09246" }} />}
                      selected={selected}
                      setSelected={setSelected}
                      style={{ color: "grey" }} // Pass grey text color as a prop
                    />
                  </Box>
                </>
              )}
              <Box
                style={{
                  color: "#868dfb",
                  alignItems: "left",
                  justifyContent: "left",
                  fontSize: "25px",
                  fontWeight: "bolder",
                }}
              >
                <Item
                  title=" Shraddha Infinite"
                  to="/Member/ShraddhaInfinite" // "/Infinite/Dashboard"  //
                  sx={{
                    fontWeight: "bold",
                    fontSize: "20px",
                  }}
                  icon={<AllInclusiveIcon style={{ color: "#B09246" }} />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </Box>
            </Box>
          </Menu>
        </ProSidebar>
      </Box>
    </Box>
  );
};

export default MemberSidebar;
