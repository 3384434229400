import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  useTheme,
} from "@mui/material";
import Header from "../../InfinitePages/Dashboard/Header";
import StylishLoadingDialog from "../../InfinitePages/Dashboard/StatBox";
import axios from "axios";
import { tokens } from "../../theme";

export default function GauestEvents() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const token = localStorage.getItem("guestToken");
  const [EventList, setEventList] = useState([]);
  const [loading, setLoading] = useState(false); 
  // const [tabValue, setTabValue] = useState(0);

  // Fetch the event list data
  const fetchData = async () => {
    setLoading(true);  // Start loading
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/guest/event",
        {},
        {
          headers: { Authorization: token },
        }
      );
      setEventList(response.data.rows);
    } catch (error) {
      console.log("Error fetching data", error);
    }
    setLoading(false); // End loading
  };

  // const handleTabChange = (event, newValue) => {
  //   setTabValue(newValue);
  // };

  // Handle registration action
  const handleRegistration = async (event_RegLink, eventId) => {
    window.open(event_RegLink);  // Open registration link
    try {
      await axios.post(
        process.env.REACT_APP_API_URL + "/display/eventclickcount",
        { eventId },
        {
          headers: { Authorization: token },
        }
      );
    } catch (error) {
      console.log("Error tracking event click", error);
    }
  };

  // useEffect to fetch events on component mount
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Box sx={{ padding: "40px", marginTop: "35px" }}>
      <Header title="Events" subtitle="Events Calendar" />
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: "20px",
        }}
      >   
        {EventList.map((event) => (
          <Card
            key={event.event_Id}
            sx={{
              justifyContent: { xs: "center", sm: "center" }, // Center horizontally on all screen sizes
              alignItems: { xs: "center", sm: "center" }, // Center vertically on all screen sizes
              width: {
                xs: "300px",
                sm: "300px",
                md: "400px",
                lg: "400px", // Increased width for larger screens
                xl: "400px", // Extra large screens
              },
              border: new Date(event.event_StartDay) > new Date() ? 4 : 0.5,
              borderColor:
                new Date(event.event_StartDay) > new Date()
                  ? "#B09246"
                  : "primary.main",
              backgroundColor: "background.paper",
              boxShadow: new Date(event.event_StartDay) > new Date() ? 20 : 12,
              transition: "transform 0.3s ease",
              "&:hover": {
                transform: "scale(1.05)",
                boxShadow: 12,
              },
            }}
          >
            <CardContent>
              <Typography variant="h3" gutterBottom>
                {event.event_Name}
              </Typography>
              <Typography variant="h5" gutterBottom dangerouslySetInnerHTML={{ __html: event.event_Agenda }} />
              <Typography variant="body1">
                <strong>Date:</strong> {event.event_StartDay}
              </Typography>
              <Typography variant="body1">
                <strong>Time:</strong> {event.event_StartTime} -{" "}
                {event.event_EndTime}
              </Typography>
              <Typography variant="body2">
                <strong>Location:</strong> {event.event_Location}
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                variant="contained"
                sx={{
                  borderRadius: "10px",
                  mb: "10px",
                  ml: "10px",
                  backgroundColor: "#B09246",
                  ":hover": { backgroundColor: "#a0813f" },
                }}
                onClick={() =>
                  handleRegistration(event.event_RegLink, event.event_Id)
                }
              >
                Join Now
              </Button>
            </CardActions>
          </Card>
        ))}
      </Box>
      <StylishLoadingDialog loading={loading} />
    </Box>
  );
}  





















