import { Box, Typography, useTheme, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import CancelIcon from "@mui/icons-material/Cancel";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import EditIcon from "@mui/icons-material/Edit";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Header from "../Dashboard/Header";
import PersonAddAltRoundedIcon from "@mui/icons-material/PersonAddAltRounded";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputAdornment,
  IconButton,
  Select,
  MenuItem,
  InputLabel,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@mui/material";

//Function for Validate enter Password 
const validatePassword = (password) => {
  const minLength = 8;
  const hasUpperCase = /[A-Z]/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

  if (
    password === "*" || 
    (password.length >= minLength &&
      hasUpperCase &&
      hasLowerCase &&
      hasSpecialChar)
  ) {
    return true; 
  } else {
    return false; 
  }
};

const getCurrentTimestamp = () => {
  return new Date().toISOString().slice(0, 19).replace("T", " ");
};

const Users = () => {
  
  const navigate = useNavigate();
  const theme = useTheme();
  const [Users, setUsers] = useState([]);
  const token = localStorage.getItem("token");
  const colors = tokens(theme.palette.mode);
  
  //function and states for manage Password visibility =================
  const [showPassword, setShowPassword] = useState(true); 
  const [showConPassword, setShowConPassword] = useState(true); 
  const [showEditPassword, setEditShowPassword] = useState(true);
  const [showEditConPassword, setEditShowConPassword] = useState(true);

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleShowConfPassword = () => {
    setShowConPassword((prev) => !prev);
  };

  const handleClickEditShowPassword = () => {
    setEditShowPassword((prev) => !prev);
  };

  const handleShowEditConfPassword = () => {
    setEditShowConPassword((prev) => !prev);
  };

  //Function and state  to manage user creation procress ==================================

  const [userRole, setuserRole] = useState("");
  const [userEmail, setuserEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cmpassword, setcmPassword] = useState("");
  const [userName, setuserName] = useState("");
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(null);
  const [open, setOpen] = useState(false);

  //function to open and close add user Popups 
  const handleClickOpen = () => {
    setOpen(true); 
  };
  const handleClose = () => {
    setOpen(false); 
    setShowPassword(true);
    setShowConPassword(true);
  };

  //function for Add User 
  const AddNewUser = async (e) => {
    e.preventDefault(); 
    setLoading(true);

    const valid = validatePassword(password);
    setIsValid(valid);

    if (!valid) {
      return;
    }

    if (!userName || !userEmail || !password || !userRole) {
      alert("All fields are required.");
      return;
    }

    if (password !== cmpassword) {
      alert("Passwords do not match.");
      return;
    }
    const created_At = getCurrentTimestamp();
   
    //API call for user creation 
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/userAPI/create",
        {
          userName,
          userEmail,
          password,
          created_At,
          userRole,
        },
        {
          headers: { Authorization: token },
        }
      );

      if (response.data.exists) {
        alert(response.data.message);
      } else {
        alert(response.data.message);
        setuserEmail("");
        setuserName("");
        setPassword("");
        setuserRole("");
        navigate("/Infinite/Users");
      }
    } catch (error) {
      alert(
        `Error during registration: ${
          error.response?.data?.message || error.message
        }`
      );
    } finally {
      setLoading(false);
    }
  }; 



  //==function for update User ================================================
  const [openEdit, setEditOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [name, setName] = useState("");
  const [UpPassword, setUpPassword] = useState("*");
  const [CmPassword, setCmPassword] = useState("*");
  const [Role, setRole] = useState("");
  const [isActive, setIsActive] = useState("");
  const [isEditValid, setIsEditValid] = useState(true);

  const handleEditUpdate = (user) => {
    setSelectedUser(user);
    setName(user.userName);
    setIsActive(user.isActive === 1 ? "Active" : "InActive");
    setRole(user.Role);
    setEditOpen(true);
    setUpPassword(user.target.value);
    setCmPassword(user.target.value);
  };

  const handleEditClose = () => {
    setEditOpen(false); 
    setSelectedUser(null);
  }; 

  //function to update User 
  const handleEditSubmit = async (e) => {
    e.preventDefault();

    const valid = validatePassword(UpPassword);
    setIsEditValid(valid);
    if (!valid) {
      return;
    }

    if (!name || !UpPassword || !CmPassword) {
      alert("Please fill in all fields.");
      return; 
    }
    if (UpPassword !== CmPassword) {
      alert("Passwords do not match...");
      return;
    }
    try {
      const updatedUser = {
        userId: selectedUser.id, 
        userName: name,
        password: UpPassword,
        isActive: isActive === "Active" ? 1 : 0,
        userRole: Role ,
        updated_at: getCurrentTimestamp(), 
      };
      await axios.post(
        process.env.REACT_APP_API_URL + `/userAPI/update`,
        updatedUser,
        {
          headers: { Authorization: token },
        }
      );
      fetchData();
      alert("User updated successfully.");

      handleEditClose();
    } catch (error) {
      alert("An error occurred while updating the user. Please try again.");
    }
  }; 

//Function for Delete User 
const handleEditDelete = async () => {
  const confirmation = window.confirm( "Are you sure you want to delete this user?" );
  if (!confirmation) {
      return ;
  }

  try {
    await axios.post(
      process.env.REACT_APP_API_URL + `/userAPI/delete/`,{ userId: selectedUser.id},
      {
        headers: { Authorization: token },
      }
    );
    alert("User deleted successfully");
    handleEditClose();
    navigate("/Infinite/Users");

  } catch (error) {
    alert("An error occurred while deleting the user");
  }
};

//functions and State for open set company permision to user 
  const [Selectopen, setSelectopen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [CmpNameList, setCmpNameList] = useState([]);

  const handleCmpPopUpOpen = (id) => {
    setSelectopen(true);
  };
  const handleCmpPopUpClose = () => {
    setSelectopen(false);
  };

  const handleToggleOption = (option) => {
    const currentIndex = selectedOptions.indexOf(option.Cmp_Name);
    const newSelectedOptions = [...selectedOptions];

    if (currentIndex === -1) {
      newSelectedOptions.push(option.Cmp_Name);
    } else {
      newSelectedOptions.splice(currentIndex, 1);
    }
    setSelectedOptions(newSelectedOptions);
  };
  const handleSubmit = () => {
    alert(`Selected options: ${selectedOptions.join(", ")}`);
    handleCmpPopUpClose();
  };

  //Function to fetch cmp list and User List ==========================================
  const fetchData = async () => {
    if (!token) {
      navigate("/Auth/");
      return;
    }
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/userAPI/list",
        {},
        {
          headers: { Authorization: token },
        }
      );

      const UserListData = Object.values(response.data.users);
      
      setUsers(UserListData);
    } catch (error) {
      console.log("Something went wrong ");
    }
  };
  //Cmp List 
    const fetchCmpList =  async () => {
      if (!token) {
        navigate("/Auth/");
        return;
      }
      try {
        const response = await axios.post(
          process.env.REACT_APP_API_URL + "/company/list",
          {},
          {
            headers: { Authorization: token },
          }
        );
        const UserListData = Object.values(response.data.users);
        setCmpNameList(UserListData);
      } catch (error) {
       console.log("Error While Fetching Cmp list")
      }
    };

  useEffect(() => {
    fetchCmpList()
    fetchData();
  });

  const isActiveMap = {
    1: "Active",
    0: "InActive",
  };

  //Table Row and colums
  const userListRow = Users.map((user) => ({
    id: user.user_Id,
    userName: user.user_Name,
    Email: user.user_Email,
    isActive: user.user_active,
    Role: user.user_Role,
  }));
  
  const columns = [
    {
      field: "Sr No",
      headerName: "Sr No",
      headerAlign: "center",
      align: "center",
      width: 80, // Specify width
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => params.api.getRowIndex(params.id) + 1,
    },

    {
      field: "userName",
      headerName: "Name",
      headerAlign: "center",
      align: "center",
      width: 150,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "Email",
      headerName: "Email",
      headerAlign: "center",
      align: "center",
      width: 150,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "isActive",
      headerName: "IsActive",
      headerAlign: "center",
      align: "center",
      width: 130,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return isActiveMap[params.value] || "InActive";
      },
    },
    {
      field: "Role",
      headerName: "Role",
      headerAlign: "center",
      align: "center",
      width: 100,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "Action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      width: 150,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Box>
          <Button
            variant="contained"
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.primary[150], 
              "&:hover": {
                backgroundColor: colors.blueAccent[700], 
                color: colors.primary[150], 
              },
            }}
            startIcon={<EditIcon />}
            size="small"
            type="button"
            onClick={() => handleEditUpdate(params.row)}
          >
            Edit
          </Button>
        </Box>
      ),
    },
    {
      field: "Select Company",
      headerName: "Select Company",
      headerAlign: "center",
      align: "center",
      width: 200,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <Box>
            <Button
              variant="contained"
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.primary[150], 
                "&:hover": {
                  backgroundColor: colors.blueAccent[700], 
                  color: colors.primary[150], 
                },
              }}
              type="button"
              size="small"
              onClick={() => handleCmpPopUpOpen(params.row.id)}
            >
              Add Company
            </Button>
          </Box>
        );
      },
    },
    
  ];

  return (
    <>
      {/* ======================= open popup for user Edit ================= */}
      <Box sx={{ p: 2 }}>
        <Dialog
          open={openEdit}
          onClose={handleEditClose}
          PaperProps={{
            sx: {
              display: "flex",
              width: "300px", 
              height: "480px", 
              maxHeight: "100%", 
              maxWidth: "100%", 
              alignItems: "center",
              justifyContent: "center",
            },
          }}
        >
          <DialogTitle>
            <Typography>Update User Information</Typography>
            <CancelIcon
              sx={{
                position: "absolute", 
                top: "10px", 
                right: "10px", 
                height: "30px",
                width: "30px",
                cursor: "pointer",
              }}
              onClick={handleEditClose}
            />
          </DialogTitle>

          <DialogContent>
            <form>
              <TextField
                size="small"
                label="Name"
                variant="outlined"
                fullWidth
                margin="normal"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
              <InputLabel>Role</InputLabel>
              <Select
                size="small"
                value={Role}
                onChange={(e) => setRole(e.target.value)}
                fullWidth
                margin="normal"
                required
              >
                <MenuItem value="" disabled>
                  Select User Role
                </MenuItem>
                <MenuItem value="Owner">Owner</MenuItem>
                <MenuItem value="SalesPerson">SalesPerson</MenuItem>
                <MenuItem value="DataEntry">DataEntry</MenuItem>
                <MenuItem value="AccountPerson ">AccountPerson</MenuItem>
              </Select>

              <InputLabel>Is Active</InputLabel>
              <Select
                size="small"
                value={isActive}
                onChange={(e) => setIsActive(e.target.value)}
                fullWidth
                margin="normal"
                required
              >
                <MenuItem value="" disabled>
                  Select User Is Active
                </MenuItem>
                <MenuItem value="Active">Active</MenuItem>
                <MenuItem value="InActive">In Active</MenuItem>
              </Select>

              <TextField
                size="small"
                label="Password"
                type={showEditPassword ? "password" : "text"}
                variant="outlined"
                fullWidth
                margin="normal"
                value={UpPassword}
                onChange={(e) => setUpPassword(e.target.value)}
                required
                error={isEditValid === false}
                helperText={
                  isEditValid === false
                    ? "Password must be at least 8 characters, including uppercase, lowercase, and special characters."
                    : ""
                }
                sx={{
                  // Using the sx prop for inline styles
                  "& .MuiInputBase-root": {
                    color:
                      isEditValid === null
                        ? "black"
                        : isEditValid
                        ? "green"
                        : "red", // Text color
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor:
                        isEditValid === null
                          ? "black"
                          : isEditValid
                          ? "green"
                          : "red", 
                    },
                    "&:hover fieldset": {
                      borderColor:
                        isEditValid === null
                          ? "#357AE8"
                          : isEditValid
                          ? "green"
                          : "red", 
                    },
                    "&.Mui-focused fieldset": {
                      borderColor:
                        isEditValid === null
                          ? "#357AE8"
                          : isEditValid
                          ? "green"
                          : "red", 
                    },
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickEditShowPassword}
                        edge="end"
                      >
                        {showEditPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                size="small"
                label="Confirm Password"
                variant="outlined"
                fullWidth
                margin="normal"
                value={CmPassword}
                onChange={(e) => setCmPassword(e.target.value)}
                type={showEditConPassword ? "password" : "text"}
                required
                error={CmPassword && UpPassword !== CmPassword}
                helperText={
                  CmPassword && UpPassword !== CmPassword
                    ? "Passwords do not match."
                    : ""
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleShowEditConfPassword}
                        edge="end"
                      >
                        {showEditConPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mt: 2,
                  gap: 1,
                }}
              >
                <Button
                  size="small"
                  variant="contained"
                  color="success"
                  onClick={handleEditSubmit}
                >
                  Update
                </Button>
                <Button
                  size="small"
                  type="submit"
                  variant="contained"
                  color="error"
                  onClick={handleEditDelete}
                >
                  Delete
                </Button>
              </Box>
            </form>
          </DialogContent>
        </Dialog>
      </Box>

      {/* ================== open popup for select company  ====================== */}
      <Dialog
        open={Selectopen}
        onClose={handleCmpPopUpClose}
        PaperProps={{
          sx: {
            width: "400px", 
            height: "400px",
            maxHeight: "90%", 
            maxWidth: "90%", 
            overflowY: "auto",
          },
        }}
      >
        <DialogTitle variant="h3">Select Company</DialogTitle>
        <DialogContent>
          <Typography variant="h4" align="center">
            Choose your options:
          </Typography>
          <FormGroup
            sx={{
              size: "small",
              display: "flex",
              flexDirection: "column",
              alignItems: "center", 
              justifyContent: "center", 
              marginLeft: "110px",
            }}
          >
            {CmpNameList.map((option) => (
              <FormControlLabel
                key={option.Cmp_Id}
                control={
                  <Checkbox
                    checked={selectedOptions.includes(option.Cmp_Name)}
                    onChange={() => handleToggleOption(option)}
                  />
                }
                label={option.Cmp_Name}
                sx={{
                  display: "flex",
                  flexDirection: "row", 
                  alignItems: "center", 
                  justifyContent: "space-between 20px", 
                  width: "100%", 
                }}
              />
            ))}
          </FormGroup>
        </DialogContent>
        <DialogActions
          sx={{ justifyContent: "center", marginBottom: "20px", gap: 2 }}
        >
          <Button
            onClick={handleCmpPopUpClose}
            color="error"
            variant="contained"
            size="small"
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleSubmit(selectedOptions)}
            color="success"
            variant="contained"
            size="small"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      {/* ==add new user popup window============================================ */}
      <Box>
        <Dialog
          open={open}
          onClose={handleClose}
          PaperProps={{
            sx: {
              width: "350px", 
              maxWidth: "80%", 
              height: "485px", 
            },
          }}
        >
          <DialogTitle
            sx={{
              fontSize: "24px",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between", 
              marginTop: "15px",
              padding: "0 16px", 
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <PersonAddAltIcon
                sx={{ marginRight: "8px", height: "50px", width: "30px" }}
              />
              Add New User
            </Box>
            <CancelIcon
              sx={{
                height: "50px",
                width: "30px",
                cursor: "pointer",
              }}
              onClick={handleClose}
            />
          </DialogTitle>
          <DialogContent>
            <Typography variant="subtitle">
              Please fill out the form below:
            </Typography>
            <Box
              component="form"
              sx={{
                display: "flex",
                flexDirection: "column",
                marginTop: "10px",
              }}
            >
              <TextField
                size="small"
                autoFocus
                margin="dense"
                label="Name"
                type="text"
                name="name"
                fullWidth
                variant="outlined"
                value={userName}
                onChange={(e) => setuserName(e.target.value)}
                disabled={loading}
                required
              />
              <TextField
                size="small"
                margin="dense"
                label="Email"
                type="email"
                name="email"
                fullWidth
                variant="outlined"
                value={userEmail}
                onChange={(e) => setuserEmail(e.target.value)}
                disabled={loading}
                required
              />

              <InputLabel>Role</InputLabel>
              <Select
                size="small"
                value={userRole}
                onChange={(e) => setuserRole(e.target.value)}
                disabled={loading}
                required
              >
                <MenuItem value="" disabled>
                  Select a Role
                </MenuItem>
                <MenuItem value="Owner">Owner</MenuItem>
                <MenuItem value="SalesPerson">SalesPerson</MenuItem>
                <MenuItem value="DataEntry">DataEntry</MenuItem>
                <MenuItem value="AccountPerson ">AccountPerson</MenuItem>
              </Select>
              <TextField
                variant="outlined"
                size="small"
                margin="dense"
                label="Password"
                type={showPassword ? "password" : "text"}
                fullWidth
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                error={isValid === false}
                helperText={
                  isValid === false
                    ? "Password at least 8 characters, including uppercase, lowercase, and special characters."
                    : ""
                }
                disabled={loading}
                sx={{
                  "& .MuiInputBase-root": {
                    color:
                      isValid === null ? "black" : isValid ? "green" : "red", 
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor:
                        isValid === null ? "black" : isValid ? "green" : "red", 
                    },
                    "&:hover fieldset": {
                      borderColor:
                        isValid === null
                          ? "#357AE8"
                          : isValid
                          ? "green"
                          : "red", 
                    },
                    "&.Mui-focused fieldset": {
                      borderColor:
                        isValid === null
                          ? "#357AE8"
                          : isValid
                          ? "green"
                          : "red", 
                    },
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                size="small"
                margin="dense"
                label="Confirm Password"
                type={showConPassword ? "password" : "text"}
                name="Password"
                fullWidth
                variant="outlined"
                value={cmpassword}
                onChange={(e) => setcmPassword(e.target.value)}
                disabled={loading}
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleShowConfPassword}
                        edge="end"
                      >
                        {showConPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={cmpassword && password !== cmpassword}
                helperText={
                  cmpassword && password !== cmpassword
                    ? "Passwords do not match."
                    : ""
                }
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "80%", 
                mr: 3.5,
                mb: 2,
              }}
            >
              <Button
                type="submit"
                onClick={AddNewUser}
                variant="contained"
                fullWidth
                sx={{
                  borderRadius: "10px", 
                  backgroundColor: colors.blueAccent[700],
                  color: colors.primary[150], 
                  "&:hover": {
                    backgroundColor: colors.blueAccent[700], 
                    color: colors.primary[150], 
                  },
                }}
              >
                Submit
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
      </Box>

      {/* ================= show user list ================================== */}
      <Box mt="80px" ml="30px" mr="20px">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          {/* Header positioned on the left */}
          <Header title="User" subtitle="All User List" />

          {/* Button positioned on the right */}
          <Button
            variant="contained"
            style={{
              backgroundColor: colors.blueAccent[700],
              color: colors.primary[150], // Custom text color
              "&:hover": {
                backgroundColor: colors.blueAccent[700], // Maintain the same background on hover
                color: colors.primary[150], // Maintain the same text color on hover
              },
            }}
            startIcon={<PersonAddAltRoundedIcon />}
            size="small"
            onClick={handleClickOpen}
          >
            Add User
          </Button>
        </Box>
        <Box
          height="74vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
              color: colors.primary[120],
              cursor: "default", 
            },
            "& .MuiDataGrid-columnHeader:focus": {
              outline: "none", 
              boxShadow: "none", 
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              outline: "none", 
              boxShadow: "none", 
            },
            "& .MuiDataGrid-columnHeader:focus-visible": {
              
              outline: "none", 
              boxShadow: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[700],
              color: colors.primary[150],
            },
          }}
        >
          <DataGrid
            rows={userListRow}
            columns={columns}
            disableColumnSort={true}
          />
        </Box>
      </Box>
    </>
  );
};
export default Users;
