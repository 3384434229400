import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Grid,
  Container,
  AppBar,
  Toolbar,
  Box,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const TopBar = () => {
  const navigate = useNavigate();
  
  const handleRedirect = () => {
    navigate("/Auth/signup");
  };

  return (
    <AppBar position="sticky" sx={{ backgroundColor: "black" }}>
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {/* Left Section: Logo and Company Name */}
        <Box display="flex" alignItems="center">
          <img
            src="../../assets/infinite.png"
            alt="Logo"
            style={{ width: 70, height: 40, marginRight: 10 }}
          />
          <Typography variant="h2" sx={{ color: "#EFE17C", fontWeight: "bold" }}>
            Shraddha Infinite
          </Typography>
        </Box>

        {/* Right Section: Button */}
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#B09246",
            ":hover": { backgroundColor: "#B09246" },
            borderRadius: "10px",
          }}
          onClick={handleRedirect}
        >
          Sign Up
        </Button>
      </Toolbar>
    </AppBar>
  );
};

const Footer = () => (
  <Box
    sx={{
      backgroundColor: "black",
      color: "white",
      textAlign: "center",
      py: 2,
      mt: 5,
    }}
  >
    <Typography variant="body2">
      Copyright &copy; Shraddha Infosystems @2025
    </Typography>
  </Box>
);

const EventPage = () => {
  const [Events, setEventList] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  
  const handRedirect = () => {
    navigate("/signup");
  };

  // Fetch the event list data
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/public/eventList",
        {},
       
      );

      const sortedEvents = response.data.rows;
      const revEvents = sortedEvents.reverse();
      
      setEventList(revEvents);
    } catch (error) {
      console.error("Error fetching data", error);
      alert("Failed to load events.");
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <TopBar />
      <Box mb={5} sx={{ minHeight: "150vh" }}>
        <Container sx={{ mt: 2 }}>
          <Typography
            variant="h2"
            align="center"
            sx={{ color: "text.primary", fontWeight: "bold" }}
          >
            Events
          </Typography>

          {/* Loading State */}
          {loading ? (
            <Box display="flex" justifyContent="center" my={5}>
              <CircularProgress />
            </Box>
          ) : (
            <Grid container spacing={4}>
              {Events.map((event) => (
                <Grid item xs={12} sm={6} md={4} key={event.event_Id}>
                  <Card
                    sx={{
                      border: new Date(event.event_StartDay) > new Date() ? 4 : 0.5,
                      borderColor:
                        new Date(event.event_StartDay) > new Date()
                          ? "yellow"
                          : "primary.main",
                      backgroundColor: "background.paper",
                      boxShadow: new Date(event.event_StartDay) > new Date() ? 20 : 12,
                      transition: "transform 0.3s ease",
                      "&:hover": {
                        transform: "scale(1.05)",
                        boxShadow: 12,
                      },
                      // height: "65vh",
                      mt: 5,
                    }}
                  >
                    <CardContent>
                      <Typography
                        variant="h5"
                        component="div"
                        sx={{ fontWeight: "bold" }}
                      >
                        {event.event_Name}
                      </Typography>
                      <Typography
                        variant="h5"
                        gutterBottom
                        dangerouslySetInnerHTML={{ __html: event.event_Agenda }}
                      />
                      <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                        <strong>Date:</strong> {event.event_StartDay}
                      </Typography>
                      <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                        <strong>Location:</strong> {event.event_Location}
                      </Typography>
                      <Button variant="contained" sx={{ mt: 2 }}
                      onClick={handRedirect}>
                        Register
                      </Button>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          )}
        </Container>
      </Box>
      <Footer />
    </>
  );
};

export default EventPage;
